
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ServerTable, { ColumnLabel } from '@/subcomponents/ServerTable.vue'
import { SortMap, AggregatedBitcoinTransaction, EntitySummary } from '@/utils/api'
import { Target } from '@/store/investigations/viz'
import { cutEnd, cutMiddle, ServerEvent } from '@/utils/general'
import { ExtendedTx, isExtendedTx, SimpleAggregatedTransaction } from '@/types/bitcoin'
import SortBar, { SortResponse } from './SortBar.vue'
import { FormattedTransaction, isFormattedTransaction, SimpleAggregatedEthereumTransaction } from '@/types/eth'
import { filter } from '@/utils/filters'
import { nodeTypeToIdString } from '@/utils/viz'

@Component({
  components: {
    SortBar,
    ServerTable
  },
  computed: {
    ...mapState([
      'target',
      'fullTransactions',
      'transactionsLoading',
      'transaction',
      'entitySummary'
    ])
  }
})
export default class EntityTransactions extends Vue {
  // from store
  public target!: Target
  public fullTransactions!: AggregatedBitcoinTransaction[]
  public transactionsLoading!: boolean
  public transaction?: ExtendedTx | FormattedTransaction
  public entitySummary?: EntitySummary

  public countFromStream = -1

  public filteredTransactions: AggregatedBitcoinTransaction[] = []
  public symbols: string[] = []
  public symbolsFilter: string[] = []
  public itemsPerPage: number[] = [50, 100, 200, 400]
  public perPage: number = 100
  public page: number = 1
  public sortFields = ['time', 'amount']
  public sorts: SortMap = { time: 'desc' }
  private hoveredEdge: string[] = ['', '']
  
  get columnLabels(): ColumnLabel[] {
    return [
      {
        label: '',
        cols: 1,
        class: 'text-subtitle-2'
      },
      {
        label: 'LIFO',
        cols: 1,
        class: 'text-subtitle-2 text-center'
      },
      {
        label: 'TxID',
        cols: 3,
        class: 'text-subtitle-2'
      },
      {
        label: '',
        cols: 3,
        class: 'text-subtitle-2'
      },
      {
        label: '',
        cols: 4,
        class: 'text-subtitle-2'
      }
    ]
  }
  
  public expanded: number[] = []
  public aggregatedToggle: boolean = true // true is aggregated, false is unaggregated

  get targetType() {
    if (this.target != null) return this.target.type
    return ''
  }

  get network() {
    return this.target.network
  }

  get indirectLifoFormatted() {
    if (this.$store.state.indirectLifoTransaction != null) {
      const { id, isOutput, address, cluster, clusterAttribution } =
        this.$store.state.indirectLifoTransaction as SimpleAggregatedEthereumTransaction
      const entity = clusterAttribution ? clusterAttribution : cluster ? cutEnd(cluster, 8) : cutEnd(address || '', 8)
      const txn = cutMiddle(id, 6)
      if (isOutput) {
        return `From funds received by ${entity} in ${txn}`
      } else {
        return `To funds sent from ${entity} in ${txn}`
      }
    }
    return ''
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.fetchPage()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.fetchPage()
  }

  public onSort(selected: SortResponse) {
    this.sorts = {
      [selected.sortBy[0]]: selected.sortDesc[0] === 1 ? 'desc' : 'asc' // currently only one sort allowed
    }
    this.page = 1
    this.fetchPage()
  }

  public directionClass(isOutput: boolean) {
    return isOutput ? 'value-output' : 'value-input'
  }

  public columnLabelsIO(item: SimpleAggregatedTransaction): ColumnLabel[] {
    return item.isOutput ? [
      {
        label: 'Value',
        cols: 4,
        class: 'pl-4 text-subtitle-2'
      },
      {
        label: 'From',
        cols: 8,
        class: 'pl-1 text-subtitle-2'
      }] : [
      {
        label: 'To',
        cols: 8,
        class: 'pl-4 text-subtitle-2'
      },
      {
        label: 'Value',
        cols: 4,
        class: 'pl-1 text-subtitle-2'
      }]
  }

  public transactionIO(item: SimpleAggregatedTransaction) {
    return this.transaction && isExtendedTx(this.transaction) ? 
      item.isOutput ? this.transaction.vin : this.transaction.vout :
      this.transaction && isFormattedTransaction(this.transaction) ?
      item.counterparties : []
  }

  get ioTemplate() {
    const network = this.target.network
    return network === 'bitcoin' ? 'bitcoinCounterparty' : 'clusteredCounterparty'
  }

  public onItemExpanded(update: { item: SimpleAggregatedTransaction; value: boolean }) {
    const { item, value } = update
    const network = this.target.network
    if (value) {
      this.$store.dispatch('getCounterpartiesDetails', { network, txn: item, expansion: true })
    } else {
      this.$store.dispatch('getCounterpartiesDetails', { network, expansion: true })
    }
  }

  created() {
    this.newEvent()
  }

  mounted() {
    this.fetchPage()
    this.updateSymbols()
    this.filterTransactions()
  }

  selectEdge(txn: AggregatedBitcoinTransaction) {
    const { id, address, cluster, clusterAttribution, isOutput } = txn
    const txnNode = nodeTypeToIdString({ id, type: 'transaction', network: this.network })
    const entityNode = nodeTypeToIdString(
      clusterAttribution ? { id: clusterAttribution, type: 'attribution', network: this.network }
      : cluster ? { id: cluster, type: 'cluster', network: this.network }
      : { id: address || '', type: 'address', network: this.network }
    )
    const sender = isOutput ? txnNode : entityNode
    const receiver = isOutput ? entityNode : txnNode
    this.$store.dispatch('highlightEdge', [sender, receiver])
  }

  deselectEdge() {
    this.$store.dispatch('highlightEdge', [])
  }

  fetchPage() {
    this.expanded = []
    if (this.$store.state.graphedLifoTransaction) {
      this.$store.dispatch('setNextDirectLIFO')
    } else {
      this.$store.dispatch('setTxnsPage', {
        sorts: this.sorts,
        page: this.page,
        perPage: this.perPage
      })
    }
  }

  @Watch('fullTransactions')
  filterTransactions() {
    this.filteredTransactions = []
    if (this.fullTransactions != null) {
      const indexed = this.fullTransactions.map((t, index) => ({
        ...t,
        listIndex: index
      }))
      if (this.symbolsFilter.length > 0) {
        this.filteredTransactions = filter(indexed, t => this.symbolsFilter.includes(t.symbol))
      } else {
        this.filteredTransactions = indexed
      }
    }
  }

  @Watch('entitySummary')
  updateSymbols() {
    this.page = 1
    this.symbols = this.entitySummary != null ? 
      filter(
        Object.keys(this.entitySummary.symbols),
        key => {
          if (this.entitySummary != null) {
            return this.entitySummary.symbols[key].currentCredits !== 0 ||
              this.entitySummary.symbols[key].currentDeductions !== 0
          }
          return false // this will never happen
        }
      ) :
      []
    this.symbolsFilter = []
  }

  @Watch('target')
  handleTargetSelected() {
    this.fetchPage()
  }

  @Watch('$store.state.highlightedEdge')
  edgeHovered() {
    this.hoveredEdge = this.$store.state.highlightedEdge.length ? this.$store.state.highlightedEdge : ['', '']
    this.expanded = []
  }

  @Watch('$store.state.eventCount')
  private newEvent() {
    const eventsLength = this.$store.state.eventStack.length
    if (eventsLength > 0) {
      const event: ServerEvent = this.$store.state.eventStack[eventsLength - 1]
      if (event.type === 'count') {
        this.countFromStream = event.data as number
      }
    }
  }
}
