
import { timeToMilliseconds } from '@/utils/general'
import { filter } from '@/utils/filters'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  Filter,
  FilterValue,
  Header,
  SingleValueStringTransform,
  TableStringTransform,
  TableTransform
} from './types/genericTable'
import ServerTableDefaultRow from '@/subcomponents/ServerTableDefaultRow.vue'
import { BITCOIN_START } from '@/utils/bitcoin'

@Component({
  components: {
    ServerTableDefaultRow
  }
})
export default class GenericTable extends Vue {
  private network: string = ''
  @Prop() label!: string
  @Prop() headers!: Header[]
  @Prop() data!: any[]
  @Prop() onItemClick!: Function
  @Prop() onMouseOver!: Function
  @Prop() onMouseOut!: Function
  @Prop() itemKey!: string
  @Prop() tsMultiplier!: number
  @Prop() isLoading!: boolean
  @Prop() template!: string
  @Prop() meta!: Function
  @Prop() filters!: Filter[]
  @Prop() onPageUpdated!: (page: number) => void
  @Prop() onItemsPerPageUpdated!: (count: number) => void
  @Prop() onItemExpanded!: (update: { item: any; value: boolean }) => void
  @Prop() itemsPerPageOptions!: number[]
  @Prop() itemClass!: string
  @Prop() parentItem!: any
  @Prop() search?: string
  @Prop() actionsToggle!: boolean

  private items: any[] = []
  private filteredItems: any[] = []
  public expanded: any[] = []
  private defaultItemsPerPage: number[] = [10, 20, 50, 100, -1]
  private activeFilters: FilterValue[] = []
  private actionsDisabledValue: boolean = false

  async created() {
    this.network = (this.$route.params.network ?? '').toLowerCase()
    this.items = [...(this.data ?? [])]
    this.filteredItems = [...this.items]
  }

  public defaultTemplate(item: any): boolean {
    return this.template === 'default' && typeof item !== 'string'
  }

  get actionsDisabled(): boolean {
    // this is inverted to match the v-switch component
    if (this.actionsToggle) {
      return this.actionsDisabledValue
    }
    return true
  }

  set actionsDisabled(value: boolean) {
    this.actionsDisabledValue = !this.actionsDisabledValue
  }

  get dataTemplate() {
    return this.template ?? 'default'
  }

  get dataFilters() {
    return this.filters && this.filters.length > 0 ? this.filters : undefined
  }

  get dataItemKey(): string {
    if (this.itemKey == null || this.itemKey === '') {
      return 'index'
    }
    return this.itemKey
  }

  get metadata() {
    return this.meta ?? ((a: any, b: any, c: any) => '')
  }

  get itemsPerPage() {
    return this.itemsPerPageOptions ?? this.defaultItemsPerPage
  }

  get itemsPerPageUpdated() {
    return this.onItemsPerPageUpdated ?? this.defaultItemsPerPageUpdated
  }

  get pageUpdated() {
    return this.onPageUpdated ?? this.defaultPageUpdated
  }

  // used in ethereum/tron template
  get receipts() {
    return this.$store.state.receipts
  }

  private refreshData() {
    this.items = [...this.data]
    this.filteredItems = [...this.data]
  }

  @Watch('isLoading')
  private doneLoading() {
    if (!this.isLoading) {
      this.refreshData()
    }
  }

  @Watch('data')
  private updateData() {
    this.refreshData()
  }

  get title() {
    return this.label == null ? '' : this.label
  }

  get loading() {
    return this.isLoading ?? false
  }

  public renderValue({
    item,
    value,
    transform
  }: {
    item: any
    value: number | string | string[]
    transform?: string | TableTransform
  }): string | number {
    if (typeof transform === 'string') {
      return transform
    }
    if (value === '') {
      if (transform) {
        // pass full item to transform function
        return transform(item)
      }
      if (typeof item === 'string' && item != null) {
        return item
      }
    }
    if (Array.isArray(value) && transform) {
      if (value.length === 3) {
        return transform(item[value[0]], item[value[1]], item[value[2]])
      }
      return transform(item[value[0]], item[value[1]])
    } else if (typeof value == 'string') {
      return transform ? transform(item[value]) : item[value]
    }
    return ''
  }

  public itemExpanded(update: { item: any; value: boolean }) {
    if (this.onItemExpanded != null) {
      this.onItemExpanded(update)
    }
  }

  renderItemClass({
    item,
    value,
    itemClass
  }: {
    item?: any
    value?: any
    itemClass?: string | TableStringTransform
  }): string {
    if (itemClass != null) {
      if (typeof itemClass === 'string') {
        return itemClass
      }
      if (typeof itemClass === 'function') {
        return this.renderValue({ item, value, transform: itemClass }) as string
      }
    }
    return ''
  }

  applyClipping({
    item,
    header,
    transform
  }: {
    item: any
    header: Header
    transform: string | TableTransform | SingleValueStringTransform
  }) {
    if (typeof transform === 'string') {
      return transform
    }
    const value = this.renderValue({ item, value: header.transformValue ?? header.value, transform })
    if (header.clipping && header.clipping.enabled) {
      return header.clipping.function(value as string, header.clipping.length)
    }
    return value
  }

  renderSubtext({ item, header }: { item: any; header: Header }): string | number {
    const subtext = header.subtext!
    const rendered = this.renderValue({
      item,
      value: header.transformValue ?? header.value,
      transform: subtext.text
    })
    if (subtext.type === 'cryptotime') {
      return this.formatDate(rendered)
    }
    return rendered
  }

  clicked(item: any, index: number) {
    if (this.onItemClick != null) {
      this.onItemClick(item, index)
    }
  }

  mouseover(item: any, index: number) {
    if (this.onMouseOver != null) {
      this.onMouseOver(item, index)
    }
  }

  mouseout() {
    if (this.onMouseOut != null) {
      this.onMouseOut()
    }
  }

  public expandClick(item: any) {
    // only allowing 1 item to be expanded at a time through vuetify controls
    if (item.isExpanded) {
      // const index = this.expanded.indexOf(item)
      this.expanded.splice(0, 1)
      item.isExpanded = false
    } else {
      const oldItem = this.expanded.shift()
      if (oldItem != null) {
        oldItem.isExpanded = false
      }
      this.expanded.push(item)
      item.isExpanded = true
    }
    if (this.expanded.length > 1) {
      // only one one item open at a time.
      const oldItem = this.expanded.shift() // just remove the first item
      oldItem.isExpanded = false
    }
    this.itemExpanded({ item, value: item.isExpanded })
  }

  defaultPageUpdated(page: number) {
    console.log(`defaultPageUpdated ${page}`)
  }

  defaultItemsPerPageUpdated(count: number) {
    console.log(`defaultItemsPerPageUpdated ${count}`)
  }

  getNested(props: string[], item: any) {
    let value = item
    for (const prop of props) {
      value = value[prop]
      if (value == null) {
        return null
      }
    }
    return value
  }

  filterOptions(value: string, transform?: (v: any, p?: any, c?: any) => any) {
    const props = value.split('.')
    const values = new Set()
    for (const item of this.filteredItems) {
      const transformed = transform ? transform(item) : this.getNested(props, item)
      if (transformed) {
        values.add(transformed)
      }
    }
    if (props[0] === 'to') {
      return [...values].sort().reverse()
    }
    return [...values].sort()
  }

  filterItems(selected: string, f: Filter, partial: boolean = false) {
    if (selected == null && f == null) {
      return
    }
    if (selected == null) {
      // get filter index by value
      this.activeFilters = [...filter(this.activeFilters, (sf) => (f.value === sf.value ? false : true))]
    } else {
      this.activeFilters.push({ selected, ...f })
    }
    // get all values first
    const transforms = this.activeFilters.map((af) => af.transform)
    const props = this.activeFilters.map((af) => af.value.split('.'))
    const values = this.activeFilters.map((af) => af.selected)
    // check if item values match selected filter values
    this.filteredItems = filter(this.items, (item) => {
      const transformed = transforms.map((t, i) => (t ? t(item) : this.getNested(props[i], item)))
      const valueMatches = partial
        ? values.map((v, i) => transformed[i].includes(v))
        : values.map((v, i) => transformed[i] === v)
      for (const match of valueMatches) {
        // if any don't match, remove
        if (!match) {
          return false
        }
      }
      // if we get here, all values matched
      return true
    })
  }

  public formatDate(timestamp: number | string) {
    if (typeof timestamp === 'number') {
      const epoch = timeToMilliseconds(timestamp)
      return this.$store.state.formatDate(epoch, true)
    }
    try {
      const epoch = new Date(timestamp).getTime()
      return this.$store.state.formatDate(epoch, true)
    } catch (e) {
      console.warn('couldnt format date', timestamp, e)
    }
  }
}
