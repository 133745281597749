
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { titleCase, ServerEvent, sleep, timeToMilliseconds } from '@/utils/general'
import { ExtendedTx, SimpleAddress, SimpleAggregatedTransaction } from '@/types/bitcoin'
import { ClusterMetadata, ComparisonConstraints, EntitySummary, SortMap } from '@/utils/api'
import FilterBar from '@/subcomponents/FilterBar.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { prettyRoundedNumber } from '@/utils/general'
import BtcDateRange from '@/subcomponents/BtcDateRange.vue'
import { networkSymbol } from '@/utils/viz'
import { filter } from '@/utils/filters'

@Component({
  components: {
    FilterBar,
    ServerTable,
    BtcDateRange
  },
  computed: mapState(['entitySummary', 'addressCluster', 'entityLedgerSimple', 'entityLedgerFull', 'listeningToEvents'])
})
export default class Cluster extends Vue {
  public clusterId: string = ''
  public network: string = ''
  public networkDisplayName: string = ''
  public symbol: string = ''

  public entitySummary!: EntitySummary | undefined
  public entityLedgerSimple!: SimpleAggregatedTransaction[]
  public entityLedgerFull!: ExtendedTx[]
  public addressCluster!: ClusterMetadata
  public listeningToEvents!: boolean

  private sliderHover = false
  public dateRange = [0, 0]
  
  public perPage: number = 100
  public page: number = 1
  public perPageOptions = [10, 25, 50, 100]
  public loading = true
  public pageView: 'Transaction' | 'Address' = 'Transaction'
  public currentData: SimpleAggregatedTransaction[] | SimpleAddress[] = []
  public type = ''

  public sorts = [
    { label: 'time', key: 'time' },
    { label: 'value', key: 'amount' }
  ]
  public sort: SortMap = { time: 'desc' }

  private amountConstraint?: ComparisonConstraints
  private timeConstraint?: ComparisonConstraints
  private inputs: boolean = true
  private outputs: boolean = true

  public clusterMetadataLoaded: boolean = false

  public prettyRoundedNumber = prettyRoundedNumber
  public networkSymbol = networkSymbol

  private countFromStream: number = 0

  get symbols() {
    return this.entitySummary != null ? 
      filter(
        Object.keys(this.entitySummary.symbols),
        key => {
          if (this.entitySummary != null) {
            return this.entitySummary.symbols[key].currentCredits !== 0 ||
              this.entitySummary.symbols[key].currentDeductions !== 0
          }
          return false // this will never happen
        }
      )
      : []
  }

  get totalCount(): number {
    if (this.pageView === 'Address' && this.addressCluster) {
      return this.addressCluster.size
    } else {
      return this.countFromStream > 0 ? this.countFromStream : this.$store.state.entityLedgerCount || 0
    }
  }

  get pageViewTitle() {
    switch (this.pageView) {
      case 'Transaction':
        return 'Transactions'
      case 'Address':
        return 'Addresses'
    }
  }

  get nonnegativeBalance(): string {
    if (this.entitySummary != null) {
      const { currentBalance } = this.entitySummary.symbols[this.symbol]
      if (currentBalance >= 0) {
        return prettyRoundedNumber(currentBalance)
      }
    }
    return 'Unknown'
  }

  @Watch('entitySummary')
  private updateDateRange() {
    if (this.entitySummary != null) {
      const { firstTransactionTime, lastTransactionTime } = this.entitySummary
      this.dateRange = [firstTransactionTime, lastTransactionTime]
      this.symbol = this.symbols.length ? this.symbols[0] : ''
    }
  }

  @Watch('entityLedgerSimple')
  async ledgerUpdated() {
    this.loading = false
  }

  @Watch('$store.state.addressClusterUpdate')
  clusterUpdated() {
    this.clusterMetadataLoaded = true
    this.getAddresses()
  }

  async created() {
    this.clusterId = this.$route.params.id
    this.network = this.$route.params.network
    this.type = `${this.network}Item`
    this.networkDisplayName = titleCase(this.network)
    await this.refreshPageView()
  }

  private async getAddresses() {
    if (!this.addressCluster) {
      await this.ledgerUpdated()
    }
    const id = this.addressCluster.id
    await this.$store.dispatch('getAddressesInCluster', {
      network: this.network,
      id: id,
      page: this.page,
      perPage: this.perPage,
      isAttributed: false
    })
  }

  private async refreshData() {
    if (this.pageView === 'Transaction') {
      await this.getClusterTxnsById()
    } else {
      await this.getAddresses()
    }
  }

  private async getClusterTxnsById() {
    const params = {
      network: this.network,
      id: this.clusterId,
      page: this.page,
      perPage: this.perPage,
      sorts: this.sort,
      amountConstraint: this.amountConstraint,
      timeConstraint: this.timeConstraint,
      inputs: this.inputs,
      outputs: this.outputs,
      type: 'cluster'
    }
    const countParams = {
      network: this.network,
      id: this.clusterId,
      amountConstraint: this.amountConstraint,
      timeConstraint: this.timeConstraint,
      stream: true,
      type: 'cluster'
    }
    this.countFromStream = 0
    await this.$store.dispatch('getEntity', params)
    if (!this.listeningToEvents) {
      await sleep(1000)
    }
    this.$store.dispatch('getEntityLedgerCount', countParams)
  }

  public async refreshPageView() {
    this.loading = true
    await this.refreshData()
    if (this.pageView === 'Transaction') {
      this.type = `${this.network}Item`
      this.currentData = this.entityLedgerSimple
    } else {
      this.type = 'bitcoinAddress'
      const addressList = this.$store.state.addresses
      this.currentData = addressList.map((addr: string) => {
        return {
          address: addr
        }
      })
    }
    this.loading = false
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.refreshPageView()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.refreshPageView()
  }

  public onUpdateSort(sort: SortMap) {
    this.sort = sort
    this.refreshPageView()
  }

  public onFilter({
    amount,
    time,
    inputs,
    outputs
  } : {
    amount?: ComparisonConstraints
    time?: ComparisonConstraints
    inputs: boolean
    outputs: boolean
  }) {
    this.amountConstraint = amount
    this.timeConstraint = time
    this.inputs = inputs
    this.outputs = outputs
    this.refreshPageView()
  }

  @Watch('$store.state.eventCount')
  private newEvent() {
    const eventsLength = this.$store.state.eventStack.length
    if (eventsLength > 0) {
      const event: ServerEvent = this.$store.state.eventStack[eventsLength - 1]
      if (event.type === 'count') {
        this.countFromStream = event.data as number
      }
    }
  }

  public formatDate(timestamp: number): string {
    const epoch = timeToMilliseconds(timestamp)
    return this.$store.state.formatDate(epoch, true, 1)
  }
}
