
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { copyToClipboard, cutMiddle } from '@/utils/general'
import { Attribution } from '@/utils/api'
import { mapState } from 'vuex'
import { FormattedLink, SettingsCollection } from '@/store/investigations/viz'
import { nodeTypeToIdString } from '@/utils/viz'
import { filter } from '@/utils/filters'
import { ClusteredCounterparty, FormattedTransaction } from '@/types/eth'

@Component({
  computed: mapState(['transaction'])
})
export default class BtcCounterpartyInputCard extends Vue {
  public cutMiddle = cutMiddle
  public show = false
  public clusterId: string = ''
  public attribution = ''

  private addressNode = ''
  private clusterNode = ''
  private graphAddressButton = {
    color: '',
    icon: '',
    text: ''
  }
  private graphClusterButton = {
    color: '',
    icon: '',
    text: ''
  }

  @Prop() item!: ClusteredCounterparty
  @Prop() txid!: string
  @Prop() selected!: boolean
  @Prop() decimalFormatter!: (n: number | string) => string

  public transaction!: FormattedTransaction

  get network() {
    return this.$store.state.targetNetwork || this.$store.state.target.network
  }

  created() {
    this.setAttribution()
    this.updateGraphed()
  }

  public cardClasses(hover: boolean): string {
    if (hover) {
      return 'hover'
    }
    return ''
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getClusterAttribution(): { attribution?: string; clusterId?: string } {
    const { cluster, clusterAttribution } = this.item
    if (cluster != null && cluster !== '') {
      this.clusterId = cluster
      if (clusterAttribution != null && clusterAttribution !== '') {
        this.clusterNode = nodeTypeToIdString({ id: clusterAttribution, type: 'attribution', network: this.network })
        return {
          attribution: clusterAttribution,
          clusterId: cluster
        }
      } else {
        this.clusterNode = nodeTypeToIdString({ id: cluster, type: 'cluster', network: this.network })
        return {
          clusterId: cluster
        }
      }
    }
    this.clusterId = ''
    return {}
  }

  public getLabel(attribution: string, cluster: { attribution?: string; clusterId?: string }): string {
    if (cluster.attribution != null && cluster.attribution !== '') {
      return cluster.attribution
    }
    if (attribution != '') {
      return attribution
    }
    if (cluster.clusterId != null && cluster.clusterId !== '') {
      return cutMiddle(cluster.clusterId, 9)
    }
    return ''
  }

  public setAttribution() {
    const { address } = this.item
    if (address != null && address !== '') {
      this.addressNode = nodeTypeToIdString({ id: address, type: 'address', network: this.network })
      const clusterAttribution = this.getClusterAttribution()
      const attribution = this.getAttribution(address)
      this.attribution = this.getLabel(attribution, clusterAttribution)
    }
  }

  public copy(text: string) {
    copyToClipboard(text)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
  }

  private async toggleClusterGraphed() {
    const cp = this.clusterNode
    if (this.graphClusterButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: this.network, cp, cpIsOutput: false })
    } else {
      this.removeCp(cp)
    }
  }

  private toggleAddressGraphed() {
    const { value } = this.item
    const cp = this.addressNode
    if (this.graphAddressButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: this.network, cp, value, cpIsOutput: false })
    } else {
      this.removeCp(cp)
    }
  }

  private async removeCp(cp: string) {
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const txnNode = nodeTypeToIdString({ type: 'transaction', id: this.txid, network: this.network })

    if (!linkSelected) this.$store.dispatch('removeNodes', { ids: [cp] })
    else this.$store.dispatch('removeLinks', { links: [`${cp}||${txnNode}`] })
  }

  @Watch('$store.state.formattedLinks')
  @Watch('$store.state.formattedSummaryLinks')
  @Watch('$store.state.settings', { deep: true })
  private updateGraphed() {
    const { txnNodeSwitch } = this.$store.state.settings as SettingsCollection
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const links = linkSelected ?
      new Set(
        filter(this.$store.state.formattedLinks, (l: FormattedLink) => !!l.permanent)
        .map((l: FormattedLink) => `${l.source.id}${l.target.id}`)
      ) : txnNodeSwitch ?
      new Set(this.$store.state.formattedLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`)) :
      new Set(this.$store.state.formattedSummaryLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`))
    const opposingNode = (txnNodeSwitch || linkSelected) ?
      nodeTypeToIdString({ type: 'transaction', id: this.txid, network: this.network }) :
      this.$store.state.target ? nodeTypeToIdString(this.$store.state.target) : ''

    if (this.clusterNode !== '' && (this.clusterNode === opposingNode || links.has(`${this.clusterNode}${opposingNode}`))) {
      this.graphClusterButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Cluster${linkSelected ? ' Input' : ''}`
      }
    } else {
      this.graphClusterButton = {
        color: 'primary',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Cluster${linkSelected ? ' Input' : ''}`
      }
    }

    if (this.addressNode !== '' && (this.addressNode === opposingNode || links.has(`${this.addressNode}${opposingNode}`))) {
      this.graphAddressButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Address${linkSelected ? ' Input' : ''}`
      }
    } else {
      this.graphAddressButton = {
        color: 'gray darken-3',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Address${linkSelected ? ' Input' : ''}`
      }
    }
  }
}
