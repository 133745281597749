
import { titleCase } from '@/utils/general'
import { Component, Vue } from 'vue-property-decorator'
import BlockBitcoin from './BlockBitcoin.vue'
import BlockEthereum from './BlockEthereum.vue'
import BlockTron from './BlockTron.vue'

@Component({
  components: {
    BlockBitcoin,
    BlockEthereum,
    BlockTron
  }
})
export default class Block extends Vue {
  private network: string = ''
  private blockId: string = ''
  private networkDisplayName: string = ''
  public networkType: string = ''

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.blockId = this.$route.params.blockId
    this.networkDisplayName = titleCase(this.network)
    this.networkType = this.determineNetworkType()
    this.resetBlock()
    this.getBlock()
  }

  private determineNetworkType(): string {
    if (this.$store.state.bitcoinNetworks.includes(this.network)) {
      return 'bitcoin'
    }
    if (this.$store.state.ethereumNetworks.includes(this.network)) {
      return 'ethereum'
    }
    if (this.$store.state.tronNetworks.includes(this.network)) {
      return 'tron'
    }
    return ''
  }

  private resetBlock() {
    this.$store.dispatch('resetBlock')
  }

  private getBlock() {
    if (this.$store.state.supportedNetworks.includes(this.network)) {
      this.$store.dispatch('getBlock', { network: this.network, id: this.blockId })
    }
  }
}
