
import { Header } from '@/subcomponents/types/genericTable'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { BlockSummary, isBlockSummary, isStrippedBlock, StrippedBlock } from '@/types/bitcoin'
import { FormattedBlock, isFormattedBlock } from '@/types/eth'
import { titleCase } from '@/utils/general'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { BlockRange, getNextBlockRange, getPrevBlockRange } from '@/utils/blockrange'
import { mapState } from 'vuex'
import { ChainBlock } from '@/store/chain'
import { BlockCondensed, isBlockCondensed } from '@/types/tron'

@Component({
  components: {
    ServerTable
  },
  computed: mapState(['blocks'])
})
export default class Blocks extends Vue {
  public blocks!: ChainBlock[] | BlockSummary[]

  public networkDisplayName: string = ''
  public loading: boolean = false
  private network: string = ''
  private networkType: string = ''
  private bitcoinHeaders: Header[] = [
    { text: 'Height', value: 'height' },
    { text: 'Mined On', value: 'time', transform: (t) => this.formatDate(t) },
    { text: 'Miner', value: 'miner', transform: (m) => m[0] ?? '' },
    { text: 'Reward', value: 'reward' },
    { text: 'Fees', value: 'totalFees', transform: (n) => Math.round((n + Number.EPSILON) * 1000) / 1000 },
    { text: 'Value Sent', value: 'totalValueSent', transform: (n) => Math.round((n + Number.EPSILON) * 1000) / 1000 },
    { text: 'Transactions', value: 'nTx' }
  ]
  private ethereumHeaders: Header[] = [
    { text: 'Height', value: 'number' },
    { text: 'Mined On', value: 'timestamp', transform: (t) => this.formatDate(t) },
    { text: 'Miner', value: 'miner' },
    { text: 'Transactions', value: 'transactionCount' }
  ]
  private tronHeaders: Header[] = [
    { text: 'Height', value: '', transform: (block: BlockCondensed) => block.block_header.raw_data.number },
    { text: 'Mined On', value: '', transform: (block: BlockCondensed) => block.block_header.raw_data.number },
    { text: 'Transactions', value: 'txCount' }
  ]
  private range: BlockRange = { from: 0, to: 0 }
  private page: number = 0
  private pageCount: number = 10

  get headers() {
    return this.networkType === 'ethereum' ? this.ethereumHeaders
      : this.networkType === 'bitcoin' ? this.bitcoinHeaders
      : this.networkType === 'tron' ? this.tronHeaders
      : []
  }

  get totalItems() {
    const latestBlock = this.blocks[0]
    if (isBlockSummary(latestBlock)) {
      return latestBlock.height + 1
    }
    if (isFormattedBlock(latestBlock)) {
      return latestBlock.number! + 1
    }
    if (isBlockCondensed(latestBlock)) {
      return latestBlock.block_header.raw_data.number + 1
    }
    return 0
  }

  get latestBlock() {
    const latestBlocks = this.$store.state.latestBlocks
    if (latestBlocks[this.network]) {
      return latestBlocks[this.network]
    }
    return {}
  }

  get latestBlockNumber() {
    if (this.networkType === 'bitcoin' && this.latestBlock != null) {
      return this.latestBlock.height
    }
    if (this.networkType === 'ethereum' && this.latestBlock != null) {
      return this.latestBlock.number
    }
    if (this.networkType === 'tron' && this.latestBlock != null) {
      return this.latestBlock.block_header.raw_data.number
    }
    if (this.latestBlock != null) {
      console.log(`network ${this.networkType} not currently supported`)
    }
    return 0
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.networkDisplayName = titleCase(this.network)
    this.networkType = this.determineNetworkType()
    this.resetBlocks()
    this.loading = true
    this.getBlocks()
    // this.latestBlocksUpdated()
  }

  private determineNetworkType(): string {
    if (this.$store.state.bitcoinNetworks.includes(this.network)) {
      return 'bitcoin'
    }
    if (this.$store.state.ethereumNetworks.includes(this.network)) {
      return 'ethereum'
    }
    if (this.$store.state.tronNetworks.includes(this.network)) {
      return 'tron'
    }
    return ''
  }

  @Watch('blocks')
  private blocksUpdated() {
    if (this.blocks.length > 0) {
      this.loading = false
    }
  }

  // @Watch('$store.state.latestBlocks', {immediate: true, deep: true})
  @Watch('$store.state.latestBlockBitcoin')
  @Watch('$store.state.latestBlockEthereum')
  @Watch('$store.state.latestBlockTron')
  private latestBlocksUpdated() {
    let height = 0
    if (this.network === 'bitcoin') {
      const latestBlock = this.$store.state.latestBlockBitcoin as StrippedBlock
      if (latestBlock != null) {
        // console.log(this.$store.state.latestBlockBitcoin.height)
        height = latestBlock.height
      }
    }
    if (this.network === 'ethereum') {
      const latestBlock = this.$store.state.latestBlockEthereum as FormattedBlock
      if (latestBlock != null && latestBlock.number != null) {
        // console.log(this.$store.state.latestBlockEthereum.number)
        height = latestBlock.number
      }
    }
    if (this.network === 'tron') {
      const latestBlock = this.$store.state.latestBlockTron as BlockCondensed
      if (latestBlock != null) {
        // console.log(this.$store.state.latestBlockTron.block_header.raw_data.number)
        height = latestBlock.block_header.raw_data.number
      }
    }

    this.range = { from: height - this.pageCount + 1, to: height }
    // const latestBlock = this.latestBlockNumber
    // if (latestBlock != null && latestBlock != 0) {
    //   console.log('latestBlock updated')
    //   this.range = {from: latestBlock - this.pageCount + 1, to: latestBlock}
    //   console.log(this.range)
    // }
  }

  private resetBlocks() {
    this.$store.dispatch('resetBlocks')
  }

  private getBlocks() {
    if (this.$store.state.supportedNetworks.includes(this.network)) {
      this.$store.dispatch('getLatestBlock', { network: this.network })
    }
  }

  public onItemClick(item: ChainBlock) {
    const height = isStrippedBlock(item) ? item.height // bitcoin
      : isFormattedBlock(item) ? item.number // ethereum
      : item.block_header.raw_data.number // tron
    this.$router.push(`/explorer/transactions/${this.network}/${height}`)
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }

  public pageUpdated(page: number) {
    this.loading = true
    // next / prev is the opposite of what's logical because blocks are displayed
    // in descending order. page count goes up, block numbers go down.
    const nextRange =
      page > this.page ? getPrevBlockRange(this.range, this.pageCount) : getNextBlockRange(this.range, this.pageCount)
    console.log(nextRange)
    this.$store.dispatch('getBlocks', {
      ...nextRange,
      network: this.network,
      type: 'height',
      count: 10,
      page: 0
    })
    this.range = nextRange
    this.page = page
  }
}
