
import { Investigation } from '@/store/investigations/investigations'
import { IdType, SettingsCollection } from '@/store/investigations/viz'
import { Dataset, DatasetResult } from '@/store/search'
import { Api } from '@/utils/api'
import { setSearchTypes } from '@/utils/search'
import { classifyInput, NetworkTypes } from '@/utils/validate'
import { NodeType, symbolNetwork } from '@/utils/viz'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState(['settings', 'currentInvestigation', 'autocompleteResults', 'autocompleteSearches'])
})
export default class TraceForm extends Vue {
  private api!: Api

  public id: string | null = ''
  public loading: boolean = false

  public settings!: SettingsCollection
  public currentInvestigation!: Investigation | undefined

  public autocompleteResults!: DatasetResult[]
  public autocompleteSearches!: number
  private previousIdLength: number = 0
  private lastAutocompleteSearch: number = 0
  public noAutocompleteResults: boolean = false

  public types: NodeType[] = []
  public typesData: string[] = []
  public network: NetworkTypes = ''
  public icons = {
    transaction: 'mdi-transfer',
    address: 'mdi-account',
    cluster: 'mdi-account-group'
  }

  created() {
    this.api = new Api()
  }

  public get showMenu(): boolean {
    return !this.loading && this.types.length > 0
  }

  public set showMenu(value: boolean) {
    /**
     * when you click on a menu, it tries to set the bound value to true.
     * our display boolean is a getter, defining a empty setter so that
     * the front end doesn't have a fit.
     */
  }

  public get showAutocomplete(): boolean {
    return (this.id == null || (typeof this.id === 'string' && this.id.length <= 12)) && !this.showMenu
  }

  @Watch('autocompleteSearches')
  private updateSearches() {
    if (this.autocompleteSearches > this.lastAutocompleteSearch && this.autocompleteResults.length > 0) {
      this.noAutocompleteResults = false
    }
    this.lastAutocompleteSearch = this.autocompleteSearches
  }

  @Watch('showAutocomplete')
  private async shiftFocus() {
    if (!this.showAutocomplete) {
      await this.$nextTick();
      (this.$refs.textField as HTMLElement).focus()
    } else {
      // TODO: figure out a way to manually set autocomplete value, otherwise id gets deleted on switch to autocomplete
      const id = this.id
      await this.$nextTick()
      this.id = id; // this doesn't work to update autocomplete
      (this.$refs.autocomplete as HTMLElement).focus()
    }
  }

  @Watch('id')
  public async check() {
    const { id } = this
    let trimmed = ''
    if (id == null || id.length === 0) {
      // cleared.
      await this.$store.dispatch('clearAutocomplete')
    } else {
      trimmed = id.trim()
      if (trimmed.length <= 12 && (!this.noAutocompleteResults || trimmed.length < this.previousIdLength)) {
        // do autocomplete
        this.$store.dispatch('executeAutocomplete', { dataset: 'all', value: trimmed, network: 'all' })
      }
    }
    this.network = ''
    this.types.splice(0)
    this.typesData.splice(0)
    if (id != null && trimmed.length > 0 && (trimmed.length > 12 || this.noAutocompleteResults)) {
      this.loading = true
      const input = classifyInput(trimmed)
      if (input.networkTypes.length > 0) {
        if (input.networkTypes.length === 1) {
          this.network = input.networkTypes[0]
        }
        const { types, typesData } = await setSearchTypes(
          trimmed,
          input,
          this.types,
          this.typesData,
          this.api,
          false,
          this.$store
        )
        this.types = types as NodeType[]
        this.typesData = typesData
      }
      this.loading = false
    }
    this.previousIdLength = trimmed ? trimmed.length : 0
  }

  public networkIcon() {
    return this.network ? `$${this.network}` : ''
  }

  public autocompleteIcon(dataset: Dataset) {
    let type: IdType
    switch (dataset) {
      case 'attributions':
        type = 'cluster'
        break
      case 'addresses':
        type = 'address'
        break
      case 'transactions':
        type = 'transaction' 
    }
    return this.icons[type]
  }

  public autocompleteNetworkIcon(symbol?: string) {
    return symbol ? `$${symbol}` : ''
  }
  
  public formatAutocompleteItem(item: DatasetResult) {
    const { dataset, result } = item
    if (dataset !== 'attributions') {
      return result
    }
    return JSON.parse(result).name
  }

  public autocompleteItemSubtitle(dataset: Dataset) {
    return dataset === 'attributions'
  }

  public formatAutocompleteItemInfo(result: string) {
    return JSON.parse(result).category
  }

  public getIcon(type: IdType) {
    return this.icons[this.getTypeDescriptor(type)]
  }

  public getTypeDescriptor(type: IdType) {
    return type === 'attribution' ? 'cluster' : type
  }

  public tooltipText(type: IdType) {
    return 'Add to graph'
  }

  public async autocompleteItemClicked(item: DatasetResult) {
    const { dataset, network: symbol } = item
    const id = this.formatAutocompleteItem(item)
    if (dataset === 'attributions') {
      // graph directly
      const network = symbol ? symbolNetwork(symbol) : ''
      const clusters = await this.api.attributionClusters({ network, id, page: 1, perPage: 1 })
      if (clusters == null) {
        this.$store.dispatch('updateSnackbar', {
          show: this.$store.state.enableErrors,
          text: `No clusters attributed to ${id}. Try an address.`,
          timeout: 1750
        })
      } else {
        const node: NodeType = {
          id,
          type: 'attribution',
          network
        }
        if (this.currentInvestigation == null) {
          await this.$store.dispatch('addInvestigation', { name: id, fromSearch: true })
        }
        this.$store.dispatch('targetToChange', { change: true })
        if (this.settings.autoLinksSwitch) {
          this.$store.dispatch('graphAllNodePairs', { nodes: [node], allNew: false })
        } else {
          this.$store.dispatch('traceIDs', { ids: [node] })
        }
      }
      this.clear()
    } else {
      this.noAutocompleteResults = true
      if (this.id && this.id.trim() === id) this.check()
      else this.id = id
    }
  }

  public async add(node: NodeType) {
    const { type, id, network } = node
    if (this.currentInvestigation == null) {
      await this.$store.dispatch('addInvestigation', { name: id, fromSearch: true })
    }
    if (type === 'transaction') {
      this.$store.dispatch('graphFullTransactions', { ids: [{ id, network }] })
    } else {
      this.$store.dispatch('targetToChange', { change: true })
      if (this.settings.autoLinksSwitch) {
        this.$store.dispatch('graphAllNodePairs', { nodes: [node], allNew: false })
      } else {
        this.$store.dispatch('traceIDs', { ids: [node] })
      }
    }

    this.clear()
    if (document.activeElement != null) {
      try {
        ;(document.activeElement as HTMLElement).blur()
      } catch (e) {}
    }
  }

  public clear() {
    this.id = ''
    this.loading = false
    this.network = ''
    this.types.splice(0)
    this.typesData.splice(0)
  }
}
