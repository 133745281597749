import {
  BlocksRequest,
  FindInputRequest,
  ItemRequest,
  NetworkRequest,
  NetworkStatsRequest,
  OSINTRawRequest,
  OSINTSearchRequest,
  PaginatedItemRequest,
  StatsRequest,
  TxnsBlockPageRequest
} from './api'

export type UrlWithParams = (params: any) => string

export interface UrlMap {
  [key: string]: UrlWithParams | string
}

export const urls: UrlMap = {
  // auth / users
  signIn: '/api/authenticate',
  signOut: '/api/signout',
  userSeen: '/api/userseen',
  checkAuth: '/api/checkauth',
  securityAlert: '/api/securityalert',
  requestPwReset: '/api/requestpwreset',
  resetPassword: '/api/resetpassword',
  registerUser: '/api/register',
  getUser: '/api/getuser',
  // admin
  getUsers: '/api/admin/getusers',
  genCodes: '/api/admin/gencodes',
  getCodes: '/api/admin/getcodes',
  genApiKey: '/api/admin/genapikey',
  getApiKeys: '/api/admin/getapikeys',
  toggleApiKey: '/api/admin/toggleapikey',
  updateApiKeyScopes: '/api/admin/updateapikey',
  deleteApiKey: '/api/admin/deleteapikey',
  toggleUser: '/api/admin/toggleuser',
  deleteUser: '/api/admin/deleteuser',
  getActiveUsers: '/api/admin/activeusers',
  getRateLimits: '/api/admin/ratelimits',
  // clusters
  addressCluster: ({ network, id }: ItemRequest) => `/api/cluster/${network}/address/${id}`,
  // addresses[] -> { address: cluster }
  addressesClusters: ({ network }: NetworkRequest) => `/api/cluster/${network}/addresses`,
  // cluster -> addresses[]
  clusterAddresses: ({ network, id, page, perPage }: PaginatedItemRequest) =>
    `/api/cluster/${network}/id/${id}/${page}/${perPage}`,
  attributedClusterAddresses: ({ network, id, page, perPage }: PaginatedItemRequest) =>
    `/api/cluster/${network}/attribution/${id}/addresses/${page}/${perPage}`,
  attributedClusterAddressesCount: ({ network, id }: PaginatedItemRequest) =>
    `/api/cluster/${network}/attribution/${id}/addresses/count`,
  // attributions
  categories: '/api/attr/categories',
  attributionClusters: ({ network, id, page, perPage }: PaginatedItemRequest) =>
    `/api/attr/${network}/attribution/${id}/clusters/${page}/${perPage}`,
  attributionClustersCount: ({ network, id }: PaginatedItemRequest) =>
    `/api/attr/${network}/attribution/${id}/clusters/count`,
  uniqueAttributions: '/api/attributions/unique',
  // investigations
  addInvestigation: '/api/investigations/add',
  getInvestigations: '/api/investigations',
  updateInvestigation: '/api/investigations/update',
  deleteInvestigation: '/api/investigations/delete',
  // stats
  supported: (network: string) => `/api/chain/stats/assets/${network}.json`,
  stats: ({ network, asset, interval, prices, start, end }: StatsRequest) =>
    `/api/chain/stats/${network}/${asset}/${interval}/${prices}/${start}-${end}.json`,
  // accounts
  supportedAccounts: (network: string) => `/api/chain/ledger/accounts/${network}.json`,
  // attributions
  attributions: '/api/attr/get',
  // chain data
  latestBlock: (network: string) => `/api/chain/block/latest/${network}`,
  block: ({ network, id }: ItemRequest) => `/api/chain/block/${network}/${id}.json`,
  blocks: ({ network, type, from, to, count, page }: BlocksRequest) =>
    `/api/chain/blocks/${network}/${type}/${from}-${to}/${count}/${page}.json`,
  blockSummary: ({ network, id }: ItemRequest) => `/api/chain/blocksummary/${network}/${id}.json`,
  blockSummaries: ({ network, type, from, to, count, page }: BlocksRequest) =>
    `/api/chain/blocksummaries/${network}/${type}/${from}-${to}/${count}/${page}.json`,
  transaction: ({ network, id }: ItemRequest) => `/api/chain/transaction/${network}/${id}.json`,
  transactions: ({ network, id }: ItemRequest) => `/api/chain/transactions/${network}/${id}.json`,
  transactionsList: (network: string) => `/api/chain/transactions/list/${network}`,
  transactionsBlockPage: ({ network, id, count, page }: TxnsBlockPageRequest) =>
    `/api/chain/transactions/page/${network}/${id}/${count}/${page}.json`,
  receipts: ({ network, id }: ItemRequest) => `/api/chain/receipts/${network}/${id}.json`,
  receiptsBlock: ({ network, id }: ItemRequest) => `/api/chain/receipts/block/${network}/${id}.json`,
  findInput: ({ network, previousOutput, vout }: FindInputRequest) =>
    `/api/chain/findinput/${network}/${previousOutput}/${vout}.json`,
  // ledgers
  addressLedger: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/address/${id}`,
  addressLedgerCount: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/addresscount/${id}`,
  clusterLedger: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/cluster/${id}`,
  clusterLedgerCount: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/clustercount/${id}`,
  filteredLedger: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}`,
  filteredLedgerCount: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/count`,
  transactionLedger: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/transaction/${id}`,
  bulkTransactionsLedger: ({ network }: NetworkRequest) => `/api/chain/ledger/${network}/transactions`,
  counterparties: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/counterparties/${id}`,
  summary: ({ network, id }: ItemRequest) => `/api/chain/ledger/${network}/summary/${id}`,
  linksSummaries: ({ network }: NetworkRequest) => `/api/chain/ledger/${network}/links`,
  linkTransactions: ({ network }: NetworkRequest) => `/api/chain/ledger/${network}/link/transactions`,
  // flows
  flows: ({ network }: NetworkRequest) => `/api/flows/${network}`,
  flow: ({ network }: NetworkRequest) => `/api/flows/flow/${network}`,
  subnetwork: ({ network }: NetworkRequest) => `/api/flows/intersection/${network}`,
  heuristicsList: ({ network }: NetworkRequest) => `/api/flows/heuristics/${network}`,
  aggregateFlows: ({ network }: NetworkRequest) => `/api/flows/aggregate/${network}/search`,
  attributedFlows: ({ network }: NetworkRequest) => `/api/flows/attributed/${network}/search`,
  aggregateFlowHierarchy: ({ network }: NetworkRequest) => `/api/flows/aggregate/${network}/hierarchy`,
  rawFlows: ({ network }: NetworkRequest) => `/api/flows/raw/${network}`,
  filteredFlows: ({ network }: NetworkRequest) => `/api/flows/filtered/${network}`,
  // osint
  osintSearch: ({ entity, engine, start, end, delay }: OSINTSearchRequest) =>
    `/api/osint?keyword=${entity}&engine=${engine}&max=${end}&startPage=${start}&delay=${delay}`,
  osintRaw: ({ uri }: OSINTRawRequest) => `/api/osint/raw?uri=${uri}`,
  // streaming events
  events: '/api/events/subscribe',
  // search and autocomplete
  search: ({ dataset }: { dataset: string }) => `/api/search/${dataset}`,
  autocomplete: ({ dataset }: { dataset: string }) => `/api/search/autocomplete/${dataset}`,
  // p2p network
  firstSeenByHash: ({ network, hash }: { network: string; hash: string }) =>
    `/api/network/first/hash/${network}/${hash}`,
  firstSeenByIp: ({ network, ip }: { network: string; ip: string }) => `/api/network/first/ip/${network}/${ip}`,
  getNodeByIp: ({ network, ip }: { network: string; ip: string }) => `/api/network/nodes/ip/${network}/${ip}`,
  getAllNodes: ({ network }: { network: string }) => `/api/network/nodes/all/${network}`,
  getNetworkStats: ({ network }: { network: string }) => `/api/network/stats/${network}`,
  getElectrumPeers: ({ network }: { network: string }) => `/api/network/electrum/${network}/peers`,
  getElectrumPeer: ({ network, ip }: { network: string; ip: string }) => `/api/network/electrum/${network}/peer/${ip}`,
  getElectrumUsers: ({ network }: { network: string }) => `/api/network/electrum/${network}/users`,
  getElectrumUsersPage: ({ network, page, limit }: { network: string; page: number; limit: number }) =>
    `/api/network/electrum/${network}/users-paged/${page}/${limit}`,
  getElectrumUser: ({ network, ip }: { network: string; ip: string }) => `/api/network/electrum/${network}/user/${ip}`,
  getElectrumTransactions: ({ network }: { network: string }) => `/api/network/electrum/${network}/transactions`,
  getElectrumTransactionsForIp: ({ network, ip }: { network: string; ip: string }) =>
    `/api/network/electrum/${network}/transactions/${ip}`,
  getElectrumUsersWithTransactions: ({ network }: { network: string }) =>
    `/api/network/electrum/${network}/users-wtih-transactions`,
  // electrum wallets
  getElectrumWallets: ({ network }: { network: string }) => `/api/network/electrum/${network}/wallets`,
  getElectrumWatchlists: ({ network }: { network: string }) => `/api/network/electrum/${network}/watchlists`,
  getElectrumWalletAddresses: ({ network, id }: { network: string; id: string }) =>
    `/api/network/electrum/${network}/wallet/addresses/${id}`,
  getElectrumWalletsByIP: ({ network, ip }: { network: string; ip: string }) =>
    `/api/network/electrum/${network}/wallets/ip/${ip}`,
  getElectrumWalletByAddress: ({ network, address }: { network: string; address: string }) =>
    `/api/network/electrum/${network}/wallet/byaddress/${address}`,
  // electrum stats
  getElectrumPeerCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/peers/${start}-${end}/${interval}`,
  getElectrumUserCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/users/${start}-${end}/${interval}`,
  getElectrumTransactionCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/transactions/${start}-${end}/${interval}`,
  getElectrumWalletCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/wallets/${start}-${end}/${interval}`,
  getElectrumWatchlistCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/watchlists/${start}-${end}/${interval}`,
  getElectrumAddressCounts: ({ network, start, end, interval }: NetworkStatsRequest) =>
    `/api/network/electrum/${network}/stats/addresses/${start}-${end}/${interval}`,
  // ips
  getGeoList: '/api/network/geo/list',
  getIP: ({ ip }: { ip: string }) => `/api/network/ip/${ip}`,
  getIPs: '/api/network/ips',
  getIPsNearGeo: '/api/network/ips/geo/near',
  getIPsWithinCountry: '/api/network/ips/geo/within/country',
  getASNs: '/api/network/asns',
  getCarriers: '/api/network/carriers',
  getCountries: '/api/network/countries',
  getRegions: '/api/network/regions',
  getCities: '/api/network/cities'
}
