import { render, staticRenderFns } from "./BlockTron.vue?vue&type=template&id=4b1e2278&"
import script from "./BlockTron.vue?vue&type=script&lang=ts&"
export * from "./BlockTron.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports