import { State } from '../index'
import { Commit, Dispatch } from 'vuex'
import { Api, failedResponse } from '@/utils/api'
import { track } from '@/utils/tracking'
import { Edge, VizTransaction } from './viz'
import { classifyNodeId, NodeType, nodeTypeToIdString } from '@/utils/viz'
import { DSVRowArray } from 'd3'
import { NOTE_ID_TEXT_SEPARATOR, NOTE_NODE_SEPARATOR, NOTE_PREFIX, NOTE_REF_PREFIX, NOTE_TYPES_SEPARATOR } from '@/utils/graph-helpers'
import { filter } from '@/utils/filters'

interface NodePosition {
  name?: string
  id: string
  x: number
  y: number
  deleted?: boolean
  text?: string // just for note nodes during processing (not for storage)
}

export interface LinkColor extends Edge {
  color?: string
}

export interface HistoryItem {
  type: string
  target: string
}

export interface InvestigationState {
  snapshot: Blob
  nodes: NodePosition[]
  ledger?: VizTransaction[]
  deletedLinks?: Edge[]
  linkColors?: LinkColor[]
  history: HistoryItem[]
  notes: string
}

export interface Investigation {
  _id?: any // the actual type for this doesn't seem available
  name: string
  started: Date
  lastSeen: Date
  userUpdated: Date
  dataUpdated: Date
  state?: InvestigationState
}

export const investigationState = {
  autosaveOff: <boolean>false,
  enableErrors: <boolean>false,
  investigations: <Investigation[]>[],
  currentInvestigation: <Investigation | undefined>undefined,
  snapshot: <Blob | undefined>undefined,
  notes: <string>''
}

export const investigationMutations = {
  // investigations
  SET_INVESTIGATIONS(state: State, investigations: Investigation[]) {
    state.investigations = investigations
  },
  SET_CURRENT_INVESTIGATION(state: State, investigation: Investigation | undefined ) {
    state.currentInvestigation = investigation
  },
  SET_AUTOSAVE_OFF(state: State, value: boolean) {
    state.autosaveOff = value
  },
  ENABLE_ERRORS(state: State, value: boolean) {
    state.enableErrors = value
  },
  SAVE_INVESTIGATION(state: State, { id, investigation }: { id: string; investigation: Investigation }) {
    investigation._id = id
    state.investigations.push(investigation)
  },
  RENAME_INVESTIGATION(state: State, { index, name }: { index: number; name: string }) {
    state.investigations[index].name = name
  },
  DELETE_INVESTIGATION(state: State, { index }: { index: number }) {
    state.investigations.splice(index, 1)
  },
  SET_SNAPSHOT(state: State, { snapshot }: { snapshot: Blob }) {
    state.snapshot = snapshot
  },
  SET_NOTES(state: State, { notes }: { notes: string }) {
    state.notes = notes
  }
}

export const investigationActions = (state: State, api: Api, dispatch: Dispatch) => ({
  // investigations
  setAutosaveOff({ commit }: { commit: Commit }, { value }: { value: boolean }) {
    commit('SET_AUTOSAVE_OFF', value)
  },
  enableErrors({ commit }: { commit: Commit }, { value }: { value: boolean }) {
    commit('ENABLE_ERRORS', value)
  },
  async getInvestigations({ commit }: { commit: Commit }) {
    const response = await api.getInvestigations(state.username)
    if (response.success) {
      track('getInvestigations')
      const investigations: Investigation[] = response.data.map((i: any) => {
        if (i.state) {
          // convert snapshot to correct type if state is present
          // const copy = Object.assign({}, i)
          const copy: Investigation = JSON.parse(JSON.stringify(i));
          (copy.state as InvestigationState).snapshot = new Blob([i.state.snapshot.buffer], { type: 'img/png' })
          return copy
        } else {
          return i
        }
      })
      commit('SET_INVESTIGATIONS', investigations)
    }
    await dispatch('getCategories')
  },
  clearInvestigation({ commit }: { commit: Commit }) {
    dispatch('clearVizData')
  },
  async addInvestigationFromFile(
    { commit }: { commit: Commit },
    { name, data }: { name: string; data: DSVRowArray<string>[] }
  ) {
    await dispatch('addInvestigation', { name })
    await dispatch('graphFileData', { data })
  },
  async addInvestigation(
    { commit }: { commit: Commit },
    { name, fromSearch = false }: { name: string; fromSearch?: boolean }
  ) {
    const currentDate = new Date(Date.now())
    const investigation: Investigation = {
      name,
      started: currentDate,
      lastSeen: currentDate,
      userUpdated: currentDate,
      dataUpdated: currentDate
    }

    if (!(fromSearch && state.autosaveOff)) {
      const response = await api.addInvestigation(state.username, investigation)
      if (response.success) {
        track('addInvestigation', { name, started: currentDate })
        commit('SAVE_INVESTIGATION', { id: response.data, investigation })
      }
    }
    commit('SET_CURRENT_INVESTIGATION', investigation)
  },
  async enterInvestigation({ commit }: { commit: Commit }, { index }: { index: number }) {
    const investigation = state.investigations[index]
    commit('SET_CURRENT_INVESTIGATION', investigation)
    if (investigation.state) {
      track('enterInvestigation', { name: investigation.name })
      const { nodes: allNodes, ledger, deletedLinks, linkColors, history, notes: allNotes } = investigation.state

      // check whether need to reformat ids to include network
      const sampleNodeId = allNodes[0].id
      const sampleSplitNode = sampleNodeId.split('|')
      const reformatIds = sampleSplitNode.length < 3 || (sampleNodeId.startsWith('-') && sampleSplitNode.length < 2)

      // split in-graph notes from general notes
      const [notes, noteNodesString] = allNotes.split(NOTE_TYPES_SEPARATOR)
      const noteNodeTexts = (noteNodesString || '').split(NOTE_NODE_SEPARATOR)
      const noteNodesToText = noteNodeTexts.reduce(
        (map, node) => {
          const [id, text] = node.split(NOTE_ID_TEXT_SEPARATOR)
          map.set(id, text)
          return map
        },
        new Map<string, string>()
      )
      // separate note node positions from other nodes
      const notePositions: NodePosition[] = []
      const removedNotePositions: NodePosition[] = []
      const entityNodes: NodeType[] = []
      const noteIdsToChange = new Map<string, any>()
      const nodePlacementsToAdd: NodePosition[] = []
      const nodeIdReplacements = new Map<string, Map<string, string>>()
      for (const node of allNodes) {
        const { id: nodeId } = node
        if (nodeId.startsWith(NOTE_PREFIX)) {
          const nodeWithText = { ...node, text: noteNodesToText.get(nodeId) }
          const [, refId] = node.id.split(NOTE_REF_PREFIX)
          if (refId != null) {
            if (reformatIds) { // ref id of the note needs to be changed to fit new format
              noteIdsToChange.set(refId, nodeWithText)
            }
          }
          if (!node.deleted) {
            notePositions.push(nodeWithText)
          } else {
            removedNotePositions.push(nodeWithText)
          }
        } else {
          let { id, type, network } = classifyNodeId(nodeId)
          if (reformatIds) {
            const networkIdMapping = new Map()
            nodeIdReplacements.set(nodeId, networkIdMapping)
            if (network === '' && type !== 'transaction') { // find out the networks the entity is on
              for (const supportedNetwork of state.supportedNetworks) {
                const response = await api.getSummary({ id, idType: type, network: supportedNetwork })
                if (!failedResponse(response) && response != null) {
                  const nodeType = {
                    id,
                    type,
                    network: supportedNetwork
                  }
                  entityNodes.push(nodeType)
                  const nodeId = nodeTypeToIdString(nodeType)
                  networkIdMapping.set(supportedNetwork, nodeId)
                  if (network !== '') { // add extra node for extra network
                    nodePlacementsToAdd.push({
                      id: nodeId,
                      x: node.x + 10,
                      y: node.y + 10,
                      deleted: node.deleted
                    })
                  } else {
                    network = supportedNetwork // original node will use this network
                  }
                }
              }
            } else { // it's not an attribution so it's only on one network as of this conversion
              const nodeType = {
                id,
                type,
                network
              }
              networkIdMapping.set(network, nodeTypeToIdString(nodeType))
              if (type !== 'transaction') {
                entityNodes.push(nodeType)
              } else { // it's a transaction
                if (network !== 'bitcoin') { // get evm chain transaction receipt
                  dispatch('getReceipts', { network, id })
                }
              }
            }

            // replace node id with new format
            node.id = nodeTypeToIdString({
              id,
              type,
              network
            })
            
            // replace note node ref if present
            const noteToChangeId = noteIdsToChange.get(nodeId) as NodePosition | null
            if (noteToChangeId != null) {
              noteToChangeId.id.replace(nodeId, node.id)
            }
          } else { // no rename necessary
            if (type !== 'transaction') {
              entityNodes.push({
                id,
                type,
                network
              })
            } else { // it's a transaction
              if (network !== 'bitcoin') { // get evm chain transaction receipt
                dispatch('getReceipts', { network, id })
              }
            }
          }
        }
      }
      // add network duplicates
      allNodes.push(...nodePlacementsToAdd)

      commit('SET_NOTE_NODES', notePositions)
      commit('SET_NOTES_REMOVED', removedNotePositions)

      // if symbol not included in ledger or ids need reformatting, adjust
      if (ledger != null && ledger.length > 0 && (ledger[0].symbol == null || reformatIds)) {
        for (const txn of ledger) {
          if (txn.symbol == null) {
            txn.symbol = 'BTC' // if it doesn't exist yet, it must be BTC
          }
          if (reformatIds) {
            let network = ''
            const newSenderNetworksMap = nodeIdReplacements.get(txn.sender)
            const newReceiverNetworksMap = nodeIdReplacements.get(txn.receiver)
            if (newSenderNetworksMap != null && newReceiverNetworksMap != null) { // should always be true
              // use the txn side with a clear network to identify network of other side
              if (newSenderNetworksMap.size === 1) {
                network = newSenderNetworksMap.keys().next().value ?? '' // can't be ''
              } else if (newReceiverNetworksMap.size === 1) {
                network = newReceiverNetworksMap.keys().next().value ?? '' // can't be ''
              }
              if (network !== '') { // should always be true
                // rename
                const newSender = newSenderNetworksMap.get(network)
                if (newSender != null) {
                  txn.sender = newSender
                }
                const newReceiver = newReceiverNetworksMap.get(network)
                if (newReceiver != null) {
                  txn.receiver = newReceiver
                }
              }
            }
          }
        }
      }

      if (reformatIds && deletedLinks != null) {
        const deletedLinksToAdd: Edge[] = []
        for (const link of deletedLinks) {
          let network = ''
          const newSenderNetworksMap = nodeIdReplacements.get(link.source)
          const newReceiverNetworksMap = nodeIdReplacements.get(link.target)
          if (newSenderNetworksMap != null && newReceiverNetworksMap != null) { // should always be true
            // use link sides with a clear network to identify network of other side
            if (newSenderNetworksMap.size === 1) {
              network = newSenderNetworksMap.keys().next().value ?? '' // can't be ''
            } else if (newReceiverNetworksMap.size === 1) {
              network = newReceiverNetworksMap.keys().next().value ?? '' // can't be ''
            } else { // both sides are on multiple networks
              // change the ids and add deleted links for other shared networks
              const senderNetworks = Array.from(newSenderNetworksMap.keys())
              const receiverNetworks = new Set(newReceiverNetworksMap.keys())
              const sharedNetworks = filter(senderNetworks, network => receiverNetworks.has(network))
              let i = 0
              let newSender = newSenderNetworksMap.get(sharedNetworks[i])
              if (newSender != null) {
                link.source = newSender
              }
              let newReceiver = newReceiverNetworksMap.get(sharedNetworks[i])
              if (newReceiver != null) {
                link.target = newReceiver
              }
              while (i < sharedNetworks.length - 1) {
                i++
                newSender = newSenderNetworksMap.get(sharedNetworks[i])
                newReceiver = newReceiverNetworksMap.get(sharedNetworks[i])
                if (newSender != null && newReceiver != null) {
                  deletedLinksToAdd.push({ source: newSender, target: newReceiver })
                }
              }
            }
            if (network !== '') {
              // change ids
              const newSender = newSenderNetworksMap.get(network)
              if (newSender != null) {
                link.source = newSender
              }
              const newReceiver = newReceiverNetworksMap.get(network)
              if (newReceiver != null) {
                link.target = newReceiver
              }
            }
          }
        }
        deletedLinks.push(...deletedLinksToAdd)
      }

      commit('SET_DELETED_LINKS', deletedLinks)

      commit('SET_LINK_COLORS', linkColors ?? [])

      // get pairwise node transactions summaries and graph nodes;
      // deleted links will remain deleted so just pull them all regardless of setting
      await dispatch('graphAllNodePairs', {
        nodes: entityNodes,
        allNew: true,
        endLoading: ledger == null || ledger.length === 0
      })

      // graph flows transactions
      if (ledger && ledger.length) {
        await dispatch('graphLedger', { ledger })
      }

      commit('SET_HISTORY', history)

      commit('SET_NOTES', { notes })

      dispatch('getReport')
    }
  },
  exitInvestigation({ commit }: { commit: Commit }) {
    commit('SET_CURRENT_INVESTIGATION', undefined)
    dispatch('clearInvestigation')
  },
  async updateInvestigation({ commit }: { commit: Commit }) {
    if (state.currentInvestigation != null && state.currentInvestigation._id != null) {
      const updated = Object.assign({}, state.currentInvestigation)
      updated.userUpdated = new Date(Date.now())
      const nodes: NodePosition[] = state.formattedNodes.map((n) => ({
        id: n.id,
        x: n.x!,
        y: n.y!,
        name: n.display,
        deleted: false
      }))
      for (const n of state.removedNodes) {
        nodes.push({
          id: n.id,
          x: n.x!,
          y: n.y!,
          name: n.display,
          deleted: true
        })
      }

      const noteNodeTexts: string[] = []
      for (const n of state.noteNodes) {
        nodes.push({
          id: n.id,
          x: n.x!,
          y: n.y!,
          deleted: false
        })
        noteNodeTexts.push(`${n.id}${NOTE_ID_TEXT_SEPARATOR}${n.text}`)
      }
      for (const n of state.removedNotes) {
        nodes.push({
          id: n.id,
          x: n.x!,
          y: n.y!,
          deleted: true
        })
        noteNodeTexts.push(`${n.id}${NOTE_ID_TEXT_SEPARATOR}${n.text}`)
      }
      const notes = `${state.notes}${NOTE_TYPES_SEPARATOR}${noteNodeTexts.join(NOTE_NODE_SEPARATOR)}`

      const linkColors: LinkColor[] = state.formattedLinks.map(({ source, target, color }) => ({
        source: source.id,
        target: target.id,
        color
      }))
      for (const { source, target, color } of state.removedLinks) {
        linkColors.push({
          source: source.id,
          target: target.id,
          color
        })
      }
      for (const { source, target, color } of state.formattedSummaryLinks) {
        linkColors.push({
          source: source.id,
          target: target.id,
          color
        })
      }
      for (const { source, target, color } of state.removedSummaryLinks) {
        linkColors.push({
          source: source.id,
          target: target.id,
          color
        })
      }

      updated.state = {
        snapshot: state.snapshot!,
        nodes,
        ledger: state.ledger,
        deletedLinks: state.deletedLinks,
        linkColors,
        history: state.history,
        notes
      }
      await api.updateInvestigation(state.username, updated)
      dispatch('getInvestigations')
    }
  },
  async saveInvestigation({ commit }: { commit: Commit }, { name }: { name: string }) {
    // put together updated investigation
    const investigation = Object.assign({}, state.currentInvestigation)
    investigation.userUpdated = new Date(Date.now())
    if (name !== '') {
      investigation.name = name
    }
    const nodes: NodePosition[] = state.formattedNodes.map((n) => ({
      id: n.id,
      x: n.x!,
      y: n.y!,
      name: n.display,
      deleted: false
    }))
    for (const n of state.removedNodes) {
      nodes.push({
        id: n.id,
        x: n.x!,
        y: n.y!,
        name: n.display,
        deleted: true
      })
    }

    const noteNodeTexts: string[] = []
    for (const n of state.noteNodes) {
      nodes.push({
        id: n.id,
        x: n.x!,
        y: n.y!,
        deleted: false
      })
      noteNodeTexts.push(`${n.id}${NOTE_ID_TEXT_SEPARATOR}${n.text}`)
    }
    for (const n of state.removedNotes) {
      nodes.push({
        id: n.id,
        x: n.x!,
        y: n.y!,
        deleted: true
      })
      noteNodeTexts.push(`${n.id}${NOTE_ID_TEXT_SEPARATOR}${n.text}`)
    }

    const notes = `${state.notes}${NOTE_TYPES_SEPARATOR}${noteNodeTexts.join(NOTE_NODE_SEPARATOR)}`
    investigation.state = {
      snapshot: state.snapshot!,
      nodes,
      ledger: state.ledger,
      deletedLinks: state.deletedLinks,
      history: state.history,
      notes
    }

    const response = await api.addInvestigation(state.username, investigation)
    if (response.success) {
      track('addInvestigation', { name, started: investigation.started })
      commit('SAVE_INVESTIGATION', { id: response.data, investigation })
    }
  },
  async deleteInvestigation({ commit }: { commit: Commit }, { index }: { index: number }) {
    const { _id } = state.investigations[index]
    await api.deleteInvestigation(_id)
    track('deleteInvestigation', { _id })
    commit('DELETE_INVESTIGATION', { index })
  },
  async renameInvestigation({ commit }: { commit: Commit }, { index, name }: { index: number; name: string }) {
    commit('RENAME_INVESTIGATION', { index, name })
    api.updateInvestigation(state.username, state.investigations[index])
  },
  async shareInvestigation({ commit }: { commit: Commit }, { investigation, username }: { investigation: Investigation; username: string }) {
    const { name, started, lastSeen, userUpdated, dataUpdated, state } = investigation
    const newInvestigation: Investigation = {
      name,
      started,
      lastSeen,
      userUpdated,
      dataUpdated,
      state
    }
    const response = await api.addInvestigation(username, newInvestigation)
    if (response.success) {
      track('shareInvestigation', { name, started, shareWith: username })
      return true
    } else {
      return false
    }
  },
  saveNotes({ commit }: { commit: Commit }, { notes }: { notes: string }) {
    commit('SET_NOTES', { notes })
    dispatch('updateInvestigation')
  }
})
