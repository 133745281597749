
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { SortDirection } from './types/serverTable'
import { Header } from '@/subcomponents/types/genericTable'
import { SortMap } from '@/utils/api'
import { FormattedNode, FormattedLink, Target } from '@/store/investigations/viz'
import { ExtendedCoinbase, ExtendedInput, isExtendedTx, Output } from '@/types/bitcoin'
import { ClusteredCounterparty, isFormattedTransaction } from '@/types/eth'
import { classifyNodeId } from '@/utils/viz'
import { ChainTransaction } from '@/store/chain'
import { isTransaction } from '@/types/tron'

@Component({
  components: {
    ServerTable
  },
  computed: {
    ...mapState([
      'target',
      'transaction',
      'selectedLink',
      'transactionsLoading'
    ])
  }
})
export default class TransactionBreakdown extends Vue {
  // from store
  public target!: Target
  public transaction?: ChainTransaction
  public transactionsLoading!: boolean
  public selectedLink!: FormattedLink

  public template: string = ''
  public transactionIO: (ExtendedCoinbase | ExtendedInput | Output | ClusteredCounterparty)[] = []
  public inputs: boolean = true
  public outputs: boolean = true
  public itemsPerPage: number[] = [10, 25, 50, 100]
  public page: number = 1
  public perPage: number = 100
  public sorts: SortMap = { time: 'desc' }

  public get adjustedTransaction() {
    return this.transaction != null ? 
      { id: isTransaction(this.transaction) ? this.transaction.txID : this.transaction.txid } :
      {}
  }

  public onSort(header: Header, direction: SortDirection | null, index: number) {
    this.sorts = {}
    const sortKey = header.value === '' ? 'amount' : header.value
    if (direction != null) {
      this.sorts[`${sortKey}`] = direction
    }
    this.fetchPage()
  }

  public async onPageUpdated(page: number) {
    this.page = page
  }

  created() {
    this.fetchPage()
  }

  @Watch('selectedLink')
  @Watch('target')
  fetchPage() {
    this.$store.dispatch('setTxnsPage', {
      sorts: this.sorts,
      page: 1, // irrelevant
      perPage: 1 // irrelevant
    })
  }

  @Watch('transaction')
  setTransactionIO() {
    let isInput = false, isOutput = false
    if (this.selectedLink != null) {
      const { source, target, reversedLink } = this.selectedLink
      const { id: sourceId } = source as FormattedNode
      const { id: targetId } = target as FormattedNode
      if (classifyNodeId(sourceId).type === 'transaction' || reversedLink != null) {
        isOutput = true
      }
      if (classifyNodeId(targetId).type === 'transaction' || reversedLink != null) {
        isInput = true
      }
    } else { // target is transaction node
      isInput = true
      isOutput = true
    }

    const io = []
    if (this.transaction != null) {
      if (isExtendedTx(this.transaction)) {
        this.template = 'bitcoinCounterparty'
        if (this.inputs && isInput && this.transaction.vin != null) {
          for (const input of this.transaction.vin) {
            io.push(input)
          }
        }
        if (this.outputs && isOutput && this.transaction.vout != null) {
          for (const output of this.transaction.vout) {
            io.push(output)
          }
        }
      } else if (isFormattedTransaction(this.transaction) || isTransaction(this.transaction)) {
        this.template = 'clusteredCounterparty'
        for (const counterparty of this.$store.state.counterparties) {
          if (counterparty.isOutput && this.outputs && isOutput) {
            io.push(counterparty)
          } else if (!counterparty.isOutput && this.inputs && isInput) {
            io.push(counterparty)
          }
        }
      }
    }
    this.transactionIO = io
  }
}
