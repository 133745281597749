import eip55 from 'eip55'
import bs58check from 'bs58check'
import { getAddress } from 'ethers'
import { validate as validateBitcoin, getAddressInfo as getAddressInfoBitcoin } from 'bitcoin-address-validation'

type BitcoinNetworkType = 'bitcoin'
type EthereumNetworkType = 'ethereum'
type TronNetworkType = 'tron'
type IPNetworkType = 'ip'
export type NetworkTypes = BitcoinNetworkType | EthereumNetworkType | TronNetworkType | IPNetworkType | ''

export interface NetworkClassifications extends 
  Partial<BitcoinClassifications>,
  Partial<EthereumClassifications>,
  Partial<TronClassifications>,
  Partial<IPClassifications>
{
  networkTypes: NetworkTypes[]
}

// bitcoin start
export interface BitcoinClassifications {
  isBitcoinBlock: boolean
  isBitcoinTxid: boolean
  isBitcoinLegacyAddress: boolean
  isBitcoinBech32Address: boolean
  isBitcoinBech32mAddress: boolean
  isBitcoinAddress: boolean
}

export function isBitcoinClassification(classification: UntypedNetwork | unknown): classification is BitcoinClassifications {
  return (
    (classification as BitcoinClassifications).isBitcoinBlock ||
    (classification as BitcoinClassifications).isBitcoinTxid ||
    (classification as BitcoinClassifications).isBitcoinLegacyAddress ||
    (classification as BitcoinClassifications).isBitcoinBech32Address ||
    (classification as BitcoinClassifications).isBitcoinBech32mAddress
  )
}
// bitcoin end

// ethereum start
export interface EthereumClassifications {
  isEthBlockOrHash: boolean
  isEthAddress: boolean
}

export function isEthereumClassification(classification: UntypedNetwork | unknown): classification is EthereumClassifications {
  return (
    (classification as EthereumClassifications).isEthAddress ||
    (classification as EthereumClassifications).isEthBlockOrHash
  )
}
// ethereum end

// tron start
export interface TronClassifications {
  isTronBlock: boolean
  isTronTxid: boolean
  isTronAddress: boolean
}

export function isTronClassification(classification: UntypedNetwork | unknown): classification is TronClassifications {
  return (
    (classification as TronClassifications).isTronAddress ||
    (classification as TronClassifications).isTronBlock ||
    (classification as TronClassifications).isTronTxid
  )
}
// tron end

// ip start
export interface IPClassifications {
  isIPv4: boolean
}

export function isIPClassification(classification: UntypedNetwork | unknown): classification is IPClassifications {
  return (classification as IPClassifications).isIPv4
}
// ip end

export type UntypedNetwork = (BitcoinClassifications | EthereumClassifications | TronClassifications | IPClassifications)

/*
  regex notes
  tron block hash
  (?!0x)0{3,}[0-9a-fA-F]{61,}

  tron tx hash
  (?!0x)(?!0{3})[0-9a-fA-F]{64}

  tron address
  T[1-9A-HJ-NP-Za-km-z]{33}

  ethereum block hash (same as tx)
  0x[a-fA-F0-9]{64}

  ethereum tx hash (same as block)
  0x[a-fA-F0-9]{64}

  ethereum address
  0x[a-fA-F0-9]{40}

  bitcoin block hash
  000000[a-f0-9]{58}

  bitcoin txid
  [a-f0-9]{64}

  bitcoin addresses
  legacy - [13][a-km-zA-HJ-NP-Z1-9]{27,34}
  bech32 - bc1[a-zA-HJ-NP-Z1-9]{39,62}
*/

const TRON_BLOCKID_REGEX = /(?!0x)0{3,}[0-9a-fA-F]{61,}/
const TRON_TXID_REGEX = /(?!0x)(?!0{3})[0-9a-fA-F]{64}/
const TRON_ADDRESS_REGEX = /T[1-9A-HJ-NP-Za-km-z]{33}/
const ETH_BLOCK_TX_HASH_REGEX = /0x[a-fA-F0-9]{64}/
const ETH_ADDRESS_REGEX = /0x[a-fA-F0-9]{40}/
const BITCOIN_BLOCK_HASH_REGEX = /000000[a-f0-9]{58}/
const BITCOIN_TXID_REGEX = /[a-f0-9]{64}/
const BITCOIN_LEGACY_ADDRESS_REGEX = /[13][a-km-zA-HJ-NP-Z1-9]{25,35}/
const BITCOIN_BECH32_ADDRESS_REGEX = /bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{36,59}/
const IP_ADDRESS_REGEX = /((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/

const ethClassifications = (input: string) => ({
  isEthBlockOrHash: ETH_BLOCK_TX_HASH_REGEX.test(input) && input.length === 66,
  isEthAddress: ETH_ADDRESS_REGEX.test(input) && input.length === 42
})

const tronClassifications = (input: string) => ({
  isTronBlock: TRON_BLOCKID_REGEX.test(input), // add length requirement?
  isTronTxid: TRON_TXID_REGEX.test(input), // add length requirement?
  isTronAddress: TRON_ADDRESS_REGEX.test(input) && bs58check.decodeUnsafe(input) != null
})

const bitcoinClassifications = (input: string) => {
  const results = {
    isBitcoinBlock: BITCOIN_BLOCK_HASH_REGEX.test(input) && input.length === 64,
    isBitcoinTxid: BITCOIN_TXID_REGEX.test(input) && input.length === 64 && !input.startsWith('000000'),
    isBitcoinLegacyAddress:
      BITCOIN_LEGACY_ADDRESS_REGEX.test(input) && !BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length <= 36,
    isBitcoinBech32Address: BITCOIN_BECH32_ADDRESS_REGEX.test(input),
    isBitcoinBech32mAddress: BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length > 42
  }
  return {
    isBitcoinAddress: results.isBitcoinLegacyAddress || results.isBitcoinBech32Address || results.isBitcoinBech32mAddress,
    ...results
  }
}

const ipClassifications = (input: string) => ({
  isIPv4: IP_ADDRESS_REGEX.test(input)
})

export function classifyInput(input: string): NetworkClassifications {
  let networkTypes: NetworkTypes[] = []

  const ipResults = ipClassifications(input)
  if (isIPClassification(ipResults)) {
    return {
      networkTypes: ['ip'],
      ...ipResults
    }
  }
  
  // get all chain classifications first in case there's overlap
  const ethereumResults = ethClassifications(input)
  const tronResults = tronClassifications(input)
  const bitcoinResults = bitcoinClassifications(input)
  let classificationResults: any = {}
  if (isBitcoinClassification(bitcoinResults)) {
    classificationResults = { ...classificationResults, ...bitcoinResults }
    networkTypes.push('bitcoin')
  }
  if (isEthereumClassification(ethereumResults)) {
    classificationResults = { ...classificationResults, ...ethereumResults }
    networkTypes.push('ethereum')
  }
  if (isTronClassification(tronResults)) {
    classificationResults = { ...classificationResults, ...tronResults }
    networkTypes.push('tron')
  }

  if (networkTypes.length > 0) {
    return {
      ...classificationResults,
      networkTypes
    }
  }

  return {
    networkTypes
  }
}

export function validateTronAddress(input: string): boolean {
  try {
    const base58decoded = bs58check.decodeUnsafe(input)
    if (base58decoded == null) return false
    const hex = getAddress(byteArray2hexStr(base58decoded).toLowerCase().substring(2))
    return validateEthAddress(hex)
  } catch(e) {
    console.log(e)
    return false
  }
}

function byte2hexStr(byte: number) {
  if (typeof byte !== 'number') throw new Error('Input must be a number')
  if (byte < 0 || byte > 255) throw new Error('Input must be a byte')

  const hexByteMap = "0123456789ABCDEF"

  let str = ''
  str += hexByteMap.charAt(byte >> 4)
  str += hexByteMap.charAt(byte & 0x0f)

  return str
}

function byteArray2hexStr(byteArray: Uint8Array) {
  let str = ''
  for (let i = 0; i < byteArray.length; i++) str += byte2hexStr(byteArray[i])
  return str
}

export function validateEthAddress(input: string): boolean {
  try {
    return eip55.verify(input)
  } catch (e) {
    console.log(e)
    return false
  }
}

export function formatEthAddress(input: string): string {
  try {
    return eip55.encode(input)
  } catch (e) {
    console.log(e)
    return ''
  }
}

export function validateBitcoinAddress(input: string): boolean {
  try {
    return validateBitcoin(input)
  } catch (e) {
    console.log(e)
    return false
  }
}

export function getBitcoinAddressInfo(input: string) {
  try {
    return getAddressInfoBitcoin(input)
  } catch (e) {
    return null
  }
}
