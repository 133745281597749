
import { Component, Vue, Watch } from 'vue-property-decorator'
import Notes from './Notes.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { mapState } from 'vuex'
import { Header } from '@/subcomponents/types/genericTable'
import { cutMiddle, downloadCSV, timeToMilliseconds } from '@/utils/general'
import { ReportTransaction, SettingsCollection, Target, TransactionFlowTarget } from '@/store/investigations/viz'
import { AggregatedBitcoinTransaction, SortMap } from '@/utils/api'
import { Investigation } from '@/store/investigations/investigations'
import { getTargetFlowData, transformReportsForCSV, formatFlowTarget } from '@/utils/report'
import { SortDirection } from '@/subcomponents/types/serverTable'
import { prettyRoundedNumber } from '@/utils/general'

@Component({
  components: {
    Notes,
    ServerTable 
  },
  computed: mapState([
    'settings',
    'reportLoading',
    'report',
    'transactionFlowTargets',
    'transactionFlowTarget',
    'transactionFlowTargetsMap',
    'currentInvestigation',
    'target'
  ])
})
export default class Report extends Vue {
  public settings!: SettingsCollection
  public reportLoading!: boolean
  public report!: ReportTransaction[]

  public perPage: number = 100
  public page: number = 1
  public perPageOptions = [10, 25, 50, 100]

  public target!: Target | undefined

  public sort: SortMap = { time: 'asc' }
  public transactionFlowTargets!: TransactionFlowTarget[]
  public transactionFlowTargetsMap!: { [key: string]: AggregatedBitcoinTransaction }
  public flowSelected: number = 0
  public currentInvestigation!: Investigation

  public reportHeaders: Header[] = [
    {
      text: 'Transaction',
      value: '',
      sortable: false,
      link: {
        label: (item: ReportTransaction) => item.transaction,
        url: (item: ReportTransaction) => `/explorer/transaction/${item.network}/${item.transaction}`,
        target: '_blank'
      },
      clipping: {
        enabled: true,
        function: cutMiddle,
        length: 6,
        tooltip: true
      },
      copyButton: true,
      width: '150px'
    },
    {
      text: 'Entity',
      value: '',
      sortable: false,
      transform: (item: ReportTransaction) =>
        item.type === 'clusterAttribution' ? 'cluster' : item.type
    },
    {
      text: 'Address',
      value: '',
      sortable: false,
      link: {
        label: (item: ReportTransaction) => item.root ?? '', // this should always yield the root
        url: (item: ReportTransaction) =>
          `/explorer/${item.type === 'address' ? 'address' : 'cluster'}/${item.network}/${item.root}`,
        target: '_blank',
        field: 'root'
      },
      clipping: {
        enabled: true,
        function: cutMiddle,
        length: 6,
        tooltip: true
      },
      copyButton: true,
      width: '160px'
    },
    {
      text: 'Attribution (Cl)',
      value: '',
      sortable: false,
      transform: (item: ReportTransaction) =>
        item.type === 'clusterAttribution' ? item.entity : item.clusterAttribution
    },
    {
      text: 'Attribution (Addr)',
      value: 'addressAttribution',
      sortable: false
    },
    // {
    //   text: 'Address Balance',
    //   value: 'addressBalanceBTC', // TODO: employ network/symbol selection and make this dynamic
    //   sortable: false
    // },
    // {
    //   text: 'Cluster Balance',
    //   value: 'clusterBalanceBTC', // TODO: employ network/symbol selection and make this dynamic
    //   sortable: false
    // },
    // {
    //   text: 'Attribution (Cl) Balance',
    //   value: 'attributionBalanceBTC', // TODO: employ network/symbol selection and make this dynamic
    //   sortable: false
    // },
    {
      text: 'Amount',
      value: '',
      sortable: true,
      transform: (item: ReportTransaction) => this.formatAmount(item.amount, item.isOutput),
      itemClass: (item: ReportTransaction) => `${this.directionClass(item.isOutput)}`,
      icon: {
        func: (symbol: string) => `$${symbol}`,
        flag: 'symbol'
      }
    },
    {
      text: 'Time',
      value: '',
      sortable: true,
      sorted: 'desc',
      transform: (item: ReportTransaction) => this.formatDate(timeToMilliseconds(item.time)),
      width: '120px'
    },
    {
      text: 'Flow Hops',
      value: '',
      sortable: true,
      transform: (item: ReportTransaction) => {
        if (this.transactionFlowTarget) {
          return getTargetFlowData(this.transactionFlowTarget, item.flows).hops
        }
      }
    },
    {
      text: 'Flow Amount',
      value: '',
      sortable: true,
      transform: (item: ReportTransaction) => {
        if (this.transactionFlowTarget) {
          return getTargetFlowData(this.transactionFlowTarget, item.flows).amount
        }
      }
    }
  ]

  get pagedReport() {
    const start = (this.page - 1) * this.perPage
    const end = this.page * this.perPage
    return this.report.slice(start, end)
  }

  get transactionFlowTarget() {
    return this.$store.state.transactionFlowTarget
  }

  set transactionFlowTarget(transactionFlowTarget: TransactionFlowTarget) {
    this.$store.dispatch('setTransactionFlowTarget', transactionFlowTarget)
  }

  public decimalFormatter!: (n: number | string) => string

  public onPageUpdated(page: number) {
    this.page = page
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
  }

  public onSort(header: Header, direction: SortDirection | null, index: number) {
    if (direction != null) {
      let field
      let condition
      switch (header.text) {
        case 'Flow Amount':
          field = 'flows.targetTransaction.amount'
          condition = (flow: any) =>
            flow.targetTransaction.id === this.transactionFlowTarget?.targetTransaction.id &&
            flow.targetTransaction.isOutput === this.transactionFlowTarget?.targetTransaction.isOutput &&
            flow.targetTransaction.index === this.transactionFlowTarget?.targetTransaction.index &&
            flow.sending === this.transactionFlowTarget?.sending
          break
        case 'Flow Hops':
          field = 'flows.minHops'
          condition = (flow: any) =>
            flow.targetTransaction.id === this.transactionFlowTarget?.targetTransaction.id &&
            flow.targetTransaction.isOutput === this.transactionFlowTarget?.targetTransaction.isOutput &&
            flow.targetTransaction.index === this.transactionFlowTarget?.targetTransaction.index &&
            flow.sending === this.transactionFlowTarget?.sending
          break
        case 'Amount':
          field = 'amount'
          break
        case 'Time':
        default:
          field = 'time'
      }
      this.$store.dispatch('sortReport', { field, direction, condition })
    }
  }

  public formatAmount(amount: string | number, isOutput: boolean) {
    return amount
  }

  public directionClass(isOutput: boolean) {
    return isOutput ? 'value-output' : 'value-input'
  }

  @Watch('settings', { deep: true })
  toggleRounding() {
    if (this.settings.roundDecimalsSwitch) {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n, 8)
    } else {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n)
    }
  }

  created() {
    if (this.transactionFlowTargets[0]) {
      this.transactionFlowTarget = this.transactionFlowTargets[0]
    }

    this.$store.dispatch('getReport')
    this.$store.dispatch('sortReport', { field: 'time', direction: 'asc' })
  }

  downloadFile() {
    const csvData = transformReportsForCSV(this.report, this.transactionFlowTarget)
    const currentName = this.currentInvestigation.name ? this.currentInvestigation.name : 'unnamed'
    const reportName: string = `report-${currentName.replaceAll(/\W/g, '-')}`
    downloadCSV(csvData, `${reportName}-${Date.now()}`)
  }

  format(item: TransactionFlowTarget) {
    return formatFlowTarget(item, this.transactionFlowTargetsMap)
  }

  public formatDate(time: number): string {
    return this.$store.state.formatDate(time, true)
  }

  @Watch('transactionFlowTarget')
  updateTransactionFlowTarget() {
    this.flowSelected += 1
  }
}
