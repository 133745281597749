
import { titleCase } from '@/utils/general'
import { Component, Vue } from 'vue-property-decorator'
import TransactionBitcoin from './TransactionBitcoin.vue'
import TransactionEthereum from './TransactionEthereum.vue'
import TransactionTron from './TransactionTron.vue'

@Component({
  components: {
    TransactionBitcoin,
    TransactionEthereum,
    TransactionTron
  }
})
export default class Transaction extends Vue {
  private network: string = ''
  private txnId: string = ''
  private networkDisplayName: string = ''
  public networkType: string = ''

  get supportedNetworks() {
    return this.$store.state.supportedNetworks
  }

  get bitcoinNetworks() {
    return this.$store.state.bitcoinNetworks
  }

  get ethereumNetworks() {
    return this.$store.state.ethereumNetworks
  }
  
  get tronNetworks() {
    return this.$store.state.tronNetworks
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.txnId = this.$route.params.txnId
    this.networkDisplayName = titleCase(this.network)
    this.networkType = this.determineNetworkType()
    this.resetTransaction()
    this.getTransaction()
  }

  private determineNetworkType(): string {
    if (this.bitcoinNetworks.includes(this.network)) {
      return 'bitcoin'
    }
    if (this.ethereumNetworks.includes(this.network)) {
      return 'ethereum'
    }
    if (this.tronNetworks.includes(this.network)) {
      return 'tron'
    }
    return ''
  }

  private resetTransaction() {
    this.$store.dispatch('resetTransaction')
  }

  private getTransaction() {
    if (this.supportedNetworks.includes(this.network)) {
      this.$store.dispatch('getTransaction', { network: this.network, id: this.txnId })
    }
  }
}
