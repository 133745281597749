
import { FormComponent } from '@/components/Dialog.vue'
import { StringGenericMap, VizTransaction } from '@/store/investigations/viz';
import { filter } from '@/utils/filters';
import { cutMiddle } from '@/utils/general';
import { classifyNodeId } from '@/utils/viz';
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex';

@Component({
  computed: mapState(['txnOptions'])
})
export default class IOOptions extends FormComponent {
  public txnOptions!: StringGenericMap<VizTransaction[]>

  private selected: Set<string> = new Set<string>()

  public classifyNodeId = classifyNodeId
  public cutMiddle = cutMiddle

  get txnNodes() {
    return Object.keys(this.txnOptions)
  }

  public inputs(txnNode: string, txns: VizTransaction[]) {
    return filter(txns, txn => txn.receiver === txnNode)
  }

  public outputs(txnNode: string, txns: VizTransaction[]) {
    return filter(txns, txn => txn.sender === txnNode)
  }

  public toggleIOSelected(io: VizTransaction) {
    const ioKey = `${io.sender}${io.receiver}`
    if (this.selected.has(ioKey)) {
      this.selected.delete(ioKey)
    } else {
      this.selected.add(ioKey)
    }
    this.$forceUpdate() // without this the icons won't update
  }

  public ioSelectedIcon(io: VizTransaction) {
    const ioKey = `${io.sender}${io.receiver}`
    if (this.selected.has(ioKey)) {
      return 'mdi-checkbox-marked'
    } else {
      return 'mdi-checkbox-blank-outline'
    }
  }

  doAction() {
    const selected: StringGenericMap<VizTransaction[]> = {}
    for (const [txnNode, txns] of Object.entries(this.txnOptions)) {
      selected[txnNode] = []
      for (const txn of txns) {
        if (this.selected.has(`${txn.sender}${txn.receiver}`)) {
          selected[txnNode].push(txn)
        }
      }
      if (selected[txnNode].length === 0) {
        delete selected[txnNode]
      }
    }
    this.$store.dispatch('graphSelectedIO', { selected })
  }

  @Watch('txnOptions')
  resetSelected() {
    this.selected.clear()
  }
}
