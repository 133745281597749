import { State } from '../index'
import { Commit, Dispatch } from 'vuex'
import { Api, PaginatedRequest, SortMap, failedResponse, AggregatedBitcoinTransaction, LinkTransaction, EntityType, BasicLinkData, TransactionLedgerResponse, TxnTriplet, TripletCluster, SimplifiedBitcoinTransaction } from '@/utils/api'
import { ExtendedCoinbase, ExtendedInput, Output, SimpleAggregatedTransaction, isExtendedCoinbase, isExtendedTx } from '@/types/bitcoin'
import { addBigNum, stringToNumber } from '@/utils/bignum'
import { filter } from '@/utils/filters'
import { classifyNodeId, graphDataFromLedger, NodeType, nodeTypeToIdString } from '@/utils/viz'
import { FormattedNode, IdType, Target, VizTransaction } from './viz'
import { FormattedSummaryLink, isTransactionLevel } from '@/utils/graph-links'
import { SortDirection } from '@/subcomponents/types/serverTable'
import { ClusteredCounterparty, isFormattedTransaction, SimpleAggregatedEthereumTransaction } from '@/types/eth'
import { isTransaction } from '@/types/tron'

interface PageSortRequest extends PaginatedRequest {
  sorts: SortMap
}

interface TransactionsSortRequest extends PageSortRequest {
  aggregated?: boolean
}

export interface AggregatedTypedCounterparty {
  entity: string
  type: 'cluster' | 'address'
  attribution?: string
  addresses?: AggregatedAddress[]
  value: number | string
}

export interface AggregatedAddress {
  address: string
  value: string | number
}

export interface AggregatedTypedBitcoinTransaction {
  entity: string
  type: 'cluster' | 'address'
  attribution: string | null
  addresses?: AggregatedAddress[]
  amount: number
  id: string
  time: number
  isOutput: boolean
  minIndex: number
}

export interface LinkTransactionWithCounterparties extends LinkTransaction {
  counterparties?: AggregatedTypedCounterparty[]
}

function isTransactionLedgerResponse(res: TransactionLedgerResponse | LinkTransaction[]): res is TransactionLedgerResponse {
  return (res as TransactionLedgerResponse).ledger != null
}

function isLinkTransaction(txn: SimpleAggregatedTransaction | LinkTransaction): txn is LinkTransaction {
  return (txn as LinkTransaction).relationshipHash != null
}

export const tabularState = {
  fullTransactions: <AggregatedBitcoinTransaction[] | AggregatedTypedBitcoinTransaction[] | LinkTransaction[]>[],
  transactionsTotal: <number | undefined>undefined,
  transactionsLoading: <boolean>false,
  expandedTransaction: <SimpleAggregatedTransaction | LinkTransaction | undefined>undefined,
  expandedCounterparties: <AggregatedTypedCounterparty[]>[],
  counterparties: <AggregatedTypedCounterparty[] | ClusteredCounterparty[]>[],
  sidepanelTab: <string>'',
  summaryLinkDirection: <0 | 1>0,
  lifoTransaction: <SimpleAggregatedEthereumTransaction | undefined>undefined,
  lifoTransactions: new  Map<string, number>(),
  indirectLifoTransaction: <SimpleAggregatedEthereumTransaction | undefined>undefined,
  indirectLifoAmounts: new Map<string, number>(),
  graphedLifoTransaction: <boolean>false
}

export const tabularMutations = {
  SET_FULL_TRANSACTIONS(
    state: State,
    { transactions }: { transactions:
        AggregatedBitcoinTransaction[] |
        AggregatedTypedBitcoinTransaction[] |
        LinkTransactionWithCounterparties[]
      }
  ) {
    state.fullTransactions = transactions
  },
  SET_TXN_TOTAL(state: State, total: number) {
    state.transactionsTotal = total
  },
  TRANSACTIONS_LOADING(state: State) {
    state.transactionsLoading = true
  },
  TRANSACTIONS_LOADED(state: State) {
    state.transactionsLoading = false
  },
  SET_EXPANDED_TRANSACTION(state: State, { txn }: { txn: SimpleAggregatedTransaction | LinkTransaction }) {
    state.expandedTransaction = txn
  },
  SET_COUNTERPARTIES(
    state: State,
    { counterparties, expansion }: { counterparties: AggregatedTypedCounterparty[]; expansion: boolean }
  ) {
    if (expansion) state.expandedCounterparties = counterparties
    else state.counterparties = counterparties
  },
  CLEAR_TABULAR(state: State) {
    state.fullTransactions = []
    state.transactionsTotal = undefined
    state.expandedCounterparties = []
    state.counterparties = []
  },
  SET_SIDEPANEL_TAB(state: State, tab: string) {
    state.sidepanelTab = tab
  },
  SET_SUMMARY_LINK_DIRECTION(state: State, direction: 0 | 1) {
    state.summaryLinkDirection = direction
  },
  SET_LIFO_TRANSACTION(state: State, transaction: SimpleAggregatedEthereumTransaction) {
    state.lifoTransaction = transaction
  },
  SET_LIFO_TRANSACTIONS(state: State, transactions: Map<string, number>) {
    state.lifoTransactions = transactions
  },
  SET_INDIRECT_LIFO_TRANSACTION(state: State, transaction?: SimpleAggregatedEthereumTransaction) {
    state.indirectLifoTransaction = transaction
  },
  SET_INDIRECT_LIFO_AMOUNTS(state: State, amounts: Map<string, number>) {
    state.indirectLifoAmounts = amounts
  },
  GRAPHED_LIFO_TXN(state: State, graphed: boolean) {
    state.graphedLifoTransaction = graphed
  }
}

export const tabularActions = (state: State, api: Api, dispatch: Dispatch) => ({
  async setTxnsPage({ commit }: { commit: Commit }, { page, perPage, sorts }: TransactionsSortRequest) {
    commit('TRANSACTIONS_LOADING')
    dispatch('getCounterpartiesDetails', { expansion: true }) // clears expansion-related store vars
    let id = '',
      network = state.targetNetwork,
      type: IdType | '' = ''
    if (state.target != null) {
      const { id: targetID, network: targetNetwork, type: targetType } = state.target
      id = targetID
      network = targetNetwork
      type = targetType
    }
    if (type && type !== 'transaction') {
      // address, cluster, or attribution
      let res
      const streamTo = state.serverEventsId
      if (type === 'address') {
        api.getAddressLedgerCount({ network, id, aggregated: true, streamTo })
        res = await api.getAddressLedger({
          id,
          network,
          page,
          perPage,
          sorts,
          txnData: true,
          ioData: false,
          fullTxns: false,
          aggregated: true
        })
      } else if (type === 'cluster') {
        api.getClusterLedgerCount({ network, id, aggregated: true, streamTo })
        res = await api.getClusterLedger({
          id,
          network,
          page,
          perPage,
          sorts,
          txnData: true,
          ioData: false,
          fullTxns: false,
          aggregated: true
        })
      } else if (type === 'attribution') {
        api.getClusterLedgerCount({ network, id, aggregated: true, attribution: true, streamTo })
        res = await api.getClusterLedger({
          id,
          network,
          page,
          perPage,
          sorts,
          txnData: true,
          ioData: false,
          fullTxns: false,
          aggregated: true,
          attribution: true
        })
      }
      if (failedResponse(res)) {
        // try again with no sorts
        if (type === 'address') {
          api.getAddressLedgerCount({ network, id, aggregated: true, streamTo })
          res = await api.getAddressLedger({
            id,
            network,
            page,
            perPage,
            txnData: true,
            ioData: false,
            fullTxns: false,
            aggregated: true
          })
        } else if (type === 'cluster') {
          api.getClusterLedgerCount({ network, id, aggregated: true, streamTo })
          res = await api.getClusterLedger({
            id,
            network,
            page,
            perPage,
            txnData: true,
            ioData: false,
            fullTxns: false,
            aggregated: true
          })
        } else if (type === 'attribution') {
          api.getClusterLedgerCount({ network, id, aggregated: true, attribution: true, streamTo })
          res = await api.getClusterLedger({
            id,
            network,
            page,
            perPage,
            txnData: true,
            ioData: false,
            fullTxns: false,
            aggregated: true,
            attribution: true
          })
        }
        // now has definitely failed
        if (failedResponse(res)) {
          dispatch('updateSnackbar', {
            show: state.enableErrors,
            text: `${res.message} ${JSON.stringify(res.original)}`,
            timeout: -1
          })
          commit('TRANSACTIONS_LOADED')
          return
        }
      }
      if (res) {
        let field: 'address' | 'cluster' | 'clusterAttribution'
        if (type === 'attribution') field = 'clusterAttribution'
        else field = type
        const transactions = (res.simple as SimpleAggregatedTransaction[]).map((t) => ({
          ...t,
          [field]: id
        }))
        // filter out outputs where there's an input with the same txid and symbol (i.e. filter out change)
        // const inputTxns = new Set<string>()
        // const outputTxnIndexes = new Map<string, number>()
        // let index = 0
        // while (index < transactions.length) {
        //   const { id: txid, isOutput, symbol } = transactions[index]
        //   const key = `${txid}${symbol}`
        //   if (isOutput) {
        //     if (inputTxns.has(key)) {
        //       // delete the current index
        //       transactions.splice(index, 1)
        //       inputTxns.delete(key)
        //     } else {
        //       outputTxnIndexes.set(key, index)
        //       index++
        //     }
        //   } else {
        //     const deleteIndex = outputTxnIndexes.get(key)
        //     if (deleteIndex != null) {
        //       // delete the output index
        //       transactions.splice(deleteIndex, 1)
        //       outputTxnIndexes.delete(key)
        //     } else {
        //       inputTxns.add(key)
        //       index++
        //     }
        //   }
        // }

        commit('SET_FULL_TRANSACTIONS', { transactions })
        if (network === 'bitcoin') {
          dispatch('cacheTxnHeaders', { network, txids: transactions.map(t => t.id) })
        } else {
          Array.from(new Set(transactions.map(t => t.id))).map(id => dispatch('getReceipts', { network, id }))
        }
      }
    } else {
      let res
      if (type === 'transaction') {
        // it's a txn node
        commit('SET_TRANSACTION', { transaction: undefined })
        await dispatch('getTransaction', { network, id })
        const transaction = state.transaction
        if (transaction != null && isExtendedTx(transaction) && transaction.vin != null) {
          const txns: TxnTriplet[] = transaction.vin
            .map((i: ExtendedInput | ExtendedCoinbase) =>
              isExtendedCoinbase(i)
                ? null
                : {
                    id: i.previousOutput,
                    isOutput: true,
                    index: i.vout
                  }
            )
            .filter((i: any) => i != null) as TxnTriplet[]
          const unique = [...new Set(txns.map((i) => i.id))]
          dispatch('cacheTxnHeaders', { network, txids: unique })
        }
        
        if (transaction != null && isExtendedTx(transaction)) {
          if (transaction.vout != null) {
            const addresses = filter((transaction.vout as Array<Output>).map(o => o.scriptPubKey.address), a => a != null)
            await dispatch('getTargetClusters', { network, addresses })
          }
          if (transaction.vin != null) {
            const addresses = filter(
              (transaction.vin as Array<ExtendedCoinbase | ExtendedInput>).
                map(i => isExtendedCoinbase(i) ? i.coinbase : i.spentOutput.address),
              a => a != null)
            await dispatch('getTargetClusters', { network, addresses })
          }
        }
      } else if (state.selectedLink != null) {
        // it's a link
        const link = state.selectedLink
        if (isTransactionLevel(link)) {
          const { source, target, reversedLink } = link
          const { id: sourceNodeId } = source as FormattedNode
          const { id: targetNodeId } = target as FormattedNode
          let isInput = false, isOutput = false
          const { id: sourceId, type: sourceType, network } = classifyNodeId(sourceNodeId)
          if (sourceType === 'transaction' || reversedLink != null) {
            id = sourceId
            isOutput = true
          }
          else {
            const { id: targetId, type: targetType } = classifyNodeId(targetNodeId)
            if (targetType === 'transaction' || reversedLink != null) {
              id = targetId
              isInput = true
            }
          }
          if (id !== '') {
            commit('SET_TRANSACTION', { transaction: undefined })
            commit('SET_TARGET_NETWORK', network)
            await dispatch('getTransaction', { network, id })
            const transaction = state.transaction
            if (transaction != null) {
              if (isExtendedTx(transaction)) {
                if (isOutput && transaction.vout != null) {
                  const addresses = filter((transaction.vout as Array<Output>).map(o => o.scriptPubKey.address), a => a != null)
                  await dispatch('getTargetClusters', { network, addresses })
                }
                if (isInput && transaction.vin != null) {
                  const txns: TxnTriplet[] = transaction.vin
                    .map((i: ExtendedInput | ExtendedCoinbase) =>
                      isExtendedCoinbase(i)
                        ? null
                        : {
                            id: i.previousOutput,
                            isOutput: true,
                            index: i.vout
                          }
                    )
                    .filter((i: any) => i != null) as TxnTriplet[]
                  const unique = [...new Set(txns.map((i) => i.id))]
                  dispatch('cacheTxnHeaders', { network, txids: unique })

                  const addresses = filter(
                    (transaction.vin as Array<ExtendedCoinbase | ExtendedInput>).
                      map(i => isExtendedCoinbase(i) ? i.coinbase : i.spentOutput.address),
                    a => a != null)
                  await dispatch('getTargetClusters', { network, addresses })
                }
              } else if (isFormattedTransaction(transaction) || isTransaction(transaction)) {
                // pull inputs/outputs from hbase, since it's not always in the transaction data
                const transactions = await api.getTransactionLedger({
                  network,
                  id,
                  page: 1,
                  perPage: 100,
                  isOutput: (isOutput && !isInput) ? true : (isInput && !isOutput) ? false : undefined,
                  simple: true
                })
                if (!failedResponse(transactions) && transactions != null) {
                  const counterparties: ClusteredCounterparty[] = (transactions.ledger as SimplifiedBitcoinTransaction[]).map(t => ({
                    address: t.address,
                    cluster: t.cluster ?? undefined,
                    clusterAttribution: t.clusterAttribution ?? undefined,
                    value: t.amount,
                    symbol: t.symbol,
                    isOutput: t.isOutput
                  }))
                  commit('SET_COUNTERPARTIES', { counterparties, expansion: false })
                }
              }
            }
          }
        } else { // it's a summary link, grab aggregated pair transactions
          const { source, target, linkSummaries } = link
          const { id: sourceId, type: sourceType } = classifyNodeId(source.id)
          const { id: targetId, type: targetType } = classifyNodeId(target.id)
          
          network = state.targetNetwork || (linkSummaries.length ? linkSummaries[0].network : '') // should never be ''

          const chosenNetworkSummary = linkSummaries.find(s => s.network === network)
          if (chosenNetworkSummary != null) {
            commit('SET_TXN_TOTAL', chosenNetworkSummary.transactions)
          }

          let sender, senderType, receiver, receiverType
          if (state.summaryLinkDirection === 0) {
            sender = sourceId
            senderType = sourceType as EntityType
            receiver = targetId
            receiverType = targetType as EntityType
          } else {
            sender = targetId
            senderType = targetType as EntityType
            receiver = sourceId
            receiverType = sourceType as EntityType
          }
          const linkData: BasicLinkData = { sender, senderType, receiver, receiverType }

          let sort: undefined | 'cryptotime' | 'amount' = undefined, sortDirection: undefined | SortDirection = undefined
          const sortKeys = Object.keys(sorts)
          if (sortKeys.length) {
            sort = sortKeys[0] as ('cryptotime' | 'amount')
            sortDirection = sorts[sort]
          }

          res = state.transactionsTotal === -1 ? null : await api.getLinkTransactions({
            network,
            linkData,
            page,
            perPage,
            sort // sort direction doesn't currently exist on the backend
          })
        }
      }
      if (failedResponse(res)) {
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `Transaction data failed to load: ${JSON.stringify(res.original)}`,
          timeout: -1
        })
        commit('TRANSACTIONS_LOADED')
        return
      }
      if (res != null) {
        if (isTransactionLedgerResponse(res)) {
          const { total } = res
          const aggregates = res.ledger as AggregatedBitcoinTransaction[]
          let filtered = aggregates
          let addressNode = false
          if (type !== 'transaction') {
            // target is a link, filter by cluster
            const { source, target } = state.selectedLink!
            const { id: sourceId } = source as FormattedNode
            const { id: targetId } = target as FormattedNode
            let filterBy: NodeType | undefined = undefined
            if (classifyNodeId(sourceId).type === 'transaction') {
              filterBy = classifyNodeId(targetId)
            } else if (classifyNodeId(targetId).type === 'transaction') {
              filterBy = classifyNodeId(sourceId)
            }
            if (filterBy) {
              const { id: nodeId, type: nodeType } = filterBy
              addressNode = nodeType === 'address'
              const nodeAddressSet = new Set(
                filter(state.nodes, n => classifyNodeId(n.id).type === 'address')
                .map(n => classifyNodeId(n.id).id)
              )
              filtered = filter(filtered, (t) => {
                const { address, cluster, clusterAttribution } = t
                switch (nodeType) {
                  case 'address':
                    return nodeId === address
                  case 'cluster':
                    // don't include it if the address is graphed separately
                    return nodeId === cluster && address != null && !nodeAddressSet.has(address)
                  case 'attribution':
                    // don't include it if the address is graphed separately
                    return nodeId === clusterAttribution && address != null && !nodeAddressSet.has(address)
                  default: // no coded type, compare to everything
                    return nodeId === address || nodeId === cluster || nodeId === clusterAttribution
                }
              })
            }
          }
          // map each cluster/address to transactions, and address list if applicable
          const entityBreakdownMap: {
            [key: string]: {
              type: 'cluster' | 'address'
              addresses?: { [address: string]: number }
              attribution: string | null
              amount: number
              id: string
              time: number
              isOutput: boolean
              minIndex: number
            }
          } = {}
          for (const transaction of filtered) {
            const { address, cluster, clusterAttribution, amount, id, time, isOutput, minIndex } = transaction
            let key: string, type: 'address' | 'cluster'
            if (cluster && !addressNode) {
              key = `${cluster}|${isOutput}`
              type = 'cluster'
            } else {
              key = `${address}|${isOutput}`
              type = 'address'
            }
            if (address) {
              // should always be true
              if (key in entityBreakdownMap) {
                entityBreakdownMap[key].amount = stringToNumber(addBigNum(entityBreakdownMap[key].amount, amount))
                if (minIndex < entityBreakdownMap[key].minIndex) entityBreakdownMap[key].minIndex = minIndex
                if (type === 'cluster') {
                  const { addresses } = entityBreakdownMap[key]
                  if (addresses) {
                    // should always be true
                    if (address in addresses) {
                      addresses[address] = stringToNumber(addBigNum(addresses[address], amount))
                    } else {
                      addresses[address] = amount
                    }
                  }
                }
              } else {
                entityBreakdownMap[key] = {
                  type,
                  addresses: { [address]: amount },
                  attribution: clusterAttribution,
                  amount,
                  id,
                  time,
                  isOutput,
                  minIndex
                }
              }
            }
          }
  
          const transactions: AggregatedTypedBitcoinTransaction[] = Object.entries(entityBreakdownMap).map((entry) => {
            const [key, value] = entry
            const [entity] = key.split('|')
            const { addresses } = value
            return {
              entity,
              ...value,
              addresses: addresses ? Object.entries(addresses).map(([address, value]) => ({ address, value })) : undefined
            }
          })
          commit('SET_FULL_TRANSACTIONS', { transactions })
          commit('SET_TXN_TOTAL', total)
        } else { // res is LinkTransaction[], dealing with a summary link
          // get output clusters for all transactions in res
          if (network === 'bitcoin') {
            const txnOutputs = await Promise.all(res.map(linkTxn => api.getTransactionLedger({
              network,
              id: linkTxn.txid,
              page: 1,
              perPage: 1000,
              simple: true,
              isOutput: true,
              aggregated: true,
              groupBy: 'address'
            })))
            for (const [index, outputs] of txnOutputs.entries()) {
              if (!failedResponse(outputs) && outputs != null) {
                // aggregate and format as counterparty
                const formattedOutputs = (<AggregatedBitcoinTransaction[]>outputs.ledger)
                .reduce((agg, { address, cluster, clusterAttribution, amount }) => {
                  const nodeType: NodeType = clusterAttribution ? { id: clusterAttribution, type: 'attribution', network } :
                    cluster ? { id: cluster, type: 'cluster', network } : { id: address!, type: 'address', network }
                  const nodeId = nodeTypeToIdString(nodeType)
                  if (nodeId in agg) {
                    const currentAgg = agg[nodeId]
                    currentAgg.value = addBigNum(currentAgg.value, amount)
                    if (nodeType.type !== 'address' && currentAgg.addresses != null) {
                      currentAgg.addresses.push({ address: address!, value: amount })
                    }
                  } else {
                    agg[nodeId] = {
                      entity: nodeType.id,
                      type: nodeType.type === 'address' ? 'address' : 'cluster',
                      attribution: clusterAttribution ? clusterAttribution : undefined,
                      addresses: nodeType.type === 'address' ? undefined : [{ address: address!, value: amount }],
                      value: amount
                    }
                  }
                  return agg
                }, {} as { [key: string]: AggregatedTypedCounterparty });
  
                (<LinkTransactionWithCounterparties[]>res)[index].counterparties = Object.values(formattedOutputs)
              }
            }

            dispatch('cacheTxnHeaders', { network, txids: res.map(t => t.txid) })
          } else { // it's EVM
            (<LinkTransactionWithCounterparties[]>res) = res.map(t => {
              dispatch('getReceipts', { network, id: t.txid })
              const { target } = state.selectedLink as FormattedSummaryLink
              const { id: targetId, type: targetType } = classifyNodeId(target.id)
              return {
                ...t,
                counterparties: [{
                  entity: targetId,
                  type: targetType === 'address' ? 'address' : 'cluster',
                  attribution: targetType === 'attribution' ? targetId : undefined,
                  addresses: targetType === 'address' ? undefined : [],
                  value: t.amount
                }]
              }
            })
          }

          commit('SET_FULL_TRANSACTIONS', { transactions: res })
        }
      }
    }

    commit('TRANSACTIONS_LOADED')
  },
  async getCounterpartiesDetails(
    { commit }: { commit: Commit },
    { network, txn, expansion }: { network: string; txn?: SimpleAggregatedTransaction | LinkTransaction; expansion: boolean }
  ) {
    if (expansion) {
      commit('SET_EXPANDED_TRANSACTION', { txn })
    }
    if (txn != null) {
      const id = isLinkTransaction(txn) ? txn.txid : txn.id
      if (!isLinkTransaction(txn)) {
        await dispatch('getTargetClusters', {
          network,
          addresses: Array.from(new Set(txn.counterparties.map((c) => c.address)))
        })
      }
      if (expansion) {
        commit('SET_TRANSACTION', { transaction: undefined })
        if (id != null) {
          await dispatch('getTransaction', { network, id })
          const transaction = state.transaction
          if (transaction != null) {
            if (isExtendedTx(transaction)) {
              const txns = transaction.vin
                .map(i =>
                  isExtendedCoinbase(i)
                    ? null
                    : {
                        id: i.previousOutput,
                        isOutput: true,
                        index: i.vout
                      }
                )
                .filter((i: any) => i != null) as TxnTriplet[]
              const unique = [...new Set(txns.map((i) => i.id))]
              dispatch('cacheTxnHeaders', { network, txids: unique })

              if (isLinkTransaction(txn)) {
                const addresses = filter(transaction.vout.map(o => o.scriptPubKey.address), a => a != null) as string[]
                await dispatch('getTargetClusters', { network, addresses })
              }
            }
          }
        }
      }
      
      const counterpartiesAgg: {
        [entity: string]: {
          type: 'cluster' | 'address'
          attribution?: string
          addresses?: { [address: string]: string | number }
          value: string | number
        }
      } = {}
      if (isLinkTransaction(txn) && state.transaction != null && isExtendedTx(state.transaction)) { // aggregate output "counterparties"
        for (const { scriptPubKey, value } of state.transaction.vout) {
          const { address } = scriptPubKey
          if (address != null) {
            const cluster = state.shared.clusterAddressCache.get(address)
            if (cluster) {
              const { id: clusterId, topAttribution: attribution } = cluster
              if (clusterId in counterpartiesAgg) {
                counterpartiesAgg[clusterId].value = addBigNum(counterpartiesAgg[clusterId].value, value)
                const { addresses } = counterpartiesAgg[clusterId]
                if (addresses) {
                  // this should always be true
                  if (address in addresses) {
                    addresses[address] = addBigNum(addresses[address], value)
                  } else {
                    addresses[address] = value
                  }
                }
              } else {
                counterpartiesAgg[clusterId] = {
                  type: 'cluster',
                  attribution: attribution ?? undefined,
                  addresses: { [address]: value },
                  value
                }
              }
            } else {
              if (address in counterpartiesAgg) {
                counterpartiesAgg[address].value = addBigNum(counterpartiesAgg[address].value, value)
              } else {
                counterpartiesAgg[address] = {
                  type: 'address',
                  value
                }
              }
            }
          }
        }
      } else if (!isLinkTransaction(txn)) { // use the built-in counterparties with cluster data to aggregate
        for (const counterparty of txn.counterparties) {
          const { address, value } = counterparty
          const cluster = state.shared.clusterAddressCache.get(address)
          if (cluster) {
            const { id: clusterId, topAttribution: attribution } = cluster
            if (clusterId in counterpartiesAgg) {
              counterpartiesAgg[clusterId].value = addBigNum(counterpartiesAgg[clusterId].value, value)
              const { addresses } = counterpartiesAgg[clusterId]
              if (addresses) {
                // this should always be true
                if (address in addresses) {
                  addresses[address] = addBigNum(addresses[address], value)
                } else {
                  addresses[address] = value
                }
              }
            } else {
              counterpartiesAgg[clusterId] = {
                type: 'cluster',
                attribution: attribution ?? undefined,
                addresses: { [address]: value },
                value
              }
            }
          } else {
            if (address in counterpartiesAgg) {
              counterpartiesAgg[address].value = addBigNum(counterpartiesAgg[address].value, value)
            } else {
              counterpartiesAgg[address] = {
                type: 'address',
                value
              }
            }
          }
        }
      }
      const aggregated = Object.entries(counterpartiesAgg).map((entry) => {
        const [entity, data] = entry
        const { type, attribution, addresses, value } = data
        return {
          entity,
          type,
          attribution,
          addresses: addresses ? Object.entries(addresses).map(([address, value]) => ({ address, value })) : undefined,
          value
        }
      })
      commit('SET_COUNTERPARTIES', { counterparties: aggregated, expansion })
    } else {
      commit('SET_COUNTERPARTIES', { counterparties: [], expansion })
    }
  },
  setSidepanelTab({ commit }: { commit: Commit }, tab: string) {
    commit('SET_SIDEPANEL_TAB', tab)
  },
  setSummaryLinkDirection({ commit }: { commit: Commit }, { direction }: { direction: 0 | 1 }) {
    commit('SET_SUMMARY_LINK_DIRECTION', direction)
  },
  async graphTransaction(
    { commit }: { commit: Commit },
    { txn, counterparties, network, permanent }:
      {
        txn: SimpleAggregatedTransaction | LinkTransaction;
        counterparties?: AggregatedTypedCounterparty[];
        network: string,
        permanent?: boolean
      }
  ) {
    if (state.lifoTransaction != null) { // graphing from lifo, set up for transition to next txn
      commit('SET_LIFO_TRANSACTION', txn)
      commit('GRAPHED_LIFO_TXN', true)
      dispatch('targetToChange', { change: true })
    }
    const nodes: NodeType[] = []
    const txns: VizTransaction[] = []
    const newestLinks: string[] = []

    let id, isOutput = false, amount, symbol, time, node: NodeType
    if (isLinkTransaction(txn)) {
      ({ txid: id, amount, symbol, cryptotime: time} = txn)
      node = classifyNodeId((state.selectedLink as FormattedSummaryLink).source.id)
    } else {
      ({ id, isOutput, amount, symbol, time } = txn)
      node = state.target as Target
    }

    const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
    const nodeId = nodeTypeToIdString(node)
    let sender, receiver
    if (isOutput) {
      sender = ghost
      receiver = nodeId
    } else {
      sender = nodeId
      receiver = ghost
    }
    txns.push({
      id: `${sender}|${receiver}`,
      sender,
      receiver,
      amount,
      symbol,
      timestamp: time,
      permanent
    })
    newestLinks.push(`${sender}||${receiver}`)
    
    
    const linksData: BasicLinkData[] = []
    if (counterparties != null) {
      for (const cp of counterparties) {
        const { entity: cpEntityRaw, attribution, type, value } = cp
        const cpNode: NodeType = attribution ?
          { id: attribution, type: 'attribution', network } :
          { id: cpEntityRaw, type, network }
        nodes.push(cpNode)

        const cpNodeId = nodeTypeToIdString(cpNode)
        let cpSender: string, cpReceiver: string, summaryLinkString: string, summaryLinkData: BasicLinkData
        if (isOutput) {
          summaryLinkString = `${cpNodeId}||${nodeId}`
          summaryLinkData = {
            sender: cpNode.id,
            senderType: cpNode.type as EntityType,
            receiver: node.id,
            receiverType: node.type as EntityType
          }
          cpSender = cpNodeId
          cpReceiver = ghost
        } else {
          summaryLinkString = `${nodeId}||${cpNodeId}`
          summaryLinkData = {
            sender: node.id,
            senderType: node.type as EntityType,
            receiver: cpNode.id,
            receiverType: cpNode.type as EntityType,
          }
          cpSender = ghost
          cpReceiver = cpNodeId
        }
        // summary level, don't include self-link
        if (nodeId !== cpNodeId) {
          linksData.push(summaryLinkData)
          newestLinks.push(summaryLinkString)
        }

        txns.push({
          id: `${cpSender}|${cpReceiver}`,
          sender: cpSender,
          receiver: cpReceiver,
          amount: value,
          symbol,
          timestamp: time,
          permanent
        })
        newestLinks.push(`${cpSender}||${cpReceiver}`)
      }
    }

    commit('ADD_NEWEST_NODES', nodes.map(n => nodeTypeToIdString(n)))
    commit('ADD_NEWEST_LINKS', newestLinks)
    if (state.settings.autoLinksSwitch) {
      await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
    } else {
      await dispatch('graphNodePairs', { linksData, network, endLoading: false })
      await dispatch('traceIDs', { ids: nodes, endLoading: false })
    }

    commit('ADD_GRAPH_TXNS', txns)
    const data = graphDataFromLedger(txns, state.linkColors)
    dispatch('addToGraph', data)
  },
  async graphCounterparty(
    { commit }: { commit: Commit },
    { network, cp, value, cpIsOutput }: { network: string; cp: string; value?: number; cpIsOutput?: boolean }
  ) {
    const permanent = state.selectedLink != null
    const txn = state.expandedTransaction
    if (txn != null) {
      const cpNode = classifyNodeId(cp)
      const { id: cpNodeId, type: cpNodeType } = cpNode
      if (value == null) { // it's a cluster, look for the appropriate agg counterparty
        const counterparties = state.expandedCounterparties
        const counterparty = counterparties.find(c => {
          if (cpNodeType === 'cluster') {
            return c.type === 'cluster' && c.entity === cpNodeId
          } else {
            return c.attribution != null && c.attribution === cpNodeId
          }
        })
        if (counterparty != null) {
          await dispatch('graphTransaction', { network, txn, counterparties: [counterparty], permanent })
        }
      } else { // it's an address, graph manually
        const nodes: NodeType[] = []
        const txns: VizTransaction[] = []
        const newestLinks: string[] = []

        let id, isOutput = false, amount, symbol, time, node: NodeType
        if (isLinkTransaction(txn)) {
          ({ txid: id, amount, symbol, cryptotime: time} = txn)
          node = classifyNodeId((state.selectedLink as FormattedSummaryLink).source.id)
        } else {
          ({ id, isOutput, amount, symbol, time } = txn)
          node = state.target as Target
        }
        nodes.push(node)

        const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
        const nodeId = nodeTypeToIdString(node)
        let sender, receiver
        if (isOutput) {
          sender = ghost
          receiver = nodeId
        } else {
          sender = nodeId
          receiver = ghost
        }
        txns.push({
          id: `${sender}|${receiver}`,
          sender,
          receiver,
          amount,
          symbol,
          timestamp: time,
          permanent
        })
        newestLinks.push(`${sender}||${receiver}`)

        const linksData: BasicLinkData[] = []
        
        nodes.push(cpNode)

        let cpSender, cpReceiver
        if (isOutput) {
          linksData.push({
            sender: cpNodeId,
            senderType: cpNodeType as EntityType,
            receiver: node.id,
            receiverType: node.type as EntityType
          })
          newestLinks.push(`${cp}||${nodeId}`)

          cpSender = cp
          cpReceiver = ghost
        } else {
          linksData.push({
            sender: node.id,
            senderType: node.type as EntityType,
            receiver: cpNodeId,
            receiverType: cpNodeType as EntityType,
          })
          newestLinks.push(`${nodeId}||${cp}`)

          cpSender = ghost
          cpReceiver = cp
        }
        txns.push({
          id: `${cpSender}|${cpReceiver}`,
          sender: cpSender,
          receiver: cpReceiver,
          amount: value,
          symbol,
          timestamp: time,
          permanent
        })
        newestLinks.push(`${cpSender}||${cpReceiver}`)

        commit('ADD_NEWEST_NODES', nodes.map(n => nodeTypeToIdString(n)))
        commit('ADD_NEWEST_LINKS', newestLinks)
        if (state.settings.autoLinksSwitch) {
          await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
        } else {
          await dispatch('graphNodePairs', { linksData, network, endLoading: false })
          await dispatch('traceIDs', { ids: nodes, endLoading: false })
        }

        commit('ADD_GRAPH_TXNS', txns)
        const data = graphDataFromLedger(txns, state.linkColors)
        await dispatch('addToGraph', data)
      }
    } else if (state.transaction != null && isExtendedTx(state.transaction)) { // this only makes sense for bitcoin
      // txn node or txn-level link is selected, no "counterparties" to use
      const { txid, vin, vout, time } = state.transaction
      const txnNode = nodeTypeToIdString({ id: txid, type: 'transaction', network })
      const cpNode = classifyNodeId(cp)
      const { id: cpNodeId, type: cpNodeType } = cpNode
      if (value == null) { // it's a cluster, agg value from the transaction
        let aggValue: number | string = 0
        if (cpIsOutput && vout != null) {
          for (const output of vout) {
            const { scriptPubKey, value: outputValue } = output as Output
            const { address } = scriptPubKey
            if (address != null) {
              const cluster = state.shared.clusterAddressCache.get(address)
              if (cluster != null) {
                const { id: clusterId, topAttribution } = cluster
                if (
                  (cpNodeType === 'attribution' && topAttribution != null && topAttribution === cpNodeId) ||
                  (cpNodeType === 'cluster' && clusterId === cpNodeId)
                ) {
                  aggValue = addBigNum(aggValue, outputValue)
                }
              }
            }
          }
        } else if (!cpIsOutput && vin != null) {
          for (const input of vin) {
            if (!isExtendedCoinbase(input)) {
              const { address, value: inputValue } = (input as ExtendedInput).spentOutput
              if (address != null) {
                const cluster = state.shared.clusterAddressCache.get(address)
                if (cluster != null) {
                  const { id: clusterId, topAttribution } = cluster
                  if (
                    (cpNodeType === 'attribution' && topAttribution != null && topAttribution === cpNodeId) ||
                    (cpNodeType === 'cluster' && clusterId === cpNodeId)
                  ) {
                    aggValue = addBigNum(aggValue, inputValue)
                  }
                }
              }
            }
          }
        }
        value = stringToNumber(aggValue)
      }
      // set up txn graphing just for the given side
      const nodes: NodeType[] = []
      const txns: VizTransaction[] = []
      const newestLinks: string[] = []

      let sender, receiver
      if (cpIsOutput) {
        sender = txnNode
        receiver = cp
      } else {
        sender = cp
        receiver = txnNode
      }
      txns.push({
        id: `${sender}|${receiver}`,
        sender,
        receiver,
        amount: value,
        symbol: 'BTC',
        timestamp: time,
        permanent
      })
      newestLinks.push(`${sender}||${receiver}`)
      nodes.push(cpNode)

      commit('ADD_NEWEST_NODES', nodes.map(n => nodeTypeToIdString(n)))
      commit('ADD_NEWEST_LINKS', newestLinks)
      if (state.settings.autoLinksSwitch) {
        await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
      }

      commit('ADD_GRAPH_TXNS', txns)
      const data = graphDataFromLedger(txns, state.linkColors)
      await dispatch('addToGraph', data)
    }
  },
  hideDirectLIFO({ commit }: { commit: Commit }) {
    commit('SET_LIFO_TRANSACTION', undefined)
    commit('SET_LIFO_TRANSACTIONS', new Map<string, number>())

    dispatch('clearIndirectLIFO')
  },
  async showDirectLIFO(
    { commit }: { commit: Commit },
    { network, transaction }: { network: string; transaction: SimpleAggregatedTransaction | SimpleAggregatedEthereumTransaction }
  ) {
    commit('TRANSACTIONS_LOADING')
    commit('SET_LIFO_TRANSACTION', transaction)
    const { id, isOutput } = transaction
    const directFlows = await api.flows({
      network,
      transactions: [{
        id,
        isOutput,
        index: 0
      }],
      direction: isOutput ? 'source' : 'destination',
      trace: 'LIFO',
      hops: { eq: 0 },
      page: 1,
      perPage: 1000
    })
    // only relevant for swaps
    const sameTransactionFlows = await api.flows({
      network,
      transactions: [{
        id,
        isOutput,
        index: 0
      }],
      direction: isOutput ? 'destination' : 'source',
      trace: 'LIFO',
      hops: { eq: 0 },
      page: 1,
      perPage: 1000
    })

    const transactionFlows = new Map<string, number>()
    const triplets: TxnTriplet[] = []
    if (!failedResponse(directFlows) && directFlows != null) {
      for (const { source, destination, amount } of directFlows) {
        if (isOutput) {
          transactionFlows.set(destination.id, amount)
          triplets.push({ ...destination, index: 0 })
        } else {
          transactionFlows.set(source.id, amount)
          triplets.push({ ...source, index: 0 })
        }
      }
    }
    if (!failedResponse(sameTransactionFlows) && sameTransactionFlows != null) {
      for (const { source, destination, amount } of sameTransactionFlows) {
        if (isOutput && source.symbol !== transaction.symbol) {
          transactionFlows.set(source.id, amount)
          triplets.push({ ...source, index: 0 })
        } else if (destination.symbol !== transaction.symbol) {
          transactionFlows.set(destination.id, amount)
          triplets.push({ ...destination, index: 0 })
        }
      }
    }

    const lifoTransactions = [transaction]
    // get full txn data for all flow triplets
    const fullFlowTransactions = triplets.length > 0 ? await api.getBulkTransactionLedger({
      network,
      triplets,
      simple: true,
      aggregated: false,
    }) : undefined
    // get counterparties for all flows triplets
    const counterpartyTransactions = triplets.length > 0 ? await api.getBulkTransactionLedger({
      network,
      triplets: triplets.map(t => ({ ...t, isOutput: !t.isOutput })),
      simple: true,
      aggregated: false,
    }) : undefined
    if (
      !failedResponse(fullFlowTransactions) && fullFlowTransactions != null &&
      !failedResponse(counterpartyTransactions) && counterpartyTransactions != null
    ) {
      const counterpartyTxnMap = counterpartyTransactions.reduce(
        (map, txn) => {
          map[txn.id] = txn as SimplifiedBitcoinTransaction
          return map
        },
        {} as { [txn: string]: SimplifiedBitcoinTransaction }
      )
      ;(fullFlowTransactions as SimpleAggregatedEthereumTransaction[]).map(t => {
        const { address, cluster, clusterAttribution, amount: value, symbol, isOutput } = counterpartyTxnMap[t.id]
        t.counterparties = [{
          address: address ?? '',
          cluster: cluster ?? undefined,
          clusterAttribution: clusterAttribution ?? undefined,
          value,
          symbol,
          isOutput
        }]
        if (network !== 'bitcoin') dispatch('getReceipts', { network, id: t.id })
      })
      lifoTransactions.push(...fullFlowTransactions as SimpleAggregatedEthereumTransaction[])
      lifoTransactions.sort((a, b) => {
        if (a.time === b.time) {
          return a.isOutput ? -1 : 1
        }
        return b.time - a.time
      })
    }

    if (state.indirectLifoTransaction != null) {
      const { id: indirectId } = state.indirectLifoTransaction
      const indirectFlows = await Promise.all(triplets.map(triplet => api.flow({
        network,
        source: isOutput ? {
          sourceId: indirectId,
          sourceIsOutput: isOutput,
          sourceIndex: 0
        } : {
          sourceId: triplet.id,
          sourceIsOutput: triplet.isOutput,
          sourceIndex: 0
        },
        destination: isOutput ? {
          destinationId: triplet.id,
          destinationIsOutput: triplet.isOutput,
          destinationIndex: 0
        } : {
          destinationId: indirectId,
          destinationIsOutput: isOutput,
          destinationIndex: 0
        },
        trace: 'LIFO'
      })))

      const indirectAmounts = new Map<string, number>()
      for (const flow of indirectFlows) {
        if (!failedResponse(flow) && flow != null) {
          if (isOutput) {
            indirectAmounts.set(flow.destination.id, flow.amount)
          } else {
            indirectAmounts.set(flow.source.id, flow.amount)
          }
        }
      }

      commit('SET_INDIRECT_LIFO_AMOUNTS', indirectAmounts)
    }

    commit('SET_FULL_TRANSACTIONS', { transactions: lifoTransactions })
    commit('SET_TXN_TOTAL', lifoTransactions.length)
    commit('SET_LIFO_TRANSACTIONS', transactionFlows)
    commit('TRANSACTIONS_LOADED')
  },
  clearIndirectLIFO({ commit }: { commit: Commit }) {
    commit('SET_INDIRECT_LIFO_TRANSACTION', undefined)
  },
  setIndirectLIFO({ commit }: { commit: Commit }, { transaction }: { transaction: SimpleAggregatedEthereumTransaction }) {
    commit('SET_INDIRECT_LIFO_TRANSACTION', transaction)
  },
  async setNextDirectLIFO({ commit }: { commit: Commit }) {
    commit('GRAPHED_LIFO_TXN', false)
    commit('TRANSACTIONS_LOADING')

    // use previous LIFO transaction to find next one
    if (state.lifoTransaction != null) {
      const network = state.targetNetwork
      const nextTransactionResponse = await api.getTransactionLedger({
        network,
        id: state.lifoTransaction.id,
        isOutput: !state.lifoTransaction.isOutput,
        page: 1,
        perPage: 1,
      })
      if (!failedResponse(nextTransactionResponse) && nextTransactionResponse != null) {
        // use current lifo transaction as counterparty
        const counterparties: ClusteredCounterparty[] = [
          {
            address: state.lifoTransaction.address ?? '',
            cluster: state.lifoTransaction.cluster,
            clusterAttribution: state.lifoTransaction.clusterAttribution,
            value: state.lifoTransaction.amount,
            symbol: state.lifoTransaction.symbol,
            isOutput: state.lifoTransaction.isOutput
          }
        ]
        const nextTransaction = nextTransactionResponse.ledger[0] as SimplifiedBitcoinTransaction
        const transaction: SimpleAggregatedEthereumTransaction = {
          ...nextTransaction,
          cluster: nextTransaction.cluster ?? undefined,
          clusterAttribution: nextTransaction.clusterAttribution ?? undefined,
          counterparties,
          status: state.lifoTransaction.status
        }
        dispatch('showDirectLIFO', { network, transaction })
      }
    }
  }
})
