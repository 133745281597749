
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SimpleAggregatedTransaction, TxHeader } from '@/types/bitcoin'
import { cutEnd, cutMiddle, prettyRoundedNumber, timeToMilliseconds } from '@/utils/general'
import { AggregatedBitcoinTransaction, ClusterMetadata } from '@/utils/api'
import { FormattedLink, SettingsCollection, Target } from '@/store/investigations/viz'
import { nodeTypeToIdString } from '@/utils/viz'
import CopyBtn from './CopyBtn.vue'
import { LRUCache } from '@splunkdlt/cache'
import { ClusteredCounterparty, ContractInfo, FormattedLogEvent, isTokenProps, SimpleAggregatedEthereumTransaction } from '@/types/eth'

@Component({
  components: {
    CopyBtn
  }
})
export default class EntityTransaction extends Vue {
  private expanded = false
  public cutMiddle = cutMiddle
  public cutEnd = cutEnd
  public singleCounterpartyCluster?: string | null = null
  public singleCounterpartyHighestEntity?: string | null = null
  public heuristics = {
    haveHeader: false,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false
  }
  public contract = {
    present: false,
    name: '',
    event: ''
  }
  private graphedCounterparties: string[] = []
  private ungraphed = false
  private showExpandTooltip = false

  public prettyRoundedNumber = prettyRoundedNumber

  @Prop() item!: SimpleAggregatedTransaction | SimpleAggregatedEthereumTransaction
  @Prop() expandClick!: (item: SimpleAggregatedTransaction | SimpleAggregatedEthereumTransaction) => void
  @Prop() decimalFormatter!: (n: number | string) => string

  get expandText() {
    return this.expanded ? 'Collapse' : 'Expand'
  }

  get network() {
    return this.$store.state.target.network
  }

  get lifoTarget() {
    return this.$store.state.lifoTransaction != null &&
    this.$store.state.lifoTransaction.id === this.item.id &&
    this.$store.state.lifoTransaction.isOutput === this.item.isOutput
  }

  get lifoAmount(): number {
    if (this.$store.state.lifoTransaction != null && this.item.isOutput !== this.$store.state.lifoTransaction.isOutput) {
      return this.$store.state.lifoTransactions.get(this.item.id) || 0
    }
    return 0
  }

  get indirectLifoTarget() {
    if (this.$store.state.indirectLifoTransaction != null) {
      const { id, isOutput } = this.$store.state.lifoTransaction
      const { id: indirectId, isOutput: indirectIsOutput } = this.$store.state.indirectLifoTransaction
      return id === indirectId && isOutput === indirectIsOutput
    }
    return false
  }

  get indirectLifoAmount(): number {
    return this.$store.state.indirectLifoAmounts.get(this.item.id) || 0
  }

  get lifoDirection(): boolean { // true is previous, false is next
    return (this.$store.state.lifoTransaction.id === this.item.id) !== this.item.isOutput
  }

  get lifoCounterparty(): boolean {
    return !this.lifoTarget && this.lifoAmount !== 0
  }

  get singleCounterpartyAddress() {
    return this.item.counterparties[0].address
  }

  created() {
    this.setExpanded()
    this.txnHeadersCacheUpdated()
    this.receiptsCacheUpdated()
    this.updateGraphed()
    this.setCounterpartyInfo()
  }

  public showLifoButton() {
    return this.$store.state.lifoTransaction == null || this.lifoTarget || this.lifoAmount !== 0
  }

  private async graphTransaction() {
    let counterpartiesToGraph
    if (!this.expanded) {
      await this.$store.dispatch('getCounterpartiesDetails', { network: this.network, txn: this.item, expansion: false })
      counterpartiesToGraph = this.$store.state.counterparties
    } else {
      counterpartiesToGraph = this.$store.state.expandedCounterparties
    }

    this.$store.dispatch('graphTransaction', { txn: this.item, counterparties: counterpartiesToGraph, network: this.network })
  }

  private ungraphTransaction() {
    const ids = Array.from(this.graphedCounterparties)
    ids.push(nodeTypeToIdString({ type: 'transaction', id: this.item.id, network: this.network }))
    this.$store.dispatch('removeNodes', { ids })
  }

  private handleExpand() {
    this.expandClick(this.item)
  }

  private handleLIFOToggle() {
    if (this.lifoTarget) {
      this.$store.dispatch('hideDirectLIFO')
      this.$emit('hideLIFO')
    } else {
      this.$store.dispatch('showDirectLIFO', { network: this.network, transaction: this.item })
    }
  }

  private toggleIndirectLIFO() {
    if (this.$store.state.indirectLifoTransaction != null) {
      this.$store.dispatch('clearIndirectLIFO')
    } else {
      this.$store.dispatch('setIndirectLIFO', { transaction: this.item })
    }
  }

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  public formatValue(item: SimpleAggregatedTransaction | SimpleAggregatedEthereumTransaction): string {
    const value = this.decimalFormatter(item.amount)
    if (item.isOutput) {
      return `${value}`
    }
    return `-${value}`
  }

  public valueColor(output: boolean): string {
    if (output) {
      return 'value-output'
    }
    return 'value-input'
  }

  @Watch('$store.state.expandedTransaction')
  private setExpanded() {
    const expandedTransaction = <SimpleAggregatedTransaction | undefined>this.$store.state.expandedTransaction
    this.expanded = expandedTransaction != null && expandedTransaction.id === this.item.id
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (this.$store.state.txnHeadersCache.has(this.item.id)) {
      const h: TxHeader = this.$store.state.txnHeadersCache.get(this.item.id)
      this.heuristics.locktime = h.locktime
      this.heuristics.version = h.version
      this.heuristics.witness = h.segwit
      this.heuristics.rbf = h.rbf
      this.heuristics.haveHeader = true
    }
  }

  @Watch('$store.state.receiptsCacheCount')
  private receiptsCacheUpdated() {
    const receipt = (<LRUCache<string, FormattedLogEvent>>this.$store.state.receiptsCache).get(this.item.id)
    if (receipt != null) {
      const { addressInfo, event } = receipt
      const eventName = event != null ? event.name : ''
      let contract = ''
      if (addressInfo != null && addressInfo.isContract) {
        const { contractName, properties } = addressInfo as ContractInfo
        if (isTokenProps(properties)) {
          contract = properties.name
        } else { 
          contract = contractName ?? ''
        }
      }
      this.contract = {
        present: eventName !== '',
        name: contract,
        event: eventName
      }
    }
  }

  @Watch('$store.state.formattedLinks')
  @Watch('$store.state.formattedSummaryLinks')
  @Watch('$store.state.settings', { deep: true })
  private async updateGraphed() {
    const { type, id } = this.$store.state.target as Target
    const { counterparties, isOutput } = this.item

    // get clusters for counterparties (if in bitcoin)
    if (this.network === 'bitcoin') {
      await this.$store.dispatch('getTargetClusters', {
        network: this.network,
        addresses: Array.from(new Set(counterparties.map((c) => c.address)))
      })
    } else { // get the clusters but don't wait for them
      this.$store.dispatch('getTargetClusters', {
        network: this.network,
        addresses: Array.from(new Set(counterparties.map((c) => c.address)))
      })
    }

    // for IO of txn and each counterparty, check if graphed; if all graphed, transaction is graphed
    const { txnNodeSwitch } = this.$store.state.settings as SettingsCollection
    const links = txnNodeSwitch ?
      new Set(this.$store.state.formattedLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`)) :
      new Set(this.$store.state.formattedSummaryLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`))
    const entityNode = nodeTypeToIdString({ type, id, network: this.network })
    const opposingNode = txnNodeSwitch ?
      nodeTypeToIdString({ type: 'transaction', id: this.item.id, network: this.network }) :
      entityNode
    let allGraphed = true
    if (txnNodeSwitch) {
      allGraphed = isOutput ? links.has(`${opposingNode}${entityNode}`) : links.has(`${entityNode}${opposingNode}`)
    }
    const graphedCounterparties: string[] = []

    for (const { address, cluster, clusterAttribution } of counterparties as ClusteredCounterparty[]) {
      let cpNode
      if (this.network === 'bitcoin') {
        const clusterMetadata: ClusterMetadata | null = this.$store.state.shared.clusterAddressCache.get(address)
        if (clusterMetadata != null) {
          const { topAttribution, id: clusterId } = clusterMetadata
          if (topAttribution != null && topAttribution !== '') {
            cpNode = nodeTypeToIdString({ id: topAttribution, type: 'attribution', network: this.network })
          } else {
            cpNode = nodeTypeToIdString({ id: clusterId, type: 'cluster', network: this.network })
          }
        } else {
          cpNode = nodeTypeToIdString({ id: address, type: 'address', network: this.network })
        }
      } else {
        if (clusterAttribution != null && clusterAttribution !== '') {
          cpNode = nodeTypeToIdString({ id: clusterAttribution, type: 'attribution', network: this.network })
        } else if (cluster != null && cluster !== '') {
          cpNode = nodeTypeToIdString({ id: cluster, type: 'cluster', network: this.network })
        } else {
          cpNode = nodeTypeToIdString({ id: address, type: 'address', network: this.network })
        }
      }
      if (txnNodeSwitch || cpNode !== opposingNode) { // don't count change
        const counterpartyGraphed = isOutput ? links.has(`${cpNode}${opposingNode}`) : links.has(`${opposingNode}${cpNode}`)
        allGraphed = allGraphed && counterpartyGraphed
        if (counterpartyGraphed) {
          graphedCounterparties.push(cpNode)
        }
      }
    }

    this.ungraphed = !allGraphed
    this.graphedCounterparties = graphedCounterparties
  }

  @Watch('$store.state.clusterAddressesUpdated')
  private setCounterpartyInfo() {
    if (this.item.counterparties.length === 1) {
      const { address, cluster, clusterAttribution } = this.item.counterparties[0] as ClusteredCounterparty
      this.singleCounterpartyCluster = cluster
      this.singleCounterpartyHighestEntity = clusterAttribution ? clusterAttribution : cluster ? cluster : address
      if (cluster == null && this.network === 'bitcoin') { // check if address has cluster
        const cluster: ClusterMetadata | null = this.$store.state.shared.clusterAddressCache.get(address)
        if (cluster != null) {
          this.singleCounterpartyCluster = cluster.id
          this.singleCounterpartyHighestEntity = cluster.topAttribution ? cluster.topAttribution : cluster.id
        }
      }
    }
  }
}
