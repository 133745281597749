import { Commit, Dispatch } from 'vuex'
import { State } from './index'
import { ClusterMetadata, Api } from '@/utils/api'
import {
  CounterParty,
  ExtendedCoinbase,
  ExtendedInput,
  ExtendedTx,
  Output
} from '@/types/bitcoin'
import { FormattedTransaction } from '@/types/eth'
import { track } from '@/utils/tracking'

export interface ClusterTransaction {
  id: string
  isOutput: boolean
  index: number
  amount: number
  time: number
  cluster: string
}

export interface SimpleClusterTransaction extends ClusterTransaction {
  version: number
  locktime: number
  svb?: number
  fee?: number
  rbf?: boolean
  totalInputValue: number | string
  counterparties: CounterParty[]
}

export interface ClusterOutput extends Output, ClusterTransaction {}

export interface ClusterCoinbase extends ExtendedCoinbase, ClusterTransaction {}

export interface ClusterInput extends ExtendedInput, ClusterTransaction {}

export type IOItem = ClusterInput | ClusterCoinbase | ClusterOutput

export interface TxnMapBitcoin {
  [key: string]: ExtendedTx
}

export interface TxnMapEthereum {
  [key: string]: FormattedTransaction
}

export interface EntityResponse {
  simple: SimpleClusterTransaction[] | ClusterTransaction[]
  ios: IOItem[]
  full: TxnMapBitcoin | TxnMapEthereum
  total: number
}

export const clusterState = {
  addressCluster: <ClusterMetadata | undefined>undefined,
  addressClusterUpdate: <number>0,
  attributionClusters: <ClusterMetadata[] | undefined>undefined,
  addresses: <string[]>[]
}

export const clusterMutations = {
  SET_ADDRESS_CLUSTER(state: State, { addressCluster }: { addressCluster?: ClusterMetadata }) {
    state.addressCluster = addressCluster
    state.addressClusterUpdate++
  },
  SET_ATTRIBUTION_CLUSTERS(state: State, { clusters }: { clusters: ClusterMetadata[] }) {
    state.attributionClusters = clusters
  },
  SET_CLUSTER_ADDRESSES(state: State, { addresses }: { addresses: string[] }) {
    state.addresses = addresses
  }
}

export const clusterActions = (state: State, api: Api, dispatch: Dispatch) => ({
  clearClusters({ commit }: { commit: Commit }) {
    commit('SET_ADDRESS_CLUSTER', { addressCluster: undefined })
    commit('SET_ATTRIBUTION_CLUSTERS', { clusters: undefined })
  },
  async getClusterForAddress(
    { commit }: { commit: Commit },
    { network, address }: { network: string; address: string }
  ) {
    let addressCluster = state.shared.clusterAddressCache.get(address)
    if (addressCluster == null) {
      const response = await api.getAddressCluster({ network, id: address })
      if (response != null) {
        addressCluster = response[0]
        commit('CLUSTER_CACHE_ADD', { address, cluster: addressCluster })
      }
    }
    if (addressCluster != null) {
      track('getClusterForAddress', { network, id: address })
      commit('SET_ADDRESS_CLUSTER', { addressCluster })
    }
  },
  async getClusterForAttribution(
    { commit }: { commit: Commit },
    { network, attribution }: { network: string; attribution: string }
  ) {
    const networkClusters = state.shared.attributionClustersCache.get(attribution)
    if (networkClusters == null || networkClusters[network] == null) {
      const response = await api.attributionClusters({ network, id: attribution, page: 1, perPage: 1 })
      if (response != null) {
        track('getClusterForAttribution', { network, id: attribution })
        commit('ATTRIBUTION_CLUSTER_CACHE_ADD', { attribution, network, cluster: response[0] })
      }
    }
    const networkSizes = state.shared.attributionSizeCache.get(attribution)
    if (networkSizes == null || networkSizes[network] == null) {
      dispatch('getAddressesInAttributionCount', { network, id: attribution, stream: true })
    }
  },
  async getAddressesInCluster(
    { commit }: { commit: Commit },
    {
      network,
      id,
      page,
      perPage,
      isAttributed = false
    }: { network: string; id: string; page: number; perPage: number; isAttributed: boolean }
  ) {
    const type = isAttributed ? 'attribution' : 'cluster'
    const response = await api.clusterAddresses({ network, id, page, perPage, type })
    if (response != undefined) {
      commit('SET_CLUSTER_ADDRESSES', { addresses: response.addresses })
    }
  }
})
