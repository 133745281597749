import axios, { Axios } from 'axios'
import { serialize, deserialize } from 'bson'
import { BrowserCache } from './cache'
import { FormattedTransaction, AttributedTransaction, FormattedBlock, FormattedLogEvent, SimpleAggregatedEthereumTransaction } from '../types/eth'
import {
  AggregatedTransaction,
  BlockSummary,
  ElectrumPeer,
  ElectrumUser,
  ElectrumUserTxn,
  ElectrumWallet,
  ElectrumWalletSummary,
  ExtendedInput,
  ExtendedTx,
  SimpleAggregatedTransaction,
  StrippedBlock
} from '@/types/bitcoin'
import { Investigation } from '@/store/investigations/investigations'
import { IOItem } from '@/store/clusters'
import { machineId } from './tracking'
import { UserInfo } from '@/store/auth'
import { ExternalAPIRequest } from '@/store/accounts'
import { SortDirection } from '@/subcomponents/types/serverTable'
import { UrlWithParams, urls } from './urls'
import { ASN, Carrier, City, Country, IPMetadata, Region } from '@/types/ip'
import { GeoItem } from '@/store/p2p'
import { ChainReceipt, ChainTransaction } from '@/store/chain'

export interface ApiResponse<T = any> {
  success: boolean
  status?: number
  url?: string
  message?: string
  data?: T
  token?: string
  username?: string
}

export interface FailureResponse {
  status: number
  message: string
  original: any
}

export function failedResponse(response: unknown): response is FailureResponse {
  if (response == null) {
    return false
  }
  const { status, message, original } = response as FailureResponse
  if (status != null && message != null && original != null) {
    return true
  }
  return false
}

export interface Addresses {
  addresses: string[]
}

export interface ClusterMetadata {
  id: string
  size: number
  earliestTime: number
  topAttribution: string | null
  topCategory: string | null
  topCount: number | null
  runnerUpAttribution: string | null
  runnerUpCategory: string | null
  runnerUpCount: number | null
  attributedAddresses: number
  totalAttributions: number
}

export interface AddressClusterMap {
  [key: string]: ClusterMetadata
}

export interface Sort {
  field: string
  ascending: boolean
}

export interface InvestigationsResponse extends Omit<ApiResponse, 'success'> {
  buffer: Buffer
}

export interface BlockAndTransactionsResponse {
  block: FormattedBlock
  txes: AttributedTransaction[]
}

export interface RecentAddresses {
  [key: string]: number
}

export interface EtherscanAttr {
  name: string | null
}

export interface SupportedAsset {
  symbol: string
  address: string
  asset: string
  intervals: string[]
  prices: boolean
}

export interface SupportedAssetResponse {
  supported: boolean
  assets: {
    [key: string]: SupportedAsset
  }
}

export interface SupportedAccountResponse {
  supported: boolean
  accounts: string[]
}

// export interface AccountLedgerResponse extends Omit<ApiResponse, 'data' | 'token' | 'username'> {
//   data?: GraphData
// }

// attributions

export interface Category {
  name: string
  code: string
  dateAdded: string
  score: number
}

export interface AttributionMap {
  [key: string]: Attribution[]
}

export interface Attribution {
  id: string
  address: string
  networks: string[]
  attributions: string[]
  categories: string[]
  source: string
  user: string
  note?: string
  timeAdded: Date
}

// flows

interface FlowTypeRequest {
  trace?: AccountingMethod
}

export interface TxnTriplet {
  id: string
  index: number
  isOutput: boolean
}

export type FlowDirection = 'all' | SingleFlowDirection

export type SingleFlowDirection = 'source' | 'destination'

export interface TransactionFlow extends FlowInfo {
  source: AggregatedBitcoinTransaction
  destination: AggregatedBitcoinTransaction
}

export interface TripletCluster {
  index: number
  id: string
  isOutput: boolean
  address: string
  cluster: string
  amount: number
  symbol: string
  time: number
}

export interface FlowResponse {
  source: TxnTriplet
  destination: TxnTriplet
  amount: number
  shortestPath: number
  longestPath: number
  numPaths: number
}

export interface FlowInfo {
  amount: number
  shortestPath: number
}

export interface Source {
  sourceId: string
  sourceIsOutput: boolean
  sourceIndex: number
}

export interface Destination {
  destinationId: string
  destinationIsOutput: boolean
  destinationIndex: number
}

export interface FlowRequest extends NetworkRequest, FlowTypeRequest {
  source: Source
  destination: Destination
}

export interface FlowBase {
  source: TxnTripletTime
  destination: TxnTripletTime
  shortestPath: number
  longestPath: number
  numPaths: number
}

export interface TxnTripletTime extends TxnTriplet {
  time: number
}

export type GroupBy = 
    'source'
    | 'sourceAddress'
    | 'sourceAttribution'
    | 'sourceCategory'
    | 'destination'
    | 'destinationAddress'
    | 'destinationAttribution'
    | 'destinationCategory'

interface AggregateFlowsRequest extends NetworkRequest, PaginatedRequest {
  groupBy: GroupBy
  filters: Partial<AggregateFlowsRequestFilters>
  trace?: AccountingMethod
}

export interface AggregateFlowsResponse extends FlowBase {
  name?: string
  sourceAddress?: string
  sourceCluster?: string
  sourceAttribution?: string
  sourceCategory?: string
  destinationAddress?: string
  destinationCluster?: string
  destinationCategory?: string
  destinationAttribution?: string
  amount: number
  symbol: string
}

export type AggregateFlow = AggregateFlowsResponse

interface AttributedFlowsRequest extends 
  NetworkRequest,
  FlowTypeRequest,
  SortedRequest,
  Partial<AggregateFlowsRequestFilters> {}

export interface AttributedFlow {
  name?: string
  source: TxnTriplet
  destination: TxnTriplet
  amount: number
  symbol: string
  shortestPath: number
}

export interface NonRecursiveHierarchicalAggregateFlows {
  name: string
  children: Array<AggregateFlow | AttributedFlow>
}

export interface HierarchicalAggregateFlows {
  name: string
  children: Array<AggregateFlow | AttributedFlow> | Array<HierarchicalAggregateFlows>
}

export interface AttributedFlows {
  name: string
  children: Array<AttributedFlow>
}

export interface HierarchicalAggregateFlowsRequest extends NetworkRequest, FlowTypeRequest {
  side: SingleFlowDirection
  attribution?: string
  address?: string
  cluster?: string
  depth?: number
  maxDepth?: number
  filters?: Partial<AggregateFlowsRequestFilters>
  symbol?: string
}

export interface SubnetworkRequest extends NetworkRequest, FlowTypeRequest {
  source: TxnTriplet
  destination: TxnTriplet
}

export interface SunburstTree {
  name: string
  children?: SunburstTree[]
  value?: number
}

export interface AggregateFlowsRequestFilters {
  source: TxnTriplet
  sourceAddress: string
  sourceCluster: string
  sourceAttribution: string
  sourceCategory: string
  destination: TxnTriplet
  destinationAddress: string
  destinationCluster: string
  destinationAttribution: string
  destinationCategory: string
  symbol?: string
}

export interface RawFlowsRequest extends NetworkRequest, FlowTypeRequest, ExternalAttributionsRequest {
  destination: Destination
}

export interface RawFlowsResponse {
  flows: RawFlow[]
}

export interface RawFlow extends FlowInfo {
  source: TxnTriplet
  destination: TxnTriplet
  sourceAddress: string
  destinationAddress: string
  directAmount: number
  externalResult?: RawFlowExternalResult
}

export interface RawFlowExternalResult {
  sourceAddress: ExternalResult
  destinationAddress: ExternalResult
}

export interface FilteredFlowsRequest extends NetworkRequest, RawFlowsResponse, FlowTypeRequest, ExternalAttributionsRequest {
  addresses: string[]
}

// general

export interface ComparisonConstraints {
  eq?: number
  gt?: number
  lt?: number
  gte?: number
  lte?: number
}

export type AccountingMethod = 'balance' | 'LIFO'

export interface NetworkRequest {
  network: string
}

export interface ExternalAttributionsRequest {
  externalAttributions?: ExternalAPIRequest
}

export interface ExternalResult {
  success: boolean
  data?: string
  message?: string
  response?: any // TODO: type this
}

export interface ItemRequest extends NetworkRequest {
  id: string | number
}

export interface ItemsRequest extends NetworkRequest {
  ids: (string | number)[]
}

export interface PaginatedRequest {
  page: number
  perPage: number
}

export interface PaginatedItemRequest extends ItemRequest {
  page?: number
  perPage?: number
  type?: string
}

export interface ItemCountRequest extends ItemRequest, CountRequest {}

export interface SortedRequest extends PaginatedRequest {
  sorts?: SortMap
}

export interface TxnsBlockPageRequest extends ItemRequest {
  count: number
  page: number
}

export interface TxnsBlockPage {
  transactions: ExtendedTx[] // TODO: add other txn types when ready
  pages: {
    next: number
    pages: number
    total: number
  }
}

export interface FlowsRequest extends NetworkRequest, PaginatedRequest, FlowTypeRequest {
  transactions: TxnTriplet[]
  direction: FlowDirection
  hops?: ComparisonConstraints
}

export interface KeyDirectionSort {
  key: string
  direction: SortDirection
}

export interface BlocksRequest extends NetworkRequest {
  type: string
  from: number
  to: number
  count: number
  page: number
}

export interface FindInputRequest extends NetworkRequest {
  previousOutput: string
  vout: number
}

export interface StatsRequest extends NetworkRequest {
  asset: string
  interval: string
  prices: boolean
  start: number
  end: number
}

export interface NetworkStatsRequest extends NetworkRequest {
  start: number
  end: number
  interval: number
}

export interface EntityLedgerRequest extends ItemRequest, SortedRequest {
  txnData?: boolean
  ioData?: boolean
  fullTxns?: boolean
  amountConstraint?: ComparisonConstraints
  timeConstraint?: ComparisonConstraints
  aggregated?: boolean
  attribution?: boolean
}

export interface FilteredLedgerRequest extends Omit<EntityLedgerRequest, 'id' | 'attribution'> {}

export interface CountRequest {
  streamTo?: string
}

export interface EntityLedgerCountRequest extends ItemRequest, CountRequest {
  amountConstraint?: ComparisonConstraints
  timeConstraint?: ComparisonConstraints
  aggregated?: boolean
  attribution?: boolean
}

export interface FilteredLedgerCountRequest extends Omit<EntityLedgerCountRequest, 'id' | 'attribution'> {}

export interface SortMap {
  [key: string]: SortDirection
}

export interface AddressLedgerResponse {
  simple: (SimpleAggregatedTransaction | AggregatedTransaction | SimpleAggregatedEthereumTransaction)[] // need to type it this way for array functions to not complain about type
  ios: IOItem[]
  full: { [key: string]: ExtendedTx }
  total: number
}

export interface TransactionLedgerRequest extends ItemRequest, SortedRequest {
  simple?: boolean
  isOutput?: boolean
  index?: number
  aggregated?: boolean
  groupBy?: 'address' | 'cluster' | 'clusterAttribution'
  report?: boolean
  address?: string
  cluster?: string
  clusterAttribution?: string
  externalAttributions?: ExternalAPIRequest
}

interface TransactionLedgerBulkRequest extends NetworkRequest {
  triplets: TxnTriplet[]
  simple?: boolean
  fields?: string[]
  aggregated?: boolean
  groupBy?: 'address' | 'cluster' | 'clusterAttribution'
  externalAttributions?: ExternalAPIRequest
}

export type EntityType = 'address' | 'cluster' | 'attribution'

export interface SummaryRequest extends ItemRequest {
  idType: EntityType
}

export interface CounterpartiesRequest extends SummaryRequest, PaginatedRequest {
  isOutput?: boolean
}

interface LinksSummariesRequest extends NetworkRequest {
  linksData: BasicLinkData[]
}

export interface BasicLinkData {
  sender: string
  senderType: EntityType
  receiver: string
  receiverType: EntityType
}

interface LinkTransactionsRequest extends NetworkRequest, PaginatedRequest {
  linkData: BasicLinkData
  sort?: 'amount' | 'cryptotime'
}

export interface TransactionLedgerResponse {
  ledger: BitcoinTransaction[] | TransactionsResponse | ReportData[]
  total: number
}

export type TransactionsResponse = (SimplifiedBitcoinTransaction | AggregatedBitcoinTransaction)[]

export interface AggregatedBitcoinTransaction
  extends Omit<SimplifiedBitcoinTransaction, 'index' | 'dollarAmount' | 'address' | 'cluster'> {
  address?: string
  cluster?: string
  count?: number
  minIndex: number
  maxIndex?: number
  externalResult?: ExternalResult | BitcoinTransactionExternalResult
}

export interface BitcoinTransaction extends SimplifiedBitcoinTransaction {
  addressType: string
  ancestor: string
  isLastOutput: boolean
  transactionType: number
  locktime: number
  svb: number
  fee: number
  rbf: boolean
  opReturn: boolean
}

export interface SimplifiedBitcoinTransaction {
  id: string
  index: number
  isOutput: boolean
  address: string
  amount: number
  symbol: string
  dollarAmount: number
  time: number
  cluster: string | null
  clusterAttribution: string | null
  externalResult?: ExternalResult | BitcoinTransactionExternalResult
}

interface BitcoinTransactionExternalResult {
  address: ExternalResult
  cluster: ExternalResult
}

export interface ReportData extends BalancesSymbol {
  cluster: string
}

export interface BalancesSymbol {
  [addressBalanceSymbol: `addressBalance${string}`]: number
  [clusterBalanceSymbol: `clusterBalance${string}`]: number
  [attributionBalanceSymbol: `attributionBalance${string}`]: number
}

export interface CounterpartiesResponse {
  counterparties: AggregatedCounterparty[]
}

interface AggregatedCounterparty {
  counterparty: string
  amount: number
  isOutput: boolean
}

export interface SummaryResponse {
  summary: EntitySummary
}

export interface EntitySummary {
  lastTransactionTime: number
  firstTransactionTime: number
  symbols: EntitySymbolSummaryMap
}

interface EntitySymbolSummaryMap {
  [symbol: string]: EntitySymbolSummary
}

interface EntitySymbolSummary {
  currentCredits: number
  currentDeductions: number
  currentBalance: number
  currentTransactionCount: number
}

export interface LinkSummaryMap {
  [key: string]: {
    sender: string
    receiver: string
    senderType: EntityType
    receiverType: EntityType
    symbols: LinkSymbolSummary
  }
}

interface LinkSymbolSummary {
  [symbol: string]: LinkSummary
}

export interface LinkSummary {
  amount: number
  numberOfTxids: number
  minCryptotime: number
  maxCryptotime: number
}

export interface LinkTransaction {
  relationshipHash: string
  txid: string
  amount: number
  symbol: string
  cryptotime: number
}

export interface Paged {
  next: number | null
  pages: number
  total: number
}

export interface OSINTSearchRequest {
  entity: string
  engine: string
  start: number
  end: number
  delay: number
}

export interface OSINTRawRequest {
  uri: string
}

export interface OSINTSearchResult {
  host: string
  link: string
  title: string
  text: string
}

// elasticsearch
export interface SearchRequestBody {
  searchString: string
  mode: 'match' | 'wildcard'
  results?: number
}

export interface AutocompleteRequestBody {
  searchString: string
  mode: 'wildcard' | 'suggest'
  results?: number
  network?: string
}

// p2p networks

export interface Node {
  ip: string
  port: number
  protocol: number
  userAgent: string
  connectedSince: number
  services: number
  height: number
  hostname: string
  city: string
  country: string
  latitude: number
  logitude: number
  timezone: string
  asn: string
  org: string
  timestamp: number
  network: string
}

export interface ElectrumUsersPaged {
  users: ElectrumUser[]
  pages: Paged
}

export interface StringMap {
  [key: string]: string
}

export interface StringArrayMap {
  [key: string]: string[]
}

export interface Count {
  count: number
}

export interface IntervalCount extends Count {
  start: number
  end: number
}

export type Coordinates = [number, number]

function handleErrorResponse(response: ApiResponse): FailureResponse {
  const { status, url, ...original } = response
  if (url != null) {
    console.warn(`Error from ${url}`)
  }
  if (status != null && status === 404) {
    const error = {
      status,
      message: 'Something was not found.',
      original
    }
    console.warn(error)
    return error
  }
  if (status != null && status >= 500) {
    const error = {
      status,
      message: 'There was a server error.',
      original
    }
    console.warn(error)
    return error
  }
  const error = {
    status: status != null ? status : 500,
    message: 'Something went wrong.',
    original: original != null ? original : {}
  }
  console.warn(error)
  return error
}

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    credentials: 'include'
  },
  withCredentials: true
}

const multipartOptions = {
  headers: {
    credentials: 'include' // leave off Content-Type when using FormData
  },
  withCredentials: true
}

export class Api {
  private cache: BrowserCache = new BrowserCache()
  private apiClient: Axios
  private multipartApiClient: Axios

  constructor() {
    this.apiClient = axios.create(defaultOptions)
    this.multipartApiClient = axios.create(multipartOptions)
    this.initTracking()
  }

  async initTracking() {
    await machineId.init()
  }

  async getFingerprints() {
    return await machineId.getFingerprints()
  }

  async idsHeader() {
    const ids = await machineId.allIds()
    return { id: btoa(JSON.stringify(ids)) }
  }

  async getOptions() {
    const ids = await this.idsHeader()
    const { headers } = defaultOptions
    const headersWithIds = { ...headers, 'x-id': ids }
    defaultOptions.headers = headersWithIds
    return defaultOptions
  }

  // request methods

  private async get(url: string): Promise<ApiResponse> {
    const options = await this.getOptions()
    const response = await this.apiClient.get(url, options)
    return response.data as any as ApiResponse
  }

  private async post(url: string, params: any): Promise<ApiResponse> {
    const options = await this.getOptions()
    const response = await this.apiClient.post(url, params, options)
    return response.data as any as ApiResponse
  }

  private async postForBuffer(url: string, params: any): Promise<Buffer> {
    const response = await this.apiClient.post(url, params, { responseType: 'arraybuffer' })
    return Buffer.from(response.data, 'base64')
  }

  private async postMultipart(url: string, form: FormData): Promise<ApiResponse> {
    const response = await this.multipartApiClient.post(url, form)
    return response.data as any as ApiResponse
  }

  public async getExternal(url: string): Promise<any | undefined> {
    const response = await axios.get(url)
    if (response.status < 400) {
      return response.data
    }
    return undefined
  }

  public async getStaticInternal(url: string): Promise<any | undefined> {
    return this.get(url)
  }

  public async postExternal(url: string): Promise<any | undefined> {
    const response = await axios.post(url)
    if (response.status < 400) {
      return response.data
    }
    return undefined
  }

  // streaming events
  public startEvents(): EventSource {
    return new EventSource(<string>urls.events)
  }

  // auth

  public async signIn(username: string, password: string): Promise<{ success: boolean }> {
    const response = await this.post(<string>urls.signIn, { username, password })
    if ((response.success && response.token !== '') || response.token != null) {
      return { success: true }
    }
    return { success: false }
  }

  public async signOut() {
    const response = await this.get(<string>urls.signOut)
  }

  public async userSeen(username: string): Promise<ApiResponse> {
    return await this.post(<string>urls.userSeen, { username })
  }

  public async checkAuth(): Promise<ApiResponse> {
    try {
      return await this.get(<string>urls.checkAuth)
    } catch (e) {
      return { message: 'authorization expired', success: false } as ApiResponse
    }
  }

  public async securityAlert(username: string): Promise<ApiResponse> {
    return await this.post(<string>urls.securityAlert, { username })
  }

  public async requestPwReset({ username, email }: { username?: string; email?: string }): Promise<ApiResponse> {
    return await this.post(<string>urls.requestPwReset, { username, email })
  }

  public async resetPassword(password: string, code: string): Promise<ApiResponse> {
    return await this.post(<string>urls.resetPassword, { password, code })
  }

  public async getUser(username: string): Promise<ApiResponse<UserInfo>> {
    return await this.post(<string>urls.getUser, { username })
  }

  // admin

  public async signUp(
    username: string,
    password: string,
    email: string,
    code: string,
    active: boolean,
    sendEmail: boolean = true
  ): Promise<ApiResponse> {
    return await this.post(<string>urls.registerUser, { username, password, email, code, active, sendEmail })
  }

  public async getUsers(): Promise<ApiResponse> {
    return await this.get(<string>urls.getUsers)
  }

  public async getSignupCodes(): Promise<ApiResponse> {
    return await this.get(<string>urls.getCodes)
  }

  public async genSignupCodes(): Promise<ApiResponse> {
    return await this.post(<string>urls.genCodes, { count: 5 })
  }

  public async getApiKeys(): Promise<ApiResponse> {
    return await this.get(<string>urls.getApiKeys)
  }

  public async genApiKey(username: string): Promise<ApiResponse> {
    return this.post(<string>urls.genApiKey, { username, level: 1 }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async toggleApiKey(username: string, key: string): Promise<ApiResponse> {
    return this.post(<string>urls.toggleApiKey, { username, key }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async updateApiKeyScopes(key: string, scopes: string[]): Promise<ApiResponse> {
    return this.post(<string>urls.updateApiKeyScopes, { key, scopes }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async deleteApiKey(username: string, key: string): Promise<ApiResponse> {
    return this.post(<string>urls.deleteApiKey, { username, key }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async toggleUser(username: string): Promise<ApiResponse> {
    return this.post(<string>urls.toggleUser, { username }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async deleteUser(username: string): Promise<ApiResponse> {
    return this.post(<string>urls.deleteUser, { username }).catch((e) => {
      console.log(e.response.data)
      return e.response.data
    })
  }

  public async getActiveUsers(): Promise<ApiResponse> {
    return this.get(<string>urls.getActiveUsers)
  }

  public async getRateLimits(ids: string[]): Promise<ApiResponse> {
    return this.post(<string>urls.getRateLimits, { ids })
  }

  // clusters

  public async getAddressCluster({ network, id }: ItemRequest): Promise<ClusterMetadata[] | undefined> {
    const url = (<UrlWithParams>urls.addressCluster)({ network, id })
    const response = await this.get(url)
    if (response != null && response.success && response.data != null) {
      if (response.data.clusters) {
        const converted: ClusterMetadata[] = response.data.clusters
        return converted
      } else {
        const converted: ClusterMetadata = response.data
        return [converted]
      }
    }
    return undefined
  }

  public async getClustersForAddresses({ network, ids }: ItemsRequest): Promise<AddressClusterMap | undefined> {
    const url = (<UrlWithParams>urls.addressesClusters)({ network })
    const response = await this.post(url, { addresses: ids })
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async clusterAddresses({
    network,
    id,
    page,
    perPage,
    type
  }: PaginatedItemRequest): Promise<Addresses | undefined> {
    const base = type === 'cluster' ? urls.clusterAddresses : urls.attributedClusterAddresses
    const url = (<UrlWithParams>base)({ network, id, page, perPage })
    const response = await this.get(url)
    if (response.success) {
      return response.data as Addresses
    }
    return undefined
  }

  public async attributedClusterAddressesCount({
    network,
    id,
    streamTo
  }: ItemCountRequest) {
    const url = (<UrlWithParams>urls.attributedClusterAddressesCount)({ network, id })
    await this.post(url, { streamTo })
  }

  public async attributionClusters({
    network,
    id,
    page,
    perPage
  }: PaginatedItemRequest): Promise<ClusterMetadata[] | undefined> {
    const url = (<UrlWithParams>urls.attributionClusters)({ network, id, page, perPage })
    const response = await this.get(url)
    if (response.success) {
      return response.data.clusters as ClusterMetadata[]
    }
    return undefined
  }

  public async attributionClustersCount({
    network,
    id,
    streamTo
  }: ItemCountRequest) {
    const url = (<UrlWithParams>urls.attributionClustersCount)({ network, id })
    await this.post(url, { streamTo })
  }

  public async getUniqueAttributions(): Promise<ApiResponse> {
    return await this.get(<string>urls.uniqueAttributions)
  }

  // investigations

  public async addInvestigation(username: string, investigation: Investigation): Promise<ApiResponse> {
    const serializable = { ...investigation } as any
    if (investigation.state) {
      // const ab = await investigation.state.snapshot.arrayBuffer()
      // const buf = Buffer.from(ab)
      const buf = Buffer.from('')
      serializable.state = { ...investigation.state, snapshot: buf }
    }
    const serialized = serialize(serializable)
    const blob = new Blob([serialized], {
      type: 'application/bson'
    })
    const form = new FormData()
    form.append('username', username)
    form.append('investigation', blob)
    return await this.postMultipart(<string>urls.addInvestigation, form)
  }

  public async getInvestigations(username: string): Promise<ApiResponse> {
    const response = await this.postForBuffer(<string>urls.getInvestigations, { username })
    const deserialized = deserialize(response) as ApiResponse
    // convert string date to epoch - could move this to API
    if (deserialized.data != null && Array.isArray(deserialized.data)) {
      deserialized.data = (<Investigation[]>deserialized.data).map(({ state, ...remaining }) => {
        if (state != null) {
          let { ledger, ...remainingState } = state
          ledger =
            ledger != null
              ? ledger.map(({ timestamp, ...remainingLedger }) => ({
                  ...remainingLedger,
                  timestamp: new Date(timestamp).getTime()
                }))
              : undefined
          state = { ...remainingState, ledger }
        }
        return { ...remaining, state }
      })
    }
    return deserialized
  }

  public async updateInvestigation(username: string, investigation: Investigation): Promise<ApiResponse> {
    // const ab = await investigation.state.snapshot.arrayBuffer()
    // const buf = Buffer.from(ab)
    const buf = Buffer.from('')
    const serializable = { ...investigation, state: { ...investigation.state, snapshot: buf } }
    const serialized = serialize(serializable)
    const blob = new Blob([serialized], {
      type: 'application/bson'
    })
    const form = new FormData()
    form.append('username', username)
    form.append('investigation', blob)
    return await this.postMultipart(<string>urls.updateInvestigation, form)
  }

  public async deleteInvestigation(_id: any): Promise<ApiResponse> {
    return await this.post(<string>urls.deleteInvestigation, { _id })
  }

  // search & autocomplete

  public async search<T>({ dataset, payload }: { dataset: string; payload: SearchRequestBody }): Promise<ApiResponse> {
    const url = (<UrlWithParams>urls.search)({ dataset })
    return await this.post(url, payload)
  }

  public async autocomplete({
    dataset,
    payload
  }: {
    dataset: string
    payload: AutocompleteRequestBody
  }): Promise<ApiResponse> {
    const url = (<UrlWithParams>urls.autocomplete)({ dataset })
    return await this.post(url, payload)
  }

  // stats

  public async getSupportedStats(network: string): Promise<SupportedAssetResponse | undefined> {
    const url = (<UrlWithParams>urls.supported)(network)
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getStats({ network, asset, interval, prices, start, end }: StatsRequest): Promise<any> {
    const url = (<UrlWithParams>urls.stats)({ network, asset, interval, prices, start, end })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // accounts

  public async getSupportedAccounts(network: string): Promise<SupportedAccountResponse | undefined> {
    const url = (<UrlWithParams>urls.supportedAccounts)(network)
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // attributions

  public async getCategories(): Promise<Category[]> {
    const response = await this.get(<string>urls.categories)
    if (response.success && response.data != null) {
      return response.data
    }
    return []
  }

  public async getAttributions(addresses: string[]): Promise<AttributionMap | undefined> {
    const response = await this.post(<string>urls.attributions, { addresses })
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // blocks

  public async getLatestBlock({ network }: { network: string }): Promise<FormattedBlock | StrippedBlock | undefined> {
    const url = (<UrlWithParams>urls.latestBlock)(network)
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getBlock({ network, id }: ItemRequest): Promise<FormattedBlock | StrippedBlock | undefined> {
    const url = (<UrlWithParams>urls.block)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getBlocks({
    network,
    type,
    from,
    to,
    count,
    page
  }: BlocksRequest): Promise<FormattedBlock[] | StrippedBlock[] | undefined> {
    const url = (<UrlWithParams>urls.blocks)({ network, type, from, to, count, page })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getBlockSummary({ network, id }: ItemRequest): Promise<FormattedBlock[] | BlockSummary[] | undefined> {
    const url = (<UrlWithParams>urls.blockSummary)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getBlockSummaries({
    network,
    type,
    from,
    to,
    count,
    page
  }: BlocksRequest): Promise<FormattedBlock[] | BlockSummary[] | undefined> {
    const url = (<UrlWithParams>urls.blockSummaries)({ network, type, from, to, count, page })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // transactions

  public async getTransaction({ network, id }: ItemRequest): Promise<ChainTransaction | undefined> {
    const url = (<UrlWithParams>urls.transaction)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getTransactions({ network, id }: ItemRequest): Promise<ChainTransaction[] | string[] | undefined> {
    const url = (<UrlWithParams>urls.transactions)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getTransactionsList<R>({
    network,
    list,
    headerOnly = false
  }: {
    network: string
    list: string[]
    headerOnly?: boolean
  }): Promise<R | undefined> {
    const url = (<UrlWithParams>urls.transactionsList)(network)
    const response = await this.post(url, { list, headerOnly })
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getTransactionsBlockPage(pageReq: TxnsBlockPageRequest): Promise<TxnsBlockPage | undefined> {
    const url = (<UrlWithParams>urls.transactionsBlockPage)(pageReq)
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getHeuristicsList({ network, list }: { network: string; list: TxnTriplet[] }) {
    const url = (<UrlWithParams>urls.heuristicsList)({ network })
    const response = await this.post(url, { transactions: list })
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // transaction receipts (evm only)

  public async getReceipts({ network, id }: ItemRequest): Promise<ChainReceipt[] | undefined> {
    const url = (<UrlWithParams>urls.receipts)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  public async getReceiptsForBlock({ network, id }: ItemRequest): Promise<ChainReceipt[] | undefined> {
    const url = (<UrlWithParams>urls.receiptsBlock)({ network, id })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // find input (utxo only)

  public async findInput({ network, previousOutput, vout }: FindInputRequest): Promise<ExtendedInput | undefined> {
    const url = (<UrlWithParams>urls.findInput)({ network, previousOutput, vout })
    const response = await this.get(url)
    if (response.success && response.data != null) {
      return response.data
    }
    return undefined
  }

  // ledgers

  public async getAddressLedger({
    network,
    id,
    page,
    perPage,
    sorts,
    txnData,
    fullTxns,
    amountConstraint,
    timeConstraint,
    aggregated
  }: EntityLedgerRequest): Promise<AddressLedgerResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.addressLedger)({ network, id })
    const response = await this.post(url, {
      page,
      perPage,
      sorts,
      txnData,
      fullTxns,
      amountConstraint,
      timeConstraint,
      aggregated
    })
    const { success, data } = response
    if (success && data != null) {
      data.simple.map((d: any) => {
        if (d.total == null && d.amount != null) {
          d.total = d.amount
        }
      })
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getAddressLedgerCount({
    network,
    id,
    amountConstraint,
    timeConstraint,
    aggregated,
    streamTo
  }: EntityLedgerCountRequest) {
    const url = (<UrlWithParams>urls.addressLedgerCount)({ network, id })
    await this.post(url, { amountConstraint, timeConstraint, aggregated, streamTo })
  }

  public async getClusterLedger({
    network,
    id,
    page,
    perPage,
    sorts,
    txnData,
    ioData,
    fullTxns,
    amountConstraint,
    timeConstraint,
    aggregated,
    attribution
  }: EntityLedgerRequest): Promise<AddressLedgerResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.clusterLedger)({ network, id })
    const response = await this.post(url, {
      page,
      perPage,
      sorts,
      txnData,
      ioData,
      fullTxns,
      amountConstraint,
      timeConstraint,
      aggregated,
      attribution
    })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getClusterLedgerCount({
    network,
    id,
    amountConstraint,
    timeConstraint,
    aggregated,
    attribution,
    streamTo
  }: EntityLedgerCountRequest) {
    const url = (<UrlWithParams>urls.clusterLedgerCount)({ network, id })
    await this.post(url, {
      amountConstraint,
      timeConstraint,
      aggregated,
      attribution,
      streamTo
    })
  }

  public async getFilteredLedger({
    network,
    page,
    perPage,
    sorts,
    txnData,
    fullTxns,
    amountConstraint,
    timeConstraint,
    aggregated
  }: FilteredLedgerRequest): Promise<AddressLedgerResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.filteredLedger)({ network })
    const response = await this.post(url, {
      page,
      perPage,
      sorts,
      txnData,
      fullTxns,
      amountConstraint,
      timeConstraint,
      aggregated
    })
    const { success, data } = response
    if (success && data != null) {
      data.simple.map((d: any) => {
        if (d.total == null && d.amount != null) {
          d.total = d.amount
        }
      })
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getFilteredLedgerCount({
    network,
    amountConstraint,
    timeConstraint,
    aggregated,
    streamTo
  }: FilteredLedgerCountRequest) {
    const url = (<UrlWithParams>urls.filteredLedgerCount)({ network })
    await this.post(url, { amountConstraint, timeConstraint, aggregated, streamTo })
  }

  public async getTransactionLedger({
    network,
    id,
    page,
    perPage,
    sorts,
    simple,
    index,
    isOutput,
    aggregated,
    groupBy,
    report,
    address,
    cluster,
    clusterAttribution,
    externalAttributions
  }: TransactionLedgerRequest): Promise<TransactionLedgerResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.transactionLedger)({ network, id })
    if (externalAttributions != null) {
      if (groupBy !== 'clusterAttribution') {
        externalAttributions = { ...externalAttributions } // copy the data to avoid changing the store object
        externalAttributions.url += `/{${groupBy}}`
      }
    }
    const response = await this.post(url, {
      page,
      perPage,
      sorts,
      simple,
      isOutput,
      index,
      aggregated,
      groupBy,
      report,
      address,
      cluster,
      clusterAttribution,
      externalAttributions
    })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getBulkTransactionLedger({
    network,
    triplets,
    simple,
    fields,
    aggregated,
    groupBy,
    externalAttributions
  }: TransactionLedgerBulkRequest): Promise<TransactionsResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.bulkTransactionsLedger)({ network })
    if (externalAttributions != null) {
      externalAttributions = { ...externalAttributions } // copy the data to avoid changing the store object
      if (aggregated) {
        if (groupBy === 'address') {
          externalAttributions.url += '/{address}{cluster}'
          externalAttributions.splitMultiple = true
        } else if (groupBy === 'cluster') {
          externalAttributions.url += '/{cluster}'
        }
      } else {
        externalAttributions.url += '/{address}{cluster}'
        externalAttributions.splitMultiple = true
      }
    }
    const response = await this.post(url, {
      triplets,
      simple,
      fields,
      aggregated,
      groupBy,
      externalAttributions
    })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  // other aggregate txn data

  public async getCounterparties({
    network,
    id,
    idType,
    page,
    perPage,
    isOutput
  }: CounterpartiesRequest): Promise<CounterpartiesResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.counterparties)({ network, id })
    const response = await this.post(url, { idType, page, perPage, isOutput })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getSummary({
    network,
    id,
    idType
  }: SummaryRequest): Promise<SummaryResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.summary)({ network, id })
    const response = await this.post(url, { idType })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getLinksSummaries({
    network,
    linksData
  }: LinksSummariesRequest): Promise<LinkSummaryMap | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.linksSummaries)({ network })
    const response = await this.post(url, { linksData })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getLinkTransactions({
    network,
    linkData,
    page,
    perPage,
    sort
  }: LinkTransactionsRequest): Promise<LinkTransaction[] | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.linkTransactions)({ network })
    const response = await this.post(url, { linkData, page, perPage, sort })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  // flows

  public async flows({
    network,
    transactions,
    direction,
    trace,
    hops,
    page,
    perPage
  }: FlowsRequest): Promise<TransactionFlow[] | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.flows)({ network })
    const response = await this.post(url, { transactions, direction, trace, hops, page, perPage })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async flow({
    network,
    source,
    destination,
    trace
  }: FlowRequest): Promise<FlowResponse | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.flow)({ network })
    const response = await this.post(url, { source, destination, trace })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async subnetwork({
    network,
    source,
    destination,
    trace
  }: SubnetworkRequest): Promise<TransactionFlow[] | FailureResponse | undefined> {
    const url = (<UrlWithParams>urls.subnetwork)({ network })
    const response = await this.post(url, { source, destination, trace })
    const { success, data } = response
    if (success && data != null) {
      return data
    }
    if (!success) {
      return handleErrorResponse(response)
    }
    return undefined
  }

  public async getAggregateFlows({
    network,
    groupBy,
    filters,
    page,
    perPage,
    trace,
  }: AggregateFlowsRequest): Promise<AggregateFlowsResponse[] | FailureResponse> {
    const url = (<UrlWithParams>urls.aggregateFlows)({ network })
    const response = await this.post(url, {
      groupBy,
      filters,
      page,
      perPage,
      trace
    })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getAttributedFlows({
    network,
    ...request
  }: AttributedFlowsRequest): Promise<AttributedFlow[] | FailureResponse> {
    const url = (<UrlWithParams>urls.attributedFlows)({ network })
    const response = await this.post(url, request)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getAggregateHierarchy({
    network,
    side,
    attribution,
    address,
    cluster,
    depth = 1,
    maxDepth = 2,
    filters,
    trace,
    symbol
  }: HierarchicalAggregateFlowsRequest): Promise<HierarchicalAggregateFlows | FailureResponse> {
    const url = (<UrlWithParams>urls.aggregateFlowHierarchy)({ network })
    const response = await this.post(url, {
      side,
      attribution,
      address,
      cluster,
      depth,
      maxDepth,
      depthFilters: filters,
      removeSelf: true,
      trace,
      symbol
    })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getRawFlows({
    network,
    destination,
    trace,
    externalAttributions
  }: RawFlowsRequest): Promise<RawFlowsResponse | FailureResponse> {
    const url = (<UrlWithParams>urls.rawFlows)({ network })
    if (externalAttributions != null) {
      externalAttributions = { ...externalAttributions } // copy the data to avoid changing the store object
      externalAttributions.url += '/{sourceAddress}{destinationAddress}'
      externalAttributions.splitMultiple = true
    }
    const response = await this.post(url, { destination, trace, externalAttributions })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getFilteredFlows({
    network,
    flows,
    addresses,
    trace,
    externalAttributions
  }: FilteredFlowsRequest): Promise<RawFlowsResponse | FailureResponse> {
    const url = (<UrlWithParams>urls.filteredFlows)({ network })
    if (externalAttributions != null) {
      externalAttributions = { ...externalAttributions } // copy the data to avoid changing the store object
      externalAttributions.url += '/{sourceAddress}{destinationAddress}'
      externalAttributions.splitMultiple = true
    }
    const response = await this.post(url, { flows, addresses, trace, externalAttributions })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  // p2p network

  public async getAllNodes({ network }: { network: string }): Promise<Node[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getAllNodes)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return (data as Node[]).map((node) => ({
        ...node,
        network
      }))
    }
    return handleErrorResponse(response)
  }

  public async getTxnsByIp({ network, ip }: { network: string; ip: string }): Promise<Node[] | FailureResponse> {
    const url = (<UrlWithParams>urls.firstSeenByIp)({ network, ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumPeers({ network }: { network: string }): Promise<ElectrumPeer[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumPeers)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumPeer({
    network,
    ip
  }: {
    network: string
    ip: string
  }): Promise<ElectrumPeer | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumPeer)({ network, ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumUsers({ network }: { network: string }): Promise<ElectrumUsersPaged | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumUsers)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumUsersPage({
    network,
    page,
    limit
  }: {
    network: string
    page: number
    limit: number
  }): Promise<ElectrumUsersPaged | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumUsersPage)({ network, page, limit })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumUser({
    network,
    ip
  }: {
    network: string
    ip: string
  }): Promise<ElectrumUser | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumUser)({ network, ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumTransactions({ network }: { network: string }): Promise<StringArrayMap | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumTransactions)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumTransactionsForIp({
    network,
    ip
  }: {
    network: string
    ip: string
  }): Promise<ElectrumUserTxn[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumTransactionsForIp)({ network, ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumUsersWithTransactions({
    network
  }: {
    network: string
  }): Promise<ElectrumUser[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumUsersWithTransactions)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  // electrum wallets

  public async getElectrumWallets({
    network
  }: {
    network: string
  }): Promise<ElectrumWalletSummary[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWallets)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWatchlists({
    network
  }: {
    network: string
  }): Promise<ElectrumWalletSummary[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWatchlists)({ network })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWalletAddresses({
    network,
    id
  }: {
    network: string
    id: string
  }): Promise<ElectrumWallet | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWalletAddresses)({ network, id })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWalletsByIP({
    network,
    ip
  }: {
    network: string
    ip: string
  }): Promise<ElectrumWalletSummary[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWalletsByIP)({ network, ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWalletByAddress({
    network,
    address
  }: {
    network: string
    address: string
  }): Promise<ElectrumWalletSummary[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWalletByAddress)({ network, address })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  // electrum stats

  public async getElectrumPeerCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumPeerCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumUserCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumUserCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumTransactionCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumTransactionCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWalletCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWalletCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumWatchlistCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumWatchlistCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getElectrumAddressCounts({
    network,
    start,
    end,
    interval
  }: NetworkStatsRequest): Promise<IntervalCount[] | FailureResponse> {
    const url = (<UrlWithParams>urls.getElectrumAddressCounts)({ network, start, end, interval })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  // IPs

  public async getGeoList(): Promise<GeoItem[] | FailureResponse> {
    const url = urls.getGeoList as string
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getIP({ ip }: { ip: string }): Promise<IPMetadata | FailureResponse> {
    const url = (<UrlWithParams>urls.getIP)({ ip })
    const response = await this.get(url)
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getIPs({ ips }: { ips: string[] }): Promise<IPMetadata[] | FailureResponse> {
    const url = urls.getIPs as string
    const response = await this.post(url, { ips })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getIPsNearGeo({
    coordinates,
    minDistance,
    maxDistance,
    lastSeen
  }: {
    coordinates: Coordinates
    minDistance?: number
    maxDistance: number
    lastSeen?: number
  }): Promise<IPMetadata[] | FailureResponse> {
    const url = urls.getIPsNearGeo as string
    const response = await this.post(url, { coordinates, minDistance, maxDistance, lastSeen })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getIPsWithinCountry({
    code,
    lastSeen
  }: {
    code: string
    lastSeen?: number
  }): Promise<IPMetadata[] | FailureResponse> {
    const url = urls.getIPsWithinCountry as string
    const response = await this.post(url, { code, lastSeen })
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getASNs(): Promise<ASN[] | FailureResponse> {
    const url = urls.getASNs as string
    const response = await this.post(url, {})
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getCarriers(): Promise<string[] | FailureResponse> {
    const url = urls.getCarriers as string
    const response = await this.post(url, {})
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getCountries(): Promise<Country[] | FailureResponse> {
    const url = urls.getCountries as string
    const response = await this.post(url, {})
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getRegions(): Promise<Region[] | FailureResponse> {
    const url = urls.getRegions as string
    const response = await this.post(url, {})
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  public async getCities(): Promise<City[] | FailureResponse> {
    const uri = urls.getCities as string
    const response = await this.post(uri, {})
    const { success, data } = response
    if (success) {
      return data
    }
    return handleErrorResponse(response)
  }

  // OSINT

  public async osintSearch({
    entity,
    engine,
    start,
    end,
    delay
  }: OSINTSearchRequest): Promise<OSINTSearchResult[] | undefined> {
    const url = (<UrlWithParams>urls.osintSearch)({ entity, engine, start, end, delay })
    const response = await this.postExternal(url)
    return response
  }

  public async osintRaw({ uri }: OSINTRawRequest): Promise<string | undefined> {
    const url = (<UrlWithParams>urls.osintRaw)({ uri })
    const response = await this.postExternal(url)
    return response
  }
}
