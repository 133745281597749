import { Store } from "vuex";
import { Api } from "./api";
import { formatEthAddress, isBitcoinClassification, isEthereumClassification, isIPClassification, isTronClassification, NetworkClassifications, validateBitcoinAddress, validateEthAddress, validateTronAddress } from "./validate";
import { NodeType } from "./viz";

export interface BlockId {
  id: string
  type: 'block'
  network: string
}

export interface IPId {
  id: string
  type: 'ip'
  network: ''
}

export async function setSearchTypes(
  id: string,
  input: NetworkClassifications,
  types: (NodeType | BlockId | IPId)[],
  typesData: string[],
  api: Api,
  explorer: boolean,
  store: Store<any>
) {
    if (isBitcoinClassification(input)) {
      const network = 'bitcoin'
      if (input.isBitcoinTxid) {
        types.push({
          id,
          type: 'transaction',
          network
        })
        typesData.push('')
      } else if (
        input.isBitcoinAddress ||
        input.isBitcoinBech32Address ||
        input.isBitcoinBech32mAddress ||
        input.isBitcoinLegacyAddress
      ) {
        if (!validateBitcoinAddress(id)) {
          // don't show any error for now
          // store.dispatch('updateSnackbar', {
          //   show: true,
          //   text: `Invalid Bitcoin address, check capitalization and try again`
          // })
        } else {
          ({ types, typesData } = await attachAttributionAndCluster(id, network, types, typesData, api))
        }
      } else if (input.isBitcoinBlock && explorer) {
        types.push({
          id,
          type: 'block',
          network
        })
        typesData.push('')
      }
    }
    if (isEthereumClassification(input)) {
      const network = 'ethereum'
      if (input.isEthBlockOrHash) {
        types.push({
          id,
          type: 'transaction',
          network
        })
        typesData.push('')

        if (explorer) {
          types.push({
            id,
            type: 'block',
            network
          })
          typesData.push('')
        }
      } else if (input.isEthAddress) {
        if (!validateEthAddress(id)) {
          id = formatEthAddress(id)
        }
        ({ types, typesData } = await attachAttributionAndCluster(id, network, types, typesData, api))
      }
    }
    if (isTronClassification(input)) {
      const network = 'tron'
      if (input.isTronTxid) {
        types.push({
          id,
          type: 'transaction',
          network
        })
        typesData.push('')
      } else if (input.isTronAddress) {
        if (validateTronAddress(id)) {
          ({ types, typesData } = await attachAttributionAndCluster(id, network, types, typesData, api))
        } // no way to reformat the address if it's not valid
      } else if (input.isTronBlock && explorer) {
        types.push({
          id,
          type: 'block',
          network
        })
        typesData.push('')
      }
    }
    if (isIPClassification(input) && explorer) {
      types.push({
        id,
        type: 'ip',
        network: ''
      })
      typesData.push('')
    }

    return { types, typesData }
  }

  async function attachAttributionAndCluster(
    id: string,
    network: string,
    types: (NodeType | BlockId | IPId)[],
    typesData: string[],
    api: Api
  ) {
    types.push({
      id,
      type: 'address',
      network
    })
    const attributionResponse = await api.getAttributions([id])
    if (attributionResponse != null && Object.keys(attributionResponse).length > 0) {
      typesData.push(attributionResponse[id][0].attributions[0])
    } else {
      typesData.push('')
    }
    const response = await api.getAddressCluster({ network: network, id })
    if (response && response.length) {
      const { id: clusterId, topAttribution, topCategory } = response[0]
      if (topAttribution && topCategory) {
        types.push({
          id: topAttribution,
          type: 'attribution',
          network
        })
        typesData.push(topCategory)
      } else {
        types.push({
          id: clusterId,
          type: 'cluster',
          network
        })
        typesData.push('')
      }
    }

    return { types, typesData }
  }