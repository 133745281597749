import { Commit } from 'vuex'
import { State } from './index'
import { Api } from '@/utils/api'
import { track } from '@/utils/tracking'

export interface ErrorCauseKeys {
  type: string
  reason?: string
  stack_trace?: string
  caused_by?: ErrorCause
  root_cause?: ErrorCause[]
  suppressed?: ErrorCause[]
}

export type ErrorCause = ErrorCauseKeys & {
  [property: string]: any
}

export interface ElasticSearchError {
  error: ErrorCause
  status: number
}

export function isElasticSearchError(data: unknown): data is ElasticSearchError {
  if (data == null) {
    return false
  }
  return (data as ElasticSearchError).error != null
}

export interface FormattedAutocompleteResponse {
  results: string[]
  count: number
}

export interface SearchInput {
  value: string
}

export type Dataset = 'attributions' | 'addresses' | 'transactions'

export interface ElasticSearchInput extends SearchInput {
  dataset: Dataset | 'attributions/names' | 'all'
  mode: 'match' | 'wildcard' | 'suggest'
  resultsCount?: number
  network?: string
}

export interface DatasetResult {
  dataset: Dataset
  result: string
  network?: string
}

export interface FormattedSearchItems {
  [key: string]: string | number | boolean
}

export interface FormattedSearchResponse {
  results: FormattedSearchItems[]
  count: number
}

export const searchState = {
  autocompleteResults: <Array<DatasetResult>>[],
  autocompleteSearches: <number>0,
  elasticsearchResults: <Array<FormattedSearchItems>>[]
}

export const searchMutations = {
  // search
  SET_AUTOCOMPLETE_RESULTS(state: State, { results }: { results: DatasetResult[] }) {
    state.autocompleteResults = results
    state.autocompleteSearches += 1
  },
  SET_ELASTICSEARCH_RESULTS(state: State, { results }: { results: FormattedSearchItems[] }) {
    state.elasticsearchResults = results
  }
}

export const searchActions = (state: State, api: Api) => ({
  // search
  async executeAutocomplete({ commit }: { commit: Commit }, { dataset, value, mode = 'wildcard', network }: ElasticSearchInput) {
    if (typeof value === 'string' && value.length > 0 && mode !== 'match') {
      track('search', { value })
      const results: DatasetResult[] = []
      if (dataset !== 'all') {
        const autocompleteResponse = await api.autocomplete({
          dataset,
          payload: {
            searchString: value,
            mode,
            network
          },
        })
        if (isElasticSearchError(autocompleteResponse)) {
          console.warn(autocompleteResponse)
          return
        }
        const { results: rawResults } = (autocompleteResponse.data as FormattedAutocompleteResponse)
        let formattedDataset: Dataset
        switch (dataset) {
          case 'attributions':
            formattedDataset = 'addresses'
            break
          case 'attributions/names':
            formattedDataset = 'attributions'
            break
          default:
            formattedDataset = dataset
        }
        for (const result of rawResults) {
          results.push({
            dataset: formattedDataset,
            result,
            network
          })
        }
      } else {
        const datasets: (Dataset | 'attributions/names')[] = ['attributions/names', 'attributions']
        const networkDatasets: {
          dataset: (Dataset | 'attributions/names'),
          network?: string
        }[] = [
          {
            dataset: 'addresses',
            network: 'BTC'
          },
          {
            dataset: 'transactions',
            network: 'BTC'
          }
        ]
        // use unshift to add attribution datasets to get them at the front of the result list
        if (network === 'all') {
          networkDatasets.unshift(...datasets.map(d => ({ dataset: d, network: 'TRX' })))
          networkDatasets.unshift(...datasets.map(d => ({ dataset: d, network: 'ETH' })))
          networkDatasets.unshift(...datasets.map(d => ({ dataset: d, network: 'BTC' })))
        } else {
          networkDatasets.unshift(...datasets.map(d => ({ dataset: d, network })))
        }
        await Promise.all(
          networkDatasets.map(networkDataset => api.autocomplete({
            dataset: networkDataset.dataset,
            payload: {
              searchString: value,
              mode,
              network: networkDataset.network,
              results: networkDataset.dataset === 'attributions/names' ? 100 : 10
            }
          }))
        ).then(autocompleteResponses => {
          for (const [index, autocompleteResponse] of autocompleteResponses.entries()) {
            if (isElasticSearchError(autocompleteResponse)) {
              console.warn(autocompleteResponse)
            } else {
              const { results: rawResults } = (autocompleteResponse.data as FormattedAutocompleteResponse)
              let formattedDataset: Dataset
              const { dataset: currentDataset, network: currentNetwork } = networkDatasets[index]
              switch (currentDataset) {
                case 'attributions':
                  formattedDataset = 'addresses'
                  break
                case 'attributions/names':
                  formattedDataset = 'attributions'
                  break
                default:
                  formattedDataset = currentDataset
              }
              for (const result of rawResults) {
                results.push({
                  dataset: formattedDataset,
                  result,
                  network: currentNetwork
                })
              }
            }
          }
        })
      }
      commit('SET_AUTOCOMPLETE_RESULTS', { results })
    } else if (mode === 'match') {
      console.warn('mode cannot be match for autocomplete')
    } else {
      console.warn('autocomplete called with empty string')
    }
  },
  clearAutocomplete({ commit }: { commit: Commit }) {
    commit('SET_AUTOCOMPLETE_RESULTS', { results: [] })
  },
  async executeElasticsearch({ commit }: { commit: Commit }, { dataset, value, mode = 'wildcard' }: ElasticSearchInput) {
    if (mode !== 'suggest') {
      const autocompleteResponse = await api.search({
        dataset,
        payload: {
          searchString: value,
          mode
        }
      })
      if (isElasticSearchError(autocompleteResponse)) {
        console.warn(autocompleteResponse)
        return
      }
      const { results } = autocompleteResponse.data as FormattedAutocompleteResponse
      commit('SET_ELASTICSEARCH_RESULTS', { results })
    } else {
      console.warn('mode cannot be suggest for search')
    }
  }
})
