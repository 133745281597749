// transactions

import { divideBigNum } from "@/utils/bignum"
import { prettyRoundedNumber } from "@/utils/general"
import { DecodedFunctionCall } from "evmdecoder/lib/abi/decode"

export interface Ret {
  contractRet?: string
  [key: string]: any
}

export interface TrxTransfer {
  amount: number
  owner_address: string
  to_address: string
}

export function isTrxTransfer(value: any): value is TrxTransfer {
  if (
    value == null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value !== 'object' ||
    Array.isArray(value) ||
    (value as any).asset_name != null
  ) {
    return false
  }
  return (
    (value as TrxTransfer).amount !== null &&
    (value as TrxTransfer).owner_address !== null &&
    (value as TrxTransfer).to_address !== null
  )
}

export interface Trc10Transfer {
  amount: number
  asset_name: string
  owner_address: string
  to_address: string
}

export function isTrc10Transfer(value: any): value is Trc10Transfer {
  if (
    value == null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }
  return (
    (value as Trc10Transfer).amount !== null &&
    (value as Trc10Transfer).owner_address !== null &&
    (value as Trc10Transfer).to_address !== null &&
    (value as Trc10Transfer).asset_name !== null
  )
}

export interface TokenIssue {
  start_time: number
  trx_num: number
  total_supply: number
  precision: number
  num: number
  name: string
  end_time: number
  description: string
  owner_address: string
  abbr: string
  url: string
}

export function isTokenIssue(value: any): value is TokenIssue {
  if (
    value == null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }
  return (
    (value as TokenIssue).total_supply !== null &&
    (value as TokenIssue).owner_address !== null &&
    (value as TokenIssue).name !== null &&
    (value as TokenIssue).abbr !== null
  )
}

export interface ExchangeValue {
  exchange_id: number
  token_id: string
  expected: number
  owner_address: string
  quant: number
}

export function isExchangeValue(value: any): value is ExchangeValue {
  if (
    value == null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }
  return (value as ExchangeValue).exchange_id !== null && (value as ExchangeValue).token_id !== null
}

export interface BaseContractCall {
  data: string
  owner_address: string
  contract_address: string
}

export function isContractCall(value: any): value is BaseContractCall {
  if (
    value == null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value !== 'object' ||
    Array.isArray(value)
  ) {
    return false
  }
  return (
    (value as BaseContractCall).data !== null &&
    (value as BaseContractCall).owner_address !== null &&
    (value as BaseContractCall).contract_address !== null
  )
}

export interface ContractParam {
  [key: string]: number | string | ContractParam
}

export interface ContractParams {
  parameter: ContractParam
  type: string
}

export interface Transaction {
  blockID?: string
  blockHeight?: number
  index?: number
  ret?: Array<Ret>
  signature: Array<string>
  txID: string
  raw_data: {
    data?: string
    contract: Array<ContractParams>
    ref_block_bytes: string
    ref_block_hash: string
    expiration: number
    timestamp?: number
  }
  raw_data_hex: string
  tokenProperties: Array<TokenProperties>
}

interface TokenProperties {
  from: string
  to: string
  amount: number
  assetName: string
  symbol: string
  precision: number
  type: string
}

export function isTransaction(data: any): data is Transaction {
  try {
    return (data as Transaction).raw_data_hex != null || data.length === 0
  } catch (e) {
    return false
  }
}

export function isTransactions(data: any[]): data is Transaction[] {
  try {
    return isTransaction(data[0]) || data.length === 0
  } catch (e) {
    return false
  }
}

// blocks

export interface BlockHeader {
  raw_data: {
    number: number
    txTrieRoot: string
    witness_address: string
    parentHash: string
    version?: number
    timestamp: number
  }
  witness_signature: string
}

export interface BlockBase {
  blockID: string
  block_header: BlockHeader
}

export interface BlockCondensed extends BlockBase {
  transactions: Array<string>
  txCount: number
}

export function isBlockCondensed(data: any): data is BlockCondensed {
  if (data == null) {
    return false
  }
  return (data as BlockCondensed).txCount != null && (data as BlockCondensed).blockID != null
}

// log events

export interface TransactionReceipt {
  energy_fee?: number
  origin_energy_usage?: number
  energy_usage_total?: number
  net_fee?: number
  net_usage?: number
  result?: string
}

export interface TransactionLog {
  address: string
  topics: Array<string>
  data: string
}

export interface TransactionInfoBase {
  id: string
  blockNumber: number
  blockTimeStamp: number
  result?: string
  resMessage?: string
  contractResult: Array<string>
  receipt: TransactionReceipt
}

export interface TronInternalTransctionCallValue {
  callValue: number
  tokenId?: string | number
}

export function isTronInternalTransactionCallValue(call: unknown): call is TronInternalTransctionCallValue {
  return (call != null && typeof call === 'object' && (call as TronInternalTransctionCallValue).callValue != null)
}

// https://developers.tron.network/docs/tron-protocol-transaction#the-examples-of-internal-transactions
export interface TronInternalTransaction {
  hash: string
  caller_address: string
  transferTo_address: string
  callValueInfo: Array<TronInternalTransctionCallValue>
  note: string // instruction. decode from hex -> text
  rejected?: boolean
  extra?: string | Object
}

export interface TransactionInfo extends TransactionInfoBase { // primary type
  fee?: number
  contract_address?: string
  log?: Array<TransactionLog>
  decodedLogs?: Array<DecodedFunctionCall>
  decodedInternal?: Array<InternalTransactions>
  internal_transactions?: Array<TronInternalTransaction>
  index?: number
}

export function isTransactionInfos(data: any[]): data is TransactionInfo[] {
  try {
    return ((data[0] as TransactionInfo).blockNumber != null && (data[0] as TransactionInfo).receipt != null) || data.length === 0
  } catch (e) {
    return false
  }
}

export interface InternalTransaction {
  type: 'other'
  from: string
  to: string
  value: number
  instruction: string
}

export type InternalTransactions = Trx | Trc10 | InternalTransaction

// TRC10 token transfer
// txn.raw_data.contract[0?].type = TransferAssetContract
// txn.raw_data.contract[0?].value : asset_name -> decode hex string -> look up id from getassetissuelist
export interface Trc10 {
  type: 'trc10'
  from: string
  to: string
  amount: number
  encodedAssetId: string
  assetId: string
  asset?: Asset
  precision: number
}

export interface Asset {
  owner_address: string
  name: string // hex
  abbr: string // hex
  total_supply: number
  trx_num: number
  precision?: number
  num: number
  start_time: number
  end_time: number
  description: string // hex
  url: string // hex
  id: number
}

// TRX transfer
// txn.raw_data.contract[0?].type = TransferContract
// txn.raw_data.contract[0?].parameter.value : has amount, owner_address, to_address : no asset_name
export interface Trx {
  type: 'protocol'
  from: string
  to: string
  amount: number
  assetName: 'TRX'
  symbol: 'Tron'
  precision: number
}

export function formatTron(amount: number, precision: number, assetName: string) {
  return `${prettyRoundedNumber(divideBigNum(amount, precision))} ${assetName}`
}