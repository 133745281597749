import { AggregatedTransaction, CounterParty } from "./bitcoin"

export interface FieldMappingAny {
  [key: string]: any
}

export type Address = string
export type Value = string | number | boolean | Array<string | number | boolean>

export interface FormattedBlock {
  /** the block number */
  number: number | null
  /** hash of the block */
  hash: string | null
  /** hash of the parent block */
  parentHash: string
  /** SHA3 of the uncles data in the block */
  sha3Uncles: string
  /** the address of the beneficiary to whom the mining rewards were given */
  miner: Address
  minerDisplayName?: string | null
  /** the root of the final state trie of the block */
  stateRoot: string
  /** the root of the transaction trie of the block */
  transactionsRoot: string
  /** the root of the receipts trie of the block */
  receiptsRoot: string
  /** the bloom filter for the logs of the block */
  logsBloom: string | null
  /** integer of the difficulty for this block */
  difficulty: number | string
  /** integer of the total difficulty of the chain until this block */
  totalDifficulty: number | string
  /** the maximum gas allowed in this block */
  gasLimit: number | string
  /** the total used gas by all transactions in this block */
  gasUsed: number | string
  /** the unix timestamp (seconds since epoch) for when the block was collated */
  timestamp: number | string
  /** the "extra data" field of this block */
  extraData: string
  /** hash of the generated proof-of-work */
  nonce: string | null
  /** integer the size of this block in bytes */
  size: number
  /** Array of uncle hashes */
  uncles: string[]

  // additional/computed information

  /** number of transactions in this block */
  transactionCount: number

  statsDisplay?: string
}

export interface AddressInfo {
  /** true if the address is a contract, otherwise false. This is determined by attempting to retrieve code stored at the address (if any) */
  isContract: boolean
  /** Name of the smart contract by matching it against configured ABI information */
  contractName?: string
}

export type DecodedStruct = { [k: string]: Value | DecodedStruct } | DecodedStruct[]

export interface FunctionCall {
  /** Function name */
  name: string
  /** Function signature (name and parameter types) */
  signature: string
  /** List of decoded parameters */
  params: Array<{
    /** Paramter name - omitted if the function call was decoded anonymously */
    name?: string
    /** Data type */
    type: string
    /** Decoded value */
    value: Value | DecodedStruct
  }>
  /**
   * A map of parameter names and their decoded value.
   * Omitted if the function call was decoded anonymously
   */
  args?: { [name: string]: Value | DecodedStruct }
  extra: any
}

export interface BaseFormattedTransaction extends FieldMappingAny {
  /** hash of the transaction */
  hash: string
  /** address of the sender */
  from: Address
  /** address of the receiver. null when its a contract creation transaction */
  to: Address | null
  /** gas provided by the sender */
  gas: number | string
  /** gas price provided by the sender in Wei */
  gasPrice: number | string
  /** the data send along with the transaction */
  input: string
  /** the number of transactions made by the sender prior to this one */
  nonce: number
  /** value transferred in Wei */
  value: number | string
  /** ECDSA recovery id */
  v: string
  /** ECDSA signature r */
  r: string
  /** ECDSA signature s */
  s: string

  // additional info

  /** Information about the recipient address of the transaction */
  toInfo?: AddressInfo | ContractInfo
  /** Information about the sender address of the transaction */
  fromInfo?: AddressInfo | ContractInfo
  /** Information about the function extracted from `input` if ABI information is available */
  call?: FunctionCall
}

/** Transaction and transaction receipt information formatted for output */
export interface FormattedTransaction extends BaseFormattedTransaction {
  /** hash of the block where this transaction was in */
  blockHash: string | null
  /** integer of the transaction's index position in the block */
  transactionIndex: number | null

  // additional info

  /** number of the block where this transaction was in */
  blockNumber: number | null

  // receipt information

  /** Success or failure state of the transaction receipt */
  status: 'success' | 'failure' | null
  /** The amount of gas used by this specific transaction alone */
  gasUsed: number | string
  /** The total amount of gas used when this transaction was executed in the block */
  cumulativeGasUsed: number | string
  /** The contract address created, if the transaction was a contract creation, otherwise null  */
  contractAddress: Address | null

  // additional/computed information

  /** Information about the created contract address */
  contractAddressInfo?: AddressInfo | ContractInfo
}

export interface AttributedTransaction extends FormattedTransaction {
  fromDisplayName: string | null
  toDisplayName: string | null
}

export interface EventData {
  /** Event name */
  name: string
  /** Event signature (name and parameter types) */
  signature: string
  /** List of decoded parameters */
  params: Array<{
    /** Paramter name - omitted if the event call was decoded anonymously */
    name?: string
    /** Data type */
    type: string
    /** Decoded value */
    value: Value | DecodedStruct
  }>
  /** A map of parameter names and their decoded value. Omitted if decoded anonymously */
  args?: { [name: string]: Value | DecodedStruct }
  extra?: any
}

export interface FormattedLogEvent {
  /** true when the log was removed, due to a chain reorganization. false if its a valid log */
  removed?: boolean
  /** integer of the log index position in the block  */
  logIndex: number | null
  /** the block number where this log is located */
  blockNumber: number | null
  /** hash of the block where this log is located */
  blockHash: string | null
  /** hash of the transaction where this log was created */
  transactionHash: string | null
  /** integer index position of this transaction in it's block */
  transactionIndex: number | null
  /** contract address from which this log originated */
  address: Address
  /** contains the non-indexed arguments of the log */
  data: string
  /** Array of 0 to 4 32 Bytes DATA of indexed log arguments.
   * (In solidity: The first topic is the hash of the signature of the event
   * (e.g. Deposit(address,bytes32,uint256)),
   * except you declared the event with the anonymous specifier.) */
  topics: string[]

  // additional/computed information

  /** Information about the address emitting this event */
  addressInfo?: AddressInfo | ContractInfo
  /** Decoded event name, signature and parameters */
  event?: EventData
}

export interface EthContractResponse {
  success: boolean
}

export interface EthContract extends EthContractResponse {
  id: string
  type: string
  name: string
  symbol: string
  decimals?: number
}

export interface ContractProxy {
  address: string
  standard?: string
  subType?: string
  target?: string
}

export interface ContractType {
  name: string | null
  subType?: string
  standards?: string[]
  proxies?: ContractProxy[]
  metadata?: boolean
  baseUri?: boolean
  enumeration?: boolean
  receive?: string[]
}

export interface TokenProperties {
  name: string
  symbol: string
  decimals?: number | string
  totalSupply?: number
  baseUri?: string
  items?: string[]
}

export interface MultiSigProperties {
  owners: string[]
}

export interface ERC3668Properties {
  url: string
}

export interface ContractInfo {
  /** True if the corresponding account is a smart contract, otherwise false */
  isContract: boolean
  /** A unique representation of all function and event signatures of a contract */
  fingerprint?: string
  /** Name of the contract from configured ABIs */
  contractName?: string
  /** Type of contract from signature matching */
  contractType?: ContractType
  /** Token properties (if ERC20, ERC721, ERC777, ERC1155) */
  properties?: TokenProperties | MultiSigProperties | ERC3668Properties
}

export function isContractInfo(info: AddressInfo | ContractInfo | undefined | null): info is ContractInfo {
  if (info == null) return false
  return (info as ContractInfo).contractType != null
}

export function isTokenProps(
  props: TokenProperties | MultiSigProperties | ERC3668Properties | undefined
): props is TokenProperties {
  if (props == null) return false
  return (props as TokenProperties).name != null
}

export function hasStandards(type: ContractType | undefined): boolean {
  if (type == null) return false
  if ((type as ContractType).standards == null) return false
  if (Array.isArray((type as ContractType).standards)) {
    return type.standards?.length! > 0
  }
  return false
}

export function isContractType(type: ContractType | undefined): type is ContractType {
  if (type == null) return false
  return (type as ContractType).name != null
}

export function isFormattedTransaction(data: any): data is FormattedTransaction {
  if (!data) {
    return false
  }
  return (data as FormattedTransaction).from != null
}

export function isFormattedTransactions(data: any[]): data is FormattedTransaction[] {
  try {
    return (data[0] as FormattedTransaction).input != null || data.length === 0
  } catch (e) {
    return false
  }
}

export function isFormattedBlock(data: any): data is FormattedBlock {
  if (data == null) {
    return false
  }
  return (data as FormattedBlock).miner != null && (data as FormattedBlock).number != null
}

// hbase-related types (not from blockchain data)

export interface SimpleAggregatedEthereumTransaction extends SimpleAggregatedEVMTransaction {
  status: string | null
}

export interface SimpleAggregatedEVMTransaction extends AggregatedTransaction {
  counterparties: ClusteredCounterparty[]
}

export interface ClusteredCounterparty extends Omit<CounterParty, 'index'> {
  symbol: string
  cluster?: string
  clusterAttribution?: string
  isOutput: boolean
}