
import { Filter, Header } from '@/subcomponents/types/genericTable'
import GenericTable from '@/subcomponents/GenericTable.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { ExtendedInput, ExtendedOutput, ExtendedTx, isExtendedCoinbase, isExtendedTx } from '@/types/bitcoin'
import {
  FormattedLogEvent,
  FormattedTransaction,
  hasStandards,
  isContractInfo,
  isContractType,
  isFormattedTransaction,
  isTokenProps
} from '@/types/eth'
import { formatNumber, sumValuesBigNum, trimZeros } from '@/utils/bignum'
import {
  displayName,
  filterDisplayName,
  formatERC20,
  formatEth,
  formatGas,
  getNftInfo,
  wyvernAtomicMatchDisplay
} from '@/utils/eth'
import { cutMiddle, sleep, titleCase } from '@/utils/general'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { TxnsBlockPage } from '@/utils/api'
import { ChainReceipt, ChainTransaction } from '@/store/chain'
import { ContractParam, formatTron, Transaction, TransactionInfo } from '@/types/tron'

function iconStatus(flag: string): string {
  return flag === 'success' ? 'mdi-check' : 'mdi-cancel'
}

function ethereumMetadata(txn: FormattedTransaction, receipts: FormattedLogEvent[]): string {
  const { call, toInfo, contractAddress } = txn
  if (!toInfo) {
    if (contractAddress != null) {
      return `New contract address: ${contractAddress}`
    }
    return ''
  }
  if (!isContractInfo(toInfo)) {
    return ''
  }
  const contractType = toInfo.isContract && isContractType(toInfo.contractType) ? toInfo.contractType.name : undefined
  const standards = hasStandards(toInfo.contractType) ? toInfo.contractType?.standards! : undefined
  if (standards) {
    if (standards.includes('ERC20') && call != null) {
      if (
        call.signature === 'transfer(address,uint256)' &&
        isTokenProps(toInfo.properties) &&
        toInfo.properties.decimals
      ) {
        const decimals = parseInt(<string>toInfo.properties.decimals, 10)
        const amount = trimZeros(formatNumber(<string>call.params[1].value, decimals))
        const symbol = toInfo.properties.symbol
        const recipient = call.params[0].value as string
        return `Transfer ${amount} ${symbol} to ${cutMiddle(recipient)}`
      }
      if (call.signature === 'withdraw(uint256)' && isTokenProps(toInfo.properties) && toInfo.properties.decimals) {
        const amount = trimZeros(formatERC20(call.params[0].value as string, toInfo.properties.decimals))
        return `Wthdraw ${amount} ${toInfo.properties.symbol}`
      }
      if (call.signature === 'approve(address,uint256)') {
        const spender = call.params[0].value as string
        return `Approve spender ${cutMiddle(spender)}`
      }
    }
    if (standards.includes('ERC721') && call != null) {
      const nftInfo = getNftInfo(call, toInfo)
      if (nftInfo) {
        return `Transfer ${nftInfo.name} NFT (ID ${nftInfo.tokenId}) to ${cutMiddle(nftInfo.recipient)}`
      }
    }
  }
  if (call && call.name && call.signature) {
    const wyvernDisplay = wyvernAtomicMatchDisplay(txn, receipts)
    return wyvernDisplay ? wyvernDisplay : call.name
  }
  return ''
}

function tronMetadata(txn: Transaction, receipts: TransactionInfo[]): string {
  // TODO: get metadata out of tron transaction
  return ''
}

@Component({
  components: {
    GenericTable,
    ServerTable
  },
  computed: mapState([
    'supportedNetworks',
    'bitcoinNetworks',
    'ethereumNetworks',
    'tronNetworks',
    'transactions',
    'transactionsBlockPage',
    'receipts'
  ])
})
export default class Transactions extends Vue {
  public supportedNetworks!: string[]
  public bitcoinNetworks!: string[]
  public ethereumNetworks!: string[]
  public tronNetworks!: string[]
  public transactions!: ChainTransaction[] | string[]
  public transactionsBlockPage!: TxnsBlockPage
  public receipts!: ChainReceipt[]
  public network: string = ''
  private blockId: string = ''
  public networkDisplayName: string = ''
  private networkType: string = ''
  private bitcoinHeaders: Header[] = [
    { text: 'TxId', value: 'txid', transform: cutMiddle },
    { text: 'Block', value: 'height' },
    { text: 'Time', value: 'time', transform: (d: number) => this.formatDate(d) },
    { text: 'Version', value: 'version' },
    { text: 'Inputs', value: 'vin', transform: (v: ExtendedInput[]) => v.length },
    { text: 'Outputs', value: 'vout', transform: (v: ExtendedOutput[]) => v.length },
    {
      text: 'Value In',
      value: 'vin',
      transform: (v: ExtendedInput[]) =>
        trimZeros(
          sumValuesBigNum(
            v.map((i) => (isExtendedCoinbase(i) ? i.value : i.spentOutput.value)),
            8,
            false
          )
        )
    },
    {
      text: 'Value Out',
      value: 'vout',
      transform: (v: ExtendedOutput[]) =>
        trimZeros(
          sumValuesBigNum(
            v.map((o) => o.value),
            8,
            false
          )
        )
    }
  ]
  private ethereumHeaders: Header[] = [
    { text: 'TxId', value: 'hash', transform: cutMiddle, icon: { flag: 'status', func: iconStatus } },
    { text: 'Block', value: 'blockNumber' },
    { text: 'From', value: ['from', 'fromInfo'], transform: displayName },
    { text: 'To', value: ['to', 'toInfo', 'contractAddress'], transform: displayName },
    { text: 'Value', value: 'value', transform: formatEth },
    { text: 'Fee', value: ['gas', 'gasPrice', 'gasUsed'], transform: formatGas }
  ]
  private ethereumFilters: Filter[] = [
    { text: 'Function', value: 'call.name' },
    { text: 'From', value: 'from' },
    { text: 'To', value: 'to', transform: filterDisplayName }
  ]
  private tronHeaders: Header[] = [
    { text: 'TxId', value: 'txID', transform: cutMiddle },
    { text: 'Block', value: 'blockHeight' },
    {
      text: 'From',
      value: '',
      transform: (transaction: Transaction) => transaction.tokenProperties.length ? transaction.tokenProperties[0].from : ''
    },
    {
      text: 'To',
      value: '',
      transform: (transaction: Transaction) => transaction.tokenProperties.length ? transaction.tokenProperties[0].to : ''
    },
    {
      text: 'Value',
      value: '',
      transform: (transaction: Transaction) => {
        const { amount, precision, assetName } = transaction.tokenProperties[0]
        return formatTron(amount, precision, assetName) 
      }
    }
  ]
  private tronFilters: Filter[] = [
    { text: 'Function', value: '', transform: (transaction: Transaction) => transaction.raw_data.contract[0].type },
    {
      text: 'From',
      value: '',
      transform: (transaction: Transaction) => transaction.tokenProperties.length ? transaction.tokenProperties[0].from : ''
    },
    {
      text: 'To',
      value: '',
      transform: (transaction: Transaction) => transaction.tokenProperties.length ? transaction.tokenProperties[0].from : ''
    }
  ]
  public itemsPerPage: number[] = [10, 25, 50, 100]
  public totalItems: number = 0
  public page: number = 0
  public perPage: number = 100
  // private txnsResolved: boolean = true
  public template: string = 'default'
  public loading: boolean = false
  private pageUpdating: boolean = false
  private perPageUpdating: boolean = false

  get headers() {
    return this.networkType === 'ethereum' ? this.ethereumHeaders
      : this.networkType === 'bitcoin' ? this.bitcoinHeaders
      : this.networkType === 'tron' ? this.tronHeaders
      : []
  }

  get metadata() {
    return this.template === 'ethereum' ? ethereumMetadata
      : this.template === 'tron' ? tronMetadata
      : undefined
  }

  get latestBlock() {
    const latestBlocks = this.$store.state.latestBlocks
    if (latestBlocks[this.network]) {
      return latestBlocks[this.network]
    }
    return undefined
  }

  get filters() {
    return this.networkType === 'ethereum' ? this.ethereumFilters
      : this.networkType === 'tron' ? this.tronFilters
      : []
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.blockId = this.$route.params.blockId
    const page = this.$route.params.page
    if (page == null || page === '') {
      this.page = 0
    } else {
      try {
        const parsed = parseInt(page, 10)
        this.page = parsed <= 0 ? 0 : parsed - 1
      } catch (e) {
        console.log("couldn't parse page number")
      }
    }
    this.networkDisplayName = titleCase(this.network)
    this.networkType = this.determineNetworkType()
    this.resetTransactions()
    const txnsPerPage: number = this.$store.state.browserCache.get('txnsPerPage')
    if (txnsPerPage != null && typeof txnsPerPage === 'number') {
      this.perPage = txnsPerPage
    }
    const txnsPerPageRoute = parseInt(this.$route.params.count, 10)
    if (txnsPerPageRoute !== txnsPerPage) {
      this.perPage = txnsPerPageRoute
    }
    if (this.networkType === 'bitcoin') {
      this.getTransactionsPage()
    } else {
      if (this.networkType !== '') {
        this.template = this.networkType
        this.getTransactions()
      }
    }
  }

  private determineNetworkType(): string {
    if (this.$store.state.bitcoinNetworks.includes(this.network)) {
      return 'bitcoin'
    }
    if (this.$store.state.ethereumNetworks.includes(this.network)) {
      return 'ethereum'
    }
    if (this.$store.state.tronNetworks.includes(this.network)) {
      return 'tron'
    }
    return ''
  }

  private resetTransactions() {
    this.$store.dispatch('resetTransactions')
  }

  private getTransactions() {
    if (this.supportedNetworks.includes(this.network)) {
      this.loading = true
      this.$store.dispatch('getTransactions', { network: this.network, id: this.blockId })
    }
  }

  private getTransactionsPage() {
    this.loading = true
    const network = this.network
    const id = this.blockId
    const count = this.perPage
    const page = this.page
    this.$store.dispatch('getTransactionsBlockPage', { network, id, count, page })
  }

  @Watch('$store.state.transactionsBlockPage')
  private transactionsBlockPageUpdated() {
    this.loading = false
  }

  @Watch('$store.state.transactions')
  private setTransactionPage() {
    if (this.networkType === 'bitcoin') {
      const start = this.page * this.perPage
      const end = start + this.perPage
      if (this.transactions.length) {
        const transactions = this.transactions.slice(start, end)
        this.$store.dispatch('setTransactionPage', { transactions })
      }
    }
    if (this.networkType !== 'bitcoin') {
      this.loading = false
    }
  }

  @Watch('$store.state.transactionsPage')
  private getFullTransactionsForPage() {
    if (this.network === 'bitcoin') {
      const list = this.$store.state.transactionsPage
      if (list.length > 0 && typeof list[0] === 'string') {
        this.$store.dispatch('getTransactionsListPage', { network: this.network, list })
      } else if (list.length > 0 && this.loading) {
        for (let i = 0; i < list.length; i++) {
          const index = this.page * this.perPage + i
          this.$store.dispatch('setTransactionAtIndex', { transaction: list[i], index })
        }
        this.loading = false
      }
    }
  }

  public onItemClick(item: ChainTransaction) {
    const id = isExtendedTx(item) ? item.txid
      : isFormattedTransaction(item) ? item.hash
      : item.txID
    this.$router.push(`/explorer/transaction/${this.network}/${id}`)
  }

  public async onPageUpdated(page: number) {
    this.page = page - 1
    this.pageUpdating = true
    if (this.networkType === 'bitcoin') {
      await sleep(50) // page and items per page update when on page > 1 and items per page is changed
      if (!this.perPageUpdating) {
        this.$router.replace({ params: { count: this.perPage.toString(), page: page.toString() } })
      }
    } else {
      this.setTransactionPage()
    }
    this.pageUpdating = false
  }

  public async onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.perPageUpdating = true
    if (this.networkType === 'bitcoin') {
      await sleep(50)
      this.$store.dispatch('browserCacheAdd', { key: 'txnsPerPage', value: count })
      if (!this.pageUpdating) {
        this.$router.replace({ params: { count: this.perPage.toString(), page: (this.page + 1).toString() } })
      }
    }
    this.perPageUpdating = false
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }
}
