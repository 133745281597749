
import { Investigation } from '@/store/investigations/investigations'
import { ReportTransaction, Target, TransactionFlowTarget } from '@/store/investigations/viz'
import { AggregatedBitcoinTransaction } from '@/utils/api'
import { PDFImageConfigs } from '@/utils/pdf'
import { formatFlowTarget, handleDownload } from '@/utils/report'
import { Viewport } from 'pixi-viewport'
import { Application } from 'pixi.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { renderHtmlToCanvas } from '@/utils/general'
import { Grid } from '@/utils/graph-helpers'

@Component({
  computed: mapState(['transactionFlowTargets', 'transactionFlowTargetsMap', 'report', 'target', 'notes'])
})
export default class Download extends Vue {
  @Prop() investigation!: Investigation
  @Prop() viewport!: Viewport
  @Prop() app!: Application
  @Prop() grid!: Grid

  public transactionFlowTargets!: TransactionFlowTarget[]
  public transactionFlowTargetsMap!: { [key: string]: AggregatedBitcoinTransaction }
  public report!: ReportTransaction[]
  public target!: Target | undefined
  public notes!: string

  public flowsToDownload: TransactionFlowTarget[] = []

  public downloadTypes: string[] = ['PDF', 'CSV']
  public downloadType: string = 'PDF'

  formatFlowTarget(target: TransactionFlowTarget) {
    return formatFlowTarget(target, this.transactionFlowTargetsMap)
  }

  async downloadGraph() {
    await this.$store.dispatch('getReport')
    await this.$store.dispatch('sortReport', { field: 'time', direction: 'asc' })
    const reportName = this.investigation.name ? this.investigation.name : 'unnamed'

    // set up the graph image
    this.viewport.removeChild(this.grid.gfx) // remove grid so that doesn't get taken into account
    
    const multiplier = 600 / this.viewport.height

    const width = this.app.renderer.screen.width
    const height = this.app.renderer.screen.height
    const resolution = this.app.renderer.resolution

    this.app.renderer.resize(width * multiplier, height * multiplier)
    this.app.renderer.resolution = resolution * multiplier

    const canvas = this.app.renderer.extract.canvas(this.app.stage) // extract

    this.app.renderer.resolution = resolution
    this.app.renderer.resize(width, height)

    const graphPdfConfigs: PDFImageConfigs = {
      width: this.app.stage.width,
      height: this.app.stage.height,
      canvasURL: canvas && canvas.toDataURL ? canvas.toDataURL('image/png', 1) : ''
    }

    this.viewport.addChild(this.grid.gfx) // add grid back

    // set up images for the notes
    let notesPdfConfigs: PDFImageConfigs | undefined
    if (this.notes) {
      const notesCanvas = document.createElement("canvas")
      // TODO: figure out a way to predict how to set these based on what's in the notes
      const width = 1200
      const height = 980
      notesCanvas.setAttribute('width', `${width}`)
      notesCanvas.setAttribute('height', `${height}`)
      const ctx = notesCanvas.getContext('2d') as CanvasRenderingContext2D
      
      await renderHtmlToCanvas(this.notes, ctx, 0, 0, width, height)

      const canvasURL = notesCanvas.toDataURL("image/png", 1.0)

      notesPdfConfigs = {
        width,
        height,
        canvasURL
      }
    }

    await handleDownload(this.report, this.flowsToDownload, this.downloadType, reportName, graphPdfConfigs, notesPdfConfigs)
  }

  public doAction() {
    this.downloadGraph()
  }
}
