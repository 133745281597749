
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import TreeMapGraph from '@/subcomponents/TreeMapGraph.vue'
import EntityPairTransactions from '@/subcomponents/EntityPairTransactions.vue'
import { InteractiveLink, SettingsCollection, Target } from '@/store/investigations/viz'
import { isTransactionLevel } from '@/utils/graph-links'
import { classifyNodeId } from '@/utils/viz'
import { cutEnd, prettyRoundedNumber } from '@/utils/general'
import TransactionBreakdown from '@/subcomponents/TransactionBreakdown.vue'

@Component({
  components: {
    TransactionBreakdown,
    TreeMapGraph,
    EntityPairTransactions
  },
  computed: mapState(['settings', 'target', 'selectedLink', 'summaryLinkDirection'])
})
export default class Summary extends Vue {
  public settings!: SettingsCollection
  public target!: Target | undefined
  public selectedLink!: InteractiveLink | undefined
  public summaryLinkDirection!: 0 | 1
  public visible: boolean = false
  public decimalFormatter!: (n: number | string) => string

  public get entityTarget() {
    return this.target != null && this.target.type !== 'transaction'
  }

  public get summaryLinkSelected() {
    if (this.selectedLink != null) {
      return !isTransactionLevel(this.selectedLink)
    }
    return false
  }

  public get directionLinkData() {
    if (this.selectedLink != null) {
      const { source, target } = this.selectedLink
      const { id: sourceId, type: sourceType } = classifyNodeId(source.id)
      const shortenedSource = sourceType === 'attribution' ? sourceId : cutEnd(sourceId, 8)
      const { id: targetId, type: targetType } = classifyNodeId(target.id)
      const shortenedTarget = targetType === 'attribution' ? targetId : cutEnd(targetId, 8)

      const directions = [`${shortenedSource} -> ${shortenedTarget}`]
      if (this.selectedLink.reversedLink != null) {
        directions.push(`${shortenedTarget} -> ${shortenedSource}`)
      }
      return directions
    }
    return []
  }

  public changeDirection(direction: number) {
    this.$store.dispatch('setSummaryLinkDirection', { direction })
  }

  public destroyTreemap() {
    ;(this.$refs.treemap as Vue & { destroy: () => void }).destroy()
  }

  public visibilityChanged(isVisible: boolean) {
    this.visible = isVisible
  }

  created() {
    this.toggleRounding()
  }

  @Watch('settings', { deep: true })
  toggleRounding() {
    if (this.settings.roundDecimalsSwitch) {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n, 8)
    } else {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n)
    }
  }
}
