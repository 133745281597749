
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { Flow, FormattedNode, InteractiveLink, SettingsCollection, Target } from '@/store/investigations/viz'
import { isExtendedCoinbase, isExtendedTx, TxHeader } from '@/types/bitcoin'
import { AggregatedBitcoinTransaction, Attribution, ClusterMetadata, EntitySummary } from '@/utils/api'
import { cutMiddle, titleCase, prettyRoundedNumber, timeToMilliseconds } from '@/utils/general'
import { classifyNodeId, networkSymbol, txnContractName } from '@/utils/viz'
import { calcFee, calcInputAmount, calcOutputAmount, svb } from '@/utils/bitcoin'
import CopyBtn from '@/subcomponents/CopyBtn.vue'
import { FormattedSummaryLink, isTransactionLevel } from '@/utils/graph-links'
import { State } from '@/store'
import { LRUCache } from '@splunkdlt/cache'
import BtcDateRange from '@/subcomponents/BtcDateRange.vue'
import { filter } from '@/utils/filters'
import { isFormattedTransaction } from '@/types/eth'
import { ChainReceipt, ChainTransaction, isFormattedLogEvent } from '@/store/chain'
import { isTransaction } from '@/types/tron'

type DirectionClass = 'value-input' | 'value-output'

export interface AddressBreakdown {
  address: string
  amount: number
  directionClass: DirectionClass
}

@Component({
  components: {
    CopyBtn,
    BtcDateRange
  },
  computed: {
    ...mapState([
      'settings',
      'target',
      'selectedLink',
      'addressCluster',
      'attributionClusters',
      'entitySummary',
      'transaction',
      'transactionFlowTargetsMap',
      'summaryLinkDirection'
    ]),
    ...mapState({
      attributionsCache: (state) => (state as State).shared.attributionsCache
    })
  }
})
export default class Metadata extends Vue {
  @Prop() addressAttribution!: Attribution | null

  public settings!: SettingsCollection
  public target!: Target
  public selectedLink!: InteractiveLink | undefined
  public addressCluster!: ClusterMetadata
  public attributionClusters!: ClusterMetadata[]
  public entitySummary!: EntitySummary | undefined
  public transaction!: ChainTransaction
  public transactionFlowTargetsMap!: { [key: string]: AggregatedBitcoinTransaction }
  public summaryLinkDirection!: 0 | 1
  public attributionsCache!: LRUCache<string, Attribution[]>

  public symbols: string[] = []
  public symbol: string = ''
  public clusterMetadataLoaded: boolean = false
  public size: number = 0
  private sliderHover = false
  public dateRange = [0, 0]
  private transactionFlow: Flow | null = null

  public transactionHeader?: TxHeader
  public contractAddress: string = ''
  public contractName?: string = ''

  public cutMiddle = cutMiddle
  public prettyRoundedNumber = prettyRoundedNumber
  public titleCase = titleCase
  public networkSymbol = networkSymbol
  public isExtendedTx = isExtendedTx
  public isFormattedTransaction = isFormattedTransaction
  public isTransaction = isTransaction

  get targetType() {
    if (this.target) return this.target.type
    if (this.selectedLink) {
      return isTransactionLevel(this.selectedLink) ? 'transaction' : 'summary'
    }
    return ''
  }

  get rootTitle() {
    if (this.targetType === 'address') return 'Address'
    return 'Root'
  }

  get root() {
    if (this.targetType !== 'attribution') {
      return this.id
    }
    if (!this.clusterMetadataLoaded) return ''
    let time = Infinity, root = ''
    for (const { earliestTime, id } of this.attributionClusters) {
      if (earliestTime < time) {
        time = earliestTime
        root = id
      }
    }
    return root
  }

  get showEntity() {
    return this.target != null && this.target.type !== 'transaction'
  }

  get nonnegativeBalance(): string {
    if (this.entitySummary != null) {
      const { currentBalance } = this.entitySummary.symbols[this.symbol]
      if (currentBalance >= 0) {
        return prettyRoundedNumber(currentBalance)
      }
    }
    return 'Unknown'
  }

  get showTransaction() {
    return (
      this.targetType === 'transaction' &&
      this.transaction != null
    )
  }

  get network(): string {
    if (this.target) return this.target.network
    if (this.selectedLink) {
      const link = this.selectedLink
      const { source } = link
      const { network } = classifyNodeId((source as FormattedNode).id)
      return network
    }
    return ''
  }

  get id(): string {
    if (this.target) return this.target.id
    if (this.selectedLink) {
      const link = this.selectedLink
      if (isTransactionLevel(link)) {
        const { source, target } = link
        const { id: sourceId, type: sourceType } = classifyNodeId((source as FormattedNode).id)
        const { id: targetId, type: targetType } = classifyNodeId((target as FormattedNode).id)
        if (sourceType === 'transaction') return sourceId
        else if (targetType === 'transaction') return targetId
      }
    }
    return ''
  }

  get attribution(): boolean {
    if (this.showEntity) {
      if (this.targetType === 'address') {
        return this.addressAttribution ? true : false
      }
      if (this.clusterMetadataLoaded) {
        return this.addressCluster.topAttribution ? true : false
      }
    }
    return false
  }

  get macroAvailable() {
    return this.targetType !== 'attribution' && (this.attribution || (this.targetType === 'address' && this.clusterMetadataLoaded))
  }

  get fee() {
    if (isExtendedTx(this.transaction)) {
      const inputs = this.transaction.vin
      if (inputs && isExtendedCoinbase(inputs[0])) {
        return 0
      }
      return calcFee(this.inputAmount, this.outputAmount)
    }
  }

  get inputAmount(): string {
    if (isExtendedTx(this.transaction)) {
      return calcInputAmount(this.transaction.vin)
    }
    return ''
  }

  get outputAmount() {
    if (isExtendedTx(this.transaction)) {
      return calcOutputAmount(this.transaction.vout)
    }
    return ''
  }

  get svb() {
    if (isExtendedTx(this.transaction)) {
      if (!this.fee) {
        return 0
      }
      return svb(this.fee, this.transaction.vsize)
    }
  }

  get currentDirectionLink() {
    if (this.summaryLinkDirection === 0) {
      return this.selectedLink
    } else if (this.selectedLink != null) {
      return this.selectedLink.reversedLink
    }
  }

  get networkLinkSummaries() {
    return filter((this.currentDirectionLink as FormattedSummaryLink).linkSummaries, s => s.network === this.network)
  }

  get symbolLinkSummary() {
    return this.networkLinkSummaries.find(s => s.symbol === this.symbol)
  }

  get transactionFlows() {
    if (this.currentDirectionLink != null) {
      if (isTransactionLevel(this.currentDirectionLink)) {
        const {flows } = this.currentDirectionLink.amount
        return flows ?? []
      } else {
        const transactions = this.currentDirectionLink.transactions
        return filter(transactions, t => t.flows != null).flatMap(t => t.flows)
      }
    }
  }

  @Watch('$store.state.shared.attributionSizesUpdated')
  @Watch('$store.state.shared.attributionSizesNotLoaded', { deep: true })
  private updateSize() {
    this.size = 0
    if (this.targetType !== 'attribution' && this.addressCluster != null) {
      this.size = this.addressCluster.size
    } else if (this.targetType === 'attribution') {
      this.size = this.attributionSize()
    }
  }

  @Watch('summaryLinkDirection')
  private updateSymbols() {
    this.symbols = this.entitySummary != null ? 
      filter(
        Object.keys(this.entitySummary.symbols),
        key => {
          if (this.entitySummary != null) {
            return this.entitySummary.symbols[key].currentCredits !== 0 ||
              this.entitySummary.symbols[key].currentDeductions !== 0
          }
          return false // this will never happen
        }
      )
      : this.targetType === 'summary' ? this.networkLinkSummaries.map(s => s.symbol) :
      []
    this.symbol = this.symbols.length ? this.symbols[0] : ''
  }

  @Watch('target')
  @Watch('selectedLink')
  private targetChanged() {
    this.updateSymbols()
    if (this.targetType) {
      this.$store.dispatch('clearClusters')
      this.clusterMetadataLoaded = false
      if (this.id) {
        if (this.targetType === 'transaction') {
          this.txnHeadersCacheUpdated() // replace header
          // get transaction & header
          this.$store.dispatch('getTransaction', { network: this.network, id: this.id })
          this.$store.dispatch('cacheTxnHeaders', { network: this.network, txids: [this.id] })
          // get contract info if relevant
          const receipt = this.$store.state.receiptsCache.get(this.id) as ChainReceipt
          if (receipt != null) {
            this.contractAddress = isFormattedLogEvent(receipt) ? receipt.address : '' // TODO: if tron, get contract address
            this.contractName = txnContractName(this.id, this.$store.state.receiptsCache, this.$store.state.transactionsCache)
          }
        } else if (this.targetType === 'attribution') {
          this.$store.dispatch('getAttributionClusters', { network: this.network, id: this.id, page: 1, perPage: 100 })
        } else {
          this.$store.dispatch('getClusterForAddress', { network: this.network, address: this.id })
        }
      }
    }
    this.updateSize()
    this.highlightFlow(null)
  }

  @Watch('addressCluster')
  @Watch('attributionClusters')
  private clusterUpdated() {
    if (this.addressCluster != null || this.attributionClusters != null) {
      this.clusterMetadataLoaded = true
    }
  }

  private attributionSize(): number {
    const networkSizeMap: { [network: string]: number } | null = this.$store.state.shared.attributionSizeCache.get(this.id)
    if (networkSizeMap != null && networkSizeMap[this.network] != null) {
      return networkSizeMap[this.network]
    } else if (this.$store.state.shared.attributionSizesNotLoaded.has(`${this.id}||${this.network}`)) {
      return -1
    }
    return 0
  }

  @Watch('entitySummary')
  private updateDateRange() {
    if (this.entitySummary != null) {
      const { firstTransactionTime, lastTransactionTime } = this.entitySummary
      this.dateRange = [firstTransactionTime, lastTransactionTime]
      this.updateSymbols()
    }
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (this.id) {
      this.transactionHeader = undefined
      if (this.$store.state.txnHeadersCache.has(this.id)) {
        this.transactionHeader = this.$store.state.txnHeadersCache.get(this.id)
      }
    }
  }

  public formatDate(time: number): string {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true, 1)
  }

  public macrotize() {
    this.$store.dispatch('macrotize', this.target)
  }

  public download() {
    this.$store.dispatch('downloadAddresses', { size: this.size })
  }

  public highlightFlow(flow: Flow | null) {
    if (flow != null) {
      this.$store.dispatch('highlightFlow', { sourceFlow: flow }) // it doesn't matter whether this is source or destination
    } else {
      this.$store.dispatch('highlightFlow', {})
    }
  }

  public formatFlowTarget(flow: Flow) {
    const { targetTransaction, sending } = flow
    const { id, isOutput, index } = targetTransaction
    const key = `${id}|${isOutput}|${index}`
    const { address, cluster, clusterAttribution } = this.transactionFlowTargetsMap[key]
    const directionDescription = sending ? 'From' : 'To'
    const targetDescription = clusterAttribution ?? cutMiddle(cluster ?? address ?? '', 6)
    const targetTxnSideDescription = `(${isOutput ? 'output' : 'input'} of ${cutMiddle(id, 6)})`
    return `${directionDescription} ${targetDescription} ${targetTxnSideDescription}`
  }

  public formatFlowData(flow: Flow) {
    const amountDescription = `${prettyRoundedNumber(flow.value)} ${networkSymbol(this.network)}`

    const { targetTransaction, sending } = flow
    const { id, isOutput, index } = targetTransaction
    const key = `${id}|${isOutput}|${index}`
    const { address, cluster, clusterAttribution } = this.transactionFlowTargetsMap[key]
    const directionDescription = sending ? 'from' : 'to'
    const targetDescription = clusterAttribution ?? cutMiddle(cluster ?? address ?? '', 6)
    return `${amountDescription} ${directionDescription} ${targetDescription}`
  }

  mounted() {
    this.targetChanged()
  }
}
