
import { Component, Vue, Watch } from 'vue-property-decorator'
import { SimpleAggregatedTransaction } from '@/types/bitcoin'
import { ClusterMetadata, ComparisonConstraints, EntitySummary, SortMap } from '@/utils/api'
import { ServerEvent, sleep, titleCase, timeToMilliseconds } from '@/utils/general'
import FilterBar from '@/subcomponents/FilterBar.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { mapState } from 'vuex'
import { prettyRoundedNumber } from '@/utils/general'
import BtcDateRange from '@/subcomponents/BtcDateRange.vue'
import { networkSymbol } from '@/utils/viz'
import { SimpleAggregatedEthereumTransaction } from '@/types/eth'
import { filter } from '@/utils/filters'

@Component({
  components: {
    FilterBar,
    ServerTable,
    BtcDateRange
  },
  computed: mapState(['entitySummary', 'addressCluster', 'entityLedgerSimple', 'supportedNetworks', 'listeningToEvents'])
})
export default class Address extends Vue {
  private network: string = ''
  public address: string = ''
  public networkDisplayName: string = ''
  public symbol: string = ''
  
  private sliderHover = false
  public dateRange = [0, 0]
  
  public perPage: number = 100
  public page: number = 1
  public perPageOptions = [10, 25, 50, 100]
  public isLoading = true

  public sorts = [
    { label: 'time', key: 'time' },
    { label: 'value', key: 'amount' }
  ]
  public sort: SortMap = { time: 'desc' }

  private amountConstraint?: ComparisonConstraints
  private timeConstraint?: ComparisonConstraints
  private inputs: boolean = true
  private outputs: boolean = true

  public clusterMetadataLoaded: boolean = false

  public entitySummary!: EntitySummary | undefined
  public addressCluster!: ClusterMetadata
  public entityLedgerSimple!: SimpleAggregatedTransaction[] | SimpleAggregatedEthereumTransaction[]
  public supportedNetworks!: string[]
  public listeningToEvents!: boolean

  public prettyRoundedNumber = prettyRoundedNumber
  public networkSymbol = networkSymbol

  private countFromStream: number = 0

  get symbols() {
    return this.entitySummary != null ? 
      filter(
        Object.keys(this.entitySummary.symbols),
        key => {
          if (this.entitySummary != null) {
            return this.entitySummary.symbols[key].currentCredits !== 0 ||
              this.entitySummary.symbols[key].currentDeductions !== 0
          }
          return false // this will never happen
        }
      )
      : []
  }
  
  get totalCount(): number {
    return this.countFromStream > 0 ? this.countFromStream : this.$store.state.entityLedgerCount || 0
  }

  get nonnegativeBalance(): string {
    if (this.entitySummary != null) {
      const { currentBalance } = this.entitySummary.symbols[this.symbol]
      if (currentBalance >= 0) {
        return prettyRoundedNumber(currentBalance)
      }
    }
    return 'Unknown'
  }

  @Watch('$store.state.addressClusterUpdate')
  clusterUpdated() {
    this.clusterMetadataLoaded = true
  }

  @Watch('$store.state.eventCount')
  private newEvent() {
    const eventsLength = this.$store.state.eventStack.length
    if (eventsLength > 0) {
      const event: ServerEvent = this.$store.state.eventStack[eventsLength - 1]
      if (event.type === 'count') {
        this.countFromStream = event.data as number
      }
    }
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.address = this.$route.params.address
    this.networkDisplayName = titleCase(this.network)
    this.getLedger()
    // this.$store.dispatch('getClusterForAddress', { network: this.network, address: this.address })
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.getLedger()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.getLedger()
  }

  private resetAddress() {
    this.$store.dispatch('resetAddress')
  }

  public async getLedger() {
    if (this.supportedNetworks.includes(this.network)) {
      this.isLoading = true
      const params = {
        network: this.network,
        id: this.address,
        page: this.page,
        perPage: this.perPage,
        sorts: this.sort,
        amountConstraint: this.amountConstraint,
        timeConstraint: this.timeConstraint,
        inputs: this.inputs,
        outputs: this.outputs,
        type: 'address'
      }
      this.$store.dispatch('getEntity', params)
      const countParams = {
        network: this.network,
        id: this.address,
        amountConstraint: this.amountConstraint,
        timeConstraint: this.timeConstraint,
        stream: true,
        type: 'address'
      }
      if (!this.listeningToEvents) {
        await sleep(1000)
      }
      this.$store.dispatch('getEntityLedgerCount', countParams)
    }
  }

  public onUpdateSort(sort: SortMap) {
    this.sort = sort
    this.getLedger()
  }

  public onFilter({
    amount,
    time,
    inputs,
    outputs
  } : {
    amount?: ComparisonConstraints
    time?: ComparisonConstraints
    inputs: boolean
    outputs: boolean
  }) {
    this.amountConstraint = amount
    this.timeConstraint = time
    this.inputs = inputs
    this.outputs = outputs
    this.getLedger()
  }

  @Watch('entitySummary')
  private updateDateRange() {
    if (this.entitySummary != null) {
      const { firstTransactionTime, lastTransactionTime } = this.entitySummary
      this.dateRange = [firstTransactionTime, lastTransactionTime]
      this.symbol = this.symbols.length ? this.symbols[0] : ''
    }
  }

  @Watch('entityLedgerSimple')
  ledgerUpdated() {
    this.isLoading = false
  }

  public formatDate(timestamp: number): string {
    const epoch = timeToMilliseconds(timestamp)
    return this.$store.state.formatDate(epoch, true, 1)
  }
}
