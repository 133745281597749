
import { titleCase } from '@/utils/general'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { BlockCondensed, formatTron, Transaction, TransactionInfo } from '@/types/tron'

@Component({
  computed: mapState(['transaction', 'block', 'receipts'])
})
export default class TransactionTron extends Vue {
  public transaction?: Transaction
  public block?: BlockCondensed
  public receipts?: TransactionInfo[]

  private network: string = ''
  private txnId: string = ''
  public networkDisplayName: string = ''
  private triedToFetchMetadata: boolean = false

  public formatTron = formatTron

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.txnId = this.$route.params.txnId
    this.networkDisplayName = titleCase(this.network)
    this.getBlock()
    this.getReceipts()
  }

  @Watch('transaction')
  private getBlock() {
    if (this.transaction != null) {
      this.$store.dispatch('getBlock', { network: this.network, id: this.transaction.blockHeight })
    }
  }

  private getReceipts() {
    this.$store.dispatch('getReceipts', { network: this.network, id: this.txnId })
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }
}
